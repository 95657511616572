import CommonDownloadButtonModel from './CommonDownloadButtonModel';
import config from '../../config';

class NrcTaxEstimatesCsvDownloadButtonModel extends CommonDownloadButtonModel {
  constructor(customerOrderInstance) {
    super(customerOrderInstance);

    this._ignoreOnExport.push('masterOrder');

    this.label = 'msg_download_nrc_tax_summary';
  }

  get visible() {
    return Boolean(
      this.masterOrder.nrcTaxEstimates &&
        !this.masterOrder.nrcTaxEstimates.failed &&
        !this.masterOrder.nrcTaxEstimates.noApplicableProducts &&
        this.masterOrder.nrcTaxEstimates.total !== null
    );
  }

  get downloadUrl() {
    return [config.api.url, '/', this.masterOrder.id, '/tax-estimates-report'].join('');
  }
}

export default NrcTaxEstimatesCsvDownloadButtonModel;
