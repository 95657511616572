import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import './css/sidebar.css';
import AppRouter from '../../AppRouter/AppRouter';

const drawerMenuItems = [
  {
    key: 'home',
    id: 'drawer-menu-home-button',
    primaryText: 'msg_home',
  },
  {
    key: 'startNew',
    id: 'drawer-menu-start-new-quote',
    primaryText: 'msg_create_quote',
  },
  {
    key: 'retrieveQuote',
    id: 'drawer-menu-retrieve-quote-button',
    primaryText: 'msg_retrieve_quotes',
  },
  {
    key: 'promotions',
    id: 'drawer-menu-promotions',
    primaryText: 'msg_promotions',
  },
  {
    key: 'help',
    id: 'drawer-menu-help',
    primaryText: 'msg_help',
  },
  {
    key: 'about',
    id: 'drawer-menu-about-quote',
    primaryText: 'msg_about_rocket_quote',
  },
];

class Sidebar extends PureComponent {
  static propTypes = {
    fieldsErrors: PropTypes.objectOf(PropTypes.string),
    itemResult: PropTypes.object,
    isSaved: PropTypes.bool,
    drawerOpened: PropTypes.bool,
    quoteStatus: PropTypes.string,
    handleSidebarEscPress: PropTypes.func,
  };

  static defaultProps = {
    fieldsErrors: {},
    itemResult: {},
    isSaved: false,
    drawerOpened: false,
    quoteStatus: '',
  };

  constructor(props) {
    super(props);

    this.closeDrawer = this.closeDrawer.bind(this);
  }

  /**
   * Important thing: onTouchTap event handlers for those menu items are
   * functions declared within this context and has names like
   * props.key + 'TouchTap', so creating props for new menu items don't forget to
   * create props.key + 'TouchTap' event handler as well
   */
  getMenuItem(props) {
    const itemProps = {
      className: 'drawer-menu-item',
      primaryText: props.primaryText,
      onClick: !!this[props.key + 'TouchTap'] ? () => this[props.key + 'TouchTap']() : () => null,
      title: props.primaryText,
      id: props.id,
    };

    if (props.key === 'save') {
      const stateCalculated = !!Object.keys(this.props.itemResult).length;
      const invalidFields = Object.keys(this.props.fieldsErrors).some(el => !!this.props.fieldsErrors[el]);
      const spiffWarning = this.props.itemResult.spiffWarningPooled === 'true';

      if (
        this.props.isSaved ||
        this.hasErrors(this.props.fieldsErrors) ||
        !stateCalculated ||
        invalidFields ||
        spiffWarning ||
        ['TRANSFERRING', 'CONVERTED'].includes(this.props.quoteStatus)
      ) {
        itemProps.disabled = true;
        itemProps.className += ' navbar-item-disabled';
      }
    }

    if (props.key === 'language') {
      itemProps.dropDownItems = props.dropDownItems;
      itemProps.switchLanguage = this.switchLanguage;
    }

    return <MenuItem key={props.key} {...itemProps} />;
  }

  hasErrors(fieldsErrors) {
    return Object.keys(fieldsErrors).filter(key => fieldsErrors[key]).length;
  }

  startNewTouchTap() {
    this.closeDrawer();
    AppRouter.push('/create');
  }

  aboutTouchTap() {
    this.props.toggleAbout(true);
    this.closeDrawer();
  }

  helpTouchTap() {
    this.closeDrawer();
    window.open('https://s2s-support-files.s3.amazonaws.com/kb/products/documents/rocket-user-guide.pdf', 'blank');
  }

  promotionsTouchTap() {
    this.closeDrawer();
    window.open('https://partners.sangoma.com/playbooks/current-quarter-promotions/', 'blank');
  }

  retrieveQuoteTouchTap() {
    AppRouter.push('/quotes');
    this.closeDrawer();
  }

  homeTouchTap() {
    AppRouter.push(`/landing`);
    this.closeDrawer();
  }

  closeDrawer() {
    if (typeof this.props.toggleDrawer === 'function') {
      this.props.toggleDrawer(false);
    }
  }

  render() {
    document.body.classList.toggle('body-overflow-hidden', this.props.drawerOpened);

    return this.props.drawerOpened ? (
      <div>
        <div className="drawer-overlay" onClick={this.closeDrawer} />
        <div className="drawer" tabIndex={0} onKeyDown={this.props.handleSidebarEscPress}>
          {drawerMenuItems.map(props => this.getMenuItem(props))}
        </div>
      </div>
    ) : null;
  }
}

export default Sidebar;
