import React from 'react';
import ControllerAbstract from './ControllerAbstract';
import apiData from '../Storage/apiData';
import $http from '../common/$http';
import { AppStateContext, AuthContext, ErrorContext, IntlContext } from '../Context';
import config from '../config';
import translator from '../common/translate';
import { LOCALES } from '../common/enums';

class AppController extends ControllerAbstract {
  constructor() {
    super();

    // Listen for app state changes
    this.signOutApp = this.signOutApp.bind(this);
    this.exitApp = this.exitApp.bind(this);
    this.switchLanguage = this.switchLanguage.bind(this);

    this.boot();
  }

  listenAppStateContext() {
    if (AppStateContext.model.appInitiated) {
      this.renderView();

      return true;
    }
  }

  boot() {
    if (AuthContext.model.token) {
      $http.instance.initInterceptors();
    } else {
      // Clear user specific data if there is no token
      this.doLogout();
    }

    // This part will be partially replaced by API cache
    this.requestTokenValidation()
      .then(r => {
        const auth = {
          ...r.data.content,
          token: AuthContext.model.token,
        };

        AuthContext.model.setProps(auth);

        AppStateContext.model.tokenValidated = true;
        AppStateContext.model.broadcast();
      })
      .catch(error => {
        this.doLogout();

        throw error;
      });

    this.requestApiData();
  }

  requestTokenValidation() {
    const url = $http.instance.apiDomainURI + '/integration/sso/validate/rq';
    return $http.instance.api.get(url);
  }

  requestVersion() {
    const url = $http.instance.apiDomainURI + '/version/all';
    return $http.instance.api.get(url);
  }

  requestCategories() {
    const url = config.api.url + '/catalog/categories';
    return $http.instance.api.get(url);
  }

  requestDealerships() {
    const url = config.api.url + '/integration/dealerships';
    return $http.instance.api.get(url);
  }

  requestCountries() {
    const url = config.api.url + '/location/countries';
    return $http.instance.api.get(url);
  }

  requestStates(countryId = config.COUNTRY_CODE_USA) {
    const url = config.api.url + '/location/states/' + encodeURIComponent(countryId.toString());
    return $http.instance.api.get(url);
  }

  requestIndustryCodes() {
    const url = config.api.url + '/location/industryCodes';
    return $http.instance.api.get(url);
  }

  requestApiMeta() {
    const url = config.api.url + '/api/meta/model';
    return $http.instance.api.get(url);
  }

  requestProperties() {
    const url = config.api.url + '/properties';

    return $http.instance.api.get(url);
  }

  requestBrandNames() {
    const url = config.api.url + '/catalog/brand-names';

    return $http.instance.api.get(url);
  }

  getAltDescription() {
    let url = config.api.url + '/catalog/customerAppliedDiscountNames';

    return $http.instance.api.get(url);
  }

  requestAnnouncement() {
    const url = config.api.url + '/announcement/get/1';

    return $http.instance.api.get(url);
  }

  requestApiData() {
    if (!AppStateContext.model.versionLoaded) {
      this.requestVersion().then(r => {
        apiData.version = r.data.content[0];

        AppStateContext.model.versionLoaded = true;
        AppStateContext.model.broadcast();
      });
    }

    if (!AppStateContext.model.dealersLoaded) {
      this.requestDealerships().then(r => {
        apiData.dealerships = r.data.content;

        if (!r.data.content.length) {
          ErrorContext.model.setProps({
            isShown: true,
            message: (
              <span>
                {translator.getMessage('msg_you_are_not_associated_with_a_dealer_in_star2star_system_please_email')}
                <br />
                <a href="mailto:AskRQ@star2star.com">AskRQ@star2star.com</a>
              </span>
            ),
            autoHideDelay: null,
            showCloseButton: false,
          });
        }

        AppStateContext.model.dealersLoaded = true;
        AppStateContext.model.broadcast();
      });
    }

    if (!AppStateContext.model.categoriesLoaded) {
      this.requestCategories().then(r => {
        // Sort by DB sequence field
        r.data.content.sort((a, b) => a.sequence - b.sequence);

        apiData.categories = r.data.content;

        AppStateContext.model.categoriesLoaded = true;
        AppStateContext.model.broadcast();
      });
    }

    if (!AppStateContext.model.countriesLoaded) {
      this.requestCountries().then(r => {
        apiData.countries = r.data.content;

        AppStateContext.model.countriesLoaded = true;
        AppStateContext.model.broadcast();
      });
    }

    if (!AppStateContext.model.statesLoaded) {
      this.requestStates().then(r => {
        apiData.states = r.data.content;

        AppStateContext.model.statesLoaded = true;
        AppStateContext.model.broadcast();
      });
    }

    if (!AppStateContext.model.apiSchemasLoaded) {
      this.requestApiMeta().then(r => {
        apiData.apiSchemas = r.data;

        AppStateContext.model.apiSchemasLoaded = true;
        AppStateContext.model.broadcast();
      });
    }

    if (!AppStateContext.model.propertiesLoaded) {
      this.requestProperties().then(r => {
        if (r.status === 200) {
          apiData.properties = r.data.content;

          AppStateContext.model.propertiesLoaded = true;
          AppStateContext.model.broadcast();
        }
      });
    }

    if (!AppStateContext.model.altDecriptionSettingsLoaded) {
      this.getAltDescription().then(r => {
        if (r.status === 200) {
          const data = r.data.content;
          const result = { recurring: [], nonRecurring: [] };

          for (let i = 0; i < data.length; i++) {
            if (data[i].active) {
              const isRecurring = data[i].recurring ? 'recurring' : 'nonRecurring';
              result[isRecurring].push({
                value: data[i].name,
                text: data[i].name,
                default: data[i].default,
              });
            }
          }

          apiData.altDescriptionOptions = result;
          AppStateContext.model.altDecriptionSettingsLoaded = true;
          AppStateContext.model.broadcast();
        }
      });
    }

    if (!AppStateContext.model.announcementLoaded) {
      AppStateContext.model.announcementLoaded = true;
      // KM-13535: Uncomment to enable announcements
      // this.getAnnouncement();
    }

    if (!AppStateContext.model.brandNamesLoaded) {
      // TODO: Refactor all requestXXX methods and usage to be async / await instead of Promise
      this.requestBrandNames().then(r => {
        if (r.status === 200) {
          apiData.brandNames = r.data.content;

          AppStateContext.model.brandNamesLoaded = true;
          AppStateContext.model.broadcast();
        }
      });
    }
  }

  async doLogin(password, userEmail) {
    const requestBody = { password, userEmail };
    const url = $http.instance.apiDomainURI + '/integration/sso/login';

    return $http.instance.apiWithoutGlobalHandler.post(url, requestBody).then(r => {
      const data = r.data;

      AuthContext.model.token = data.content.token;
      $http.instance.initInterceptors();

      AuthContext.model.setProps({
        showLoginModal: false,
      });

      // TODO: Remove this code after implementation of proper API calls re-try on token expiration
      // Reload the page to ensure all required API data received by UI
      window.location.reload();

      return r;
    });
  }

  doLogout() {
    // Wipe localStorage on user log out
    localStorage.clear();

    AuthContext.model.setProps({
      isAgent: false,
      email: '',
      firstName: '',
      internalUser: false,
      lastName: '',
      permissions: [],
      userId: 0,
      token: '',
    });

    AppStateContext.model.tokenValidated = false;
    AppStateContext.model.broadcast(true);
  }

  signOutApp() {
    const url = `${$http.instance.apiDomainURI}/integration/sso/logout`;
    $http.instance.api.get(url);
    this.doLogout();

    AuthContext.model.setProps({
      signOutInProgress: true,
    });

    window.location = config.loginPage + '/logout.php';
  }

  exitApp() {
    window.location = config.loginPage;
  }

  switchLanguage(locale) {
    if (!Object.values(LOCALES).includes(locale)) {
      return false;
    }

    IntlContext.model.setProps({ locale });
  }

  getAnnouncement() {
    AppStateContext.model.announcementLoaded = false;

    this.requestAnnouncement().then(r => {
      if (r.status === 200) {
        apiData.announcement = r.data.content;

        AppStateContext.model.announcementLoaded = true;
        AppStateContext.model.broadcast();
      }
    });
  }

  getIndustryTypes() {
    AppStateContext.model.industryTypesLoading = true;

    return this.requestIndustryCodes().then(r => {
      if (r.status === 200) {
        apiData.industryTypes = r.data.content
          .filter(industryType => industryType.active)
          .sort((a, b) => a.sort - b.sort);

        AppStateContext.model.industryTypesLoading = false;
        AppStateContext.model.broadcast();
      }
    });
  }
}

export default AppController;
