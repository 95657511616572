import CommonDownloadButtonModel from './CommonDownloadButtonModel';
import config from '../../config';
import { AuthContext } from '../../Context';
import { ORDER_TYPES } from '../../common/enums';

class LocationBreakoutsCsvDownloadButtonModel extends CommonDownloadButtonModel {
  constructor(customerOrderInstance) {
    super(customerOrderInstance);

    this._ignoreOnExport.push('masterOrder');

    this.label = 'msg_location_breakouts_csv';
  }

  get visible() {
    return (
      AuthContext.model.hasInternalPermissions &&
      this.masterOrder.locations.length > 0 &&
      this.masterOrder.orderType !== ORDER_TYPES.ADD_ON
    );
  }

  get downloadUrl() {
    return [config.api.url, `/${this.masterOrder.id}/locations/line-items?token=`, AuthContext.model.token].join('');
  }
}

export default LocationBreakoutsCsvDownloadButtonModel;
