import ERROR_TEXT_COLLECTION from '../common/ERROR_TEXT_COLLECTION';
import $http from '../common/$http';
import {
  ORDER_TYPES,
  QUOTE_STATUS,
  SOLUTION_TYPES,
  constants,
  CATEGORY_IDS,
  USER_ROLES,
  LEASE_OPTIONS,
  PRICEBOOK_TAGS,
  ADDENDUM_FIELDS,
  EMAIL_REGEX,
  PRODUCT_TAGS,
  QUOTE_OPERATION_MODES,
  MANDATORY_SF_QUOTE_FIELDS_BY_QUOTE_TYPE,
} from '../common/enums';
import config from '../config.js';
import { v4 as UUID } from 'uuid';
import { AuthContext, PriceBookContext } from '../Context';
import { CONTACT_PROPS } from '../components/CustomerContactInfo/ContactSectionProps';
import { RESULT_TYPES } from './CalcResultsModel';

const MIN_LENGTH = 2;
const PHONE_NUMBER_LENGTH = 10;
// TODO add some flag to this line item to identify it
const CUSTOMER_INSTALLATION_PRODUCT_ID = 90003;

const REGEX_PATTERNS = {
  email: EMAIL_REGEX,
  // eslint-disable-next-line
  url: /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/,
  // eslint-disable-next-line
  zip: /^\d{5}(-?\d{4})?$/,
};

class Validate {
  static __lastNameRequestUuid = null;

  static validateCalculationResultBoolean(calcResults, dataPointName, responseErrorCode) {
    let code = '';

    const dataPointValue = calcResults.getValue(dataPointName, RESULT_TYPES.boolean);

    if (dataPointValue) {
      code = responseErrorCode;
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static lineItems(quote) {
    // KM-4191: Do not require device for Add-ons & Reductions
    if ([ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(quote.orderType)) {
      return {
        code: '',
        text: '',
      };
    }

    const userRole = AuthContext.model.role;

    let code = '';
    let totalCount = 0;

    if (quote.locations.length && quote.lineItems.some(d => d.quantityLessThanAllocatedFinalized)) {
      code = 'LINE_ITEM_QUANTITY_LESS_THAN_ALLOCATED_FINALIZED';
    }

    // Filter out permanent line items
    const lineItems = quote.lineItems.filter(data => {
      if (!!data.catalogFlags === false) {
        return false;
      }

      const isFixed = data.catalogFlags.some(d => d.flag.name === 'isFixedOnUi');
      const countsTowardsMinimalNumberOfProducts = data.catalogFlags.some(
        d => d.flag.name === 'countsTowardsMinimalNumberOfProducts'
      );

      return countsTowardsMinimalNumberOfProducts || !isFixed;
    });

    for (let i = 0; i < lineItems.length; i++) {
      totalCount += lineItems[i].quantityWithOverride;
    }

    // NOTE: fixed line items are filtered out

    // TODO: Add new error codes for each case. Discuss codes and error text
    // 1. No line items in the quote
    // * It will triggers only before price book catalog loaded
    if (quote.lineItems.length === 0) {
      code = 'DEVICES_LIST_TOO_SHORT';
    }
    // 2. There are line items in quote but their total quantity is equal zero
    // Note: some line items may have negative qty now. This condition updated to be <= 0
    else if (totalCount <= 0) {
      code = 'DEVICES_LIST_TOO_SHORT';
    }
    // 2. KM-7510: Forbid quote save for NON internal users
    // If there are Partner added items only presents in quote
    else if (
      ![USER_ROLES.SALES_OPERATIONS_USER, USER_ROLES.INTERNAL_USER].includes(userRole) &&
      lineItems.filter(({ quantityWithOverride }) => quantityWithOverride > 0).every(({ partnerAdd }) => partnerAdd)
    ) {
      code = 'DEVICES_LIST_TOO_SHORT';
    }
    // 3.1 Negative quantity validation on Master Order level
    else if (lineItems.some(d => d.quantityWithOverride < 0 || d.quantity < 0)) {
      code = 'LINE_ITEMS_HAS_NEGATIVE_QUANTITY';
    }
    // 3.2 Negative quantity validation on Location level
    else {
      for (let i = 0; i < quote.locations.length; i++) {
        const loc = quote.locations[i];

        if (loc.lineItems.some(d => d.quantityWithOverride < 0 || d.quantity < 0)) {
          code = 'LINE_ITEMS_HAS_NEGATIVE_QUANTITY';
          break;
        }
      }
    }

    if (quote.packagedApplicationsExists && code === '') {
      const locWithPa = quote.locations.find(l => l.allocateAllCustomerLevelPackagedApplications);

      if (locWithPa && locWithPa.status === QUOTE_STATUS.FINALIZED) {
        const { length } = lineItems;

        for (let i = 0; i < length; i++) {
          const l = lineItems[i];
          const flag = l.catalogFlags.filter(d => d.flag.name === 'productTag') || false;

          if (
            flag?.some(({ valueAsString }) => valueAsString === 'packagedApplicationsThatRequireConsistentAllocation') &&
            l.quantityWithOverride !== l.allocated
          ) {
            code = locWithPa.locationName + ' @' + ERROR_TEXT_COLLECTION['LOCATIONS_MUST_BE_UNFINALIZED_OR_ADDON'] + '@';
            break;
          }
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static numLocations(quote) {
    if ([ORDER_TYPES.ADD_ON].includes(quote.orderType)) {
      return {
        code: '',
        text: '',
      };
    }

    const value = quote.numLocations;
    const code = value < 1 ? 'NUM_LOCATIONS_TOO_SMALL' : '';

    return {
      code,
      text: value === null ? '' : ERROR_TEXT_COLLECTION[code],
    };
  }

  static dealerId(quote) {
    const value = quote.billingDealerId;
    const code = value ? '' : 'BILLING_DEALER_SHOULD_BE_SELECTED';

    return {
      code,
      text: value === null ? '' : ERROR_TEXT_COLLECTION[code],
    };
  }

  static dealerContactUserId(quote) {
    const value = quote.dealerContactName;
    let code = '';

    if (value === null || value === '') {
      code = 'SELECT_QUOTE_OWNER_NAME';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static NRC_MRC_LineItems(quote) {
    let code = '';

    let totalQuantity = 0;
    let hasNegativeQuantity = false;
    const lineItems = quote.lineItems.filter(data => {
      if (!!data.catalogFlags === false) {
        return false;
      }

      const isFixed = data.catalogFlags.some(d => d.flag.name === 'isFixedOnUi');
      const countsTowardsMinimalNumberOfProducts = data.catalogFlags.some(
        d => d.flag.name === 'countsTowardsMinimalNumberOfProducts'
      );

      return countsTowardsMinimalNumberOfProducts || !isFixed;
    });

    for (let i = 0; i < lineItems.length; i++) {
      const d = lineItems[i];

      if (!hasNegativeQuantity && (d.quantityWithOverride < 0 || d.quantity < 0)) {
        hasNegativeQuantity = true;
      }

      totalQuantity += d.quantityWithOverride;
    }

    // Negative quantity error message should have higher priority
    if (hasNegativeQuantity) {
      code = 'LINE_ITEMS_HAS_NEGATIVE_QUANTITY';
    } else if (totalQuantity <= 0) {
      code = 'DEVICES_LIST_TOO_SHORT';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static customerNameLength(quote) {
    const value = quote.customerName;
    let code = '';

    if (value === null) {
      return {
        code: 'SELECT_CUSTOMER_NAME',
        text: '',
      };
    } else if (value === '' || value.length < MIN_LENGTH) {
      code = 'CUSTOMER_NAME_TOO_SHORT';
    }

    return {
      code: code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static isDbaUnique(quote) {
    const customerName = quote.customerName;
    const DBA = quote.dba;
    const useDba = quote.useDba;
    let code = '';

    if (!DBA && useDba) {
      code = 'DBA_TOO_SHORT';
    } else if (DBA === customerName) {
      code = 'DBA_MUST_BE_NOT_EQUAL_CUSTOMER_NAME';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static async requestCustomerNameValidation(quote, validateDba = false) {
    // Skip customer name validation for new/new Sales Force initiated quote if customerId set already
    if (quote.salesforceOpportunityId && quote.orderType === ORDER_TYPES.NEW_CUSTOMER && quote.customerId) {
      return {
        code: '',
        text: '',
      };
    }

    const quoteId = Number(quote.id);
    const dealerId = Number(quote.dealerId);
    const masterOrderId = Number(quote.masterOrderId);
    const requestUuid = UUID();
    this.__lastNameRequestUuid = requestUuid;
    let nameForValidate = quote.customerName;

    if (validateDba && quote.useDba && quote.orderType === ORDER_TYPES.NEW_CUSTOMER) {
      nameForValidate = quote.dba;
    }

    let params = {
      dealerContactUserId: quote.dealerContactUserId,
      customerName: nameForValidate,
      dealerId: dealerId,
    };

    if (quoteId) {
      params.quoteId = quoteId;
    }

    if (masterOrderId) {
      params.masterOrderId = masterOrderId;
    }

    const search = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key].toString().toLowerCase()))
      .join('&');

    const url = config.api.url + '/integration/exists/customer-name?' + search;

    return $http.instance.api.get(url).then(({ data }) => {
      if (this.__lastNameRequestUuid !== requestUuid) {
        return false;
      }

      const exists = data.content;
      const code = exists ? 'CUSTOMER_NAME_NEW_ONLY' : '';

      return {
        code,
        text: ERROR_TEXT_COLLECTION[code],
      };
    });
  }

  static title(quote) {
    const value = quote.quoteTitle;
    const code = value === null || value.length < MIN_LENGTH ? 'QUOTE_TITLE_TOO_SHORT' : '';

    return {
      code,
      text: value === null ? '' : ERROR_TEXT_COLLECTION[code],
    };
  }

  static spiffAmount(spiffWarningPooled) {
    if (typeof spiffWarningPooled === 'string') {
      spiffWarningPooled = spiffWarningPooled.toLowerCase();
    }

    const code = spiffWarningPooled === 'true' ? 'SPIFF_WARNING' : '';

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static spiffDiscountAvailableRC(spiffDiscount) {
    let code = 'SPIFF_DISCOUNT_EXCEED_RC';
    if (spiffDiscount < 0) {
      return {
        code,
        text: ERROR_TEXT_COLLECTION[code],
      };
    }

    return {
      code: '',
      text: '',
    };
  }

  static spiffDiscountAvailableNRC(spiffDiscount) {
    let code = 'SPIFF_DISCOUNT_EXCEED_NRC';
    if (spiffDiscount < 0) {
      return {
        code,
        text: ERROR_TEXT_COLLECTION[code],
      };
    }

    return {
      code: '',
      text: '',
    };
  }

  static customerContact(value, propName) {
    if (value === null || value === undefined || value === '') {
      return {
        code: '',
        text: '',
      };
    }

    let text = '';

    if ('contactEmail' === propName) {
      if (!value.match(REGEX_PATTERNS.email)) {
        text = 'msg_enter_correct_email';
      }
    } else if (['contactOfficePhone', 'contactMobilePhone', 'btn'].includes(propName)) {
      if (value.length !== PHONE_NUMBER_LENGTH) {
        text = 'msg_phone_number_should_be_10_digits';
      }
    } else if ('zip' === propName) {
      if (!REGEX_PATTERNS.zip.test(value)) {
        text = 'msg_zip_code_should_be_5_or_9_digits';
      }
    }

    return {
      code: text.length ? 'CUSTOMER_CONTACT_INFORMATION_ERROR' : '',
      text,
    };
  }

  static inactiveLineItems(lineItems) {
    const inactiveLineItem = lineItems.find(item => !item.active);

    let code = '';

    if (inactiveLineItem) {
      code = 'INACTIVE_LINE_ITEMS';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static isAllLocationsUnique(locationsNames, quote) {
    let code = this.isLocationUniqueUI(locationsNames);

    if (code === '') {
      for (let i = 0; i < quote.locations.length; i++) {
        if (!quote.locations[i].nameIsUniqueOnBe) {
          code = '@' + ERROR_TEXT_COLLECTION['LOCATION_NAME_NOT_UNIQUE'] + '@ - ' + quote.locations[i].locationName;
          break;
        }
      }
    }

    return code;
  }

  static isLocationUniqueUI(locationsNames) {
    return new Set(...[locationsNames]).size !== locationsNames.length ? 'LOCATION_NAME_NOT_UNIQUE' : '';
  }

  static async isLocationUniqueBE(quoteId, locationName) {
    let params = {
      locationName: locationName,
      quoteId: quoteId,
    };

    const search = Object.keys(params)
      .map(key => key + '=' + encodeURIComponent(params[key].toString().toLowerCase()))
      .join('&');

    const url = config.api.url + '/integration/exists/location-name?' + search;

    return $http.instance.api.get(url).then(({ data }) => {
      const exists = data.content;

      let code = '';

      if (exists !== -1) {
        code = '@' + ERROR_TEXT_COLLECTION['LOCATION_NAME_NOT_UNIQUE'] + '@ - ' + locationName;
      }

      return {
        code,
        text: '',
      };
    });
  }

  static blankIctpPartner(quote) {
    let code = '';

    for (let i = 0; i < quote.ictpProviders.providers.length; i++) {
      const selectedProvider = quote.ictpProviders.providers[i].selectedOption;

      if (selectedProvider.isPartner && (selectedProvider.price === null || selectedProvider.price === '')) {
        code = 'ICTPPS_BLANK_PARTNER_PRICE';
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static ictpConfirmationRequired(quote) {
    if (PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.BUSINESS_CONTINUITY)) {
      return {
        code: '',
        text: '',
      };
    }

    let code = '';
    let someSelectedPartnersAreSetToZero = false;

    for (let i = 0; i < quote.ictpProviders.providers.length; i++) {
      const selectedProvider = quote.ictpProviders.providers[i].selectedOption;

      if (selectedProvider.isPartner) {
        if (selectedProvider.dealerNet === 0) {
          someSelectedPartnersAreSetToZero = true;
          break;
        }
      }
    }

    if (someSelectedPartnersAreSetToZero && !quote.ictpProviders.isConfirmed) {
      code = 'ICTPPS_CONFIRMATION_REQUIRED';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static addOnFinalize(locationId) {
    const url = `${config.api.url}/quotes/addon/validate/${locationId}`;

    return $http.instance.api.get(url).then(({ data }) => {
      return data.content;
    });
  }

  static madPoolOverAllocated(madPoolOverAllocated) {
    let code = '';

    if (madPoolOverAllocated) {
      code = 'MAD_POOL_OVER_ALLOCATED';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static spiffOverAllocated(spiffOverAllocated) {
    let code = '';

    if (spiffOverAllocated) {
      code = 'SPIFF_OVER_ALLOCATED';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static madUpfrontOverAllocated(madUpfrontOverAllocated) {
    let code = '';

    if (madUpfrontOverAllocated) {
      code = 'MAD_UP_FRONT_OVER_ALLOCATED';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static madRecurOverAllocated(madRecurOverAllocated) {
    let code = '';

    if (madRecurOverAllocated) {
      code = 'MAD_RECUR_OVER_ALLOCATED';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static checkSalesExceptionsRules(calcResult = {}) {
    const calcResultsKeys = Object.keys(calcResult);
    const violatedRules = [];

    for (let i = 0; i < calcResultsKeys.length; i++) {
      const key = calcResultsKeys[i];

      if (!key.match(/^seRule\d+$/)) {
        continue;
      }

      const value = String(calcResult[key]).toLowerCase();

      if (value === 'true') {
        violatedRules.push('@msg_' + key + '@');
      }
    }

    return violatedRules;
  }

  static saveButtonSeApproval(quote) {
    let code = '';

    if (quote.quoteStatus === QUOTE_STATUS.SE_PENDING_APPROVAL) {
      code = 'SAVE_BUTTON_SE_APPROVAL';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static countMixedWarning(calcResult, locationQuotes) {
    let code = '';

    locationQuotes.forEach(el => {
      if (el.unlimitedCallRoutingCountMixedWarning === 'true') {
        code = 'COUNT_MIXED_WARNING';
      }
    });

    if (calcResult.unlimitedCallRoutingCountMixedWarning === 'true') {
      code = 'COUNT_MIXED_WARNING';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static limitWarning(calcResult, locationQuotes) {
    let code = '';

    locationQuotes.forEach(el => {
      if (el.calcResults.results.unlimitedCallRoutingLimitWarning === 'true') {
        code = 'LIMIT_WARNING';
      }
    });

    if (calcResult.unlimitedCallRoutingLimitWarning === 'true') {
      code = 'LIMIT_WARNING';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static allocationsForFinalizedMo(quoteStatus, isQuoteOverAllocated) {
    let code = '';

    if (quoteStatus === QUOTE_STATUS.FINALIZED && isQuoteOverAllocated) {
      code = 'ALLOCATIONS_FOR_FINALIZED_MO';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static numStarBoxes(calcResultMO, locationQuotes) {
    let code = '';

    if (calcResultMO.starBoxPerLocationError === 'true') {
      code = 'STARBOX_IS_REQUIRED';
    }

    locationQuotes.forEach(location => {
      if (location.calcResults.results.starBoxPerLocationError === 'true') {
        code = 'STARCLOUD_PLUS_REQUIRES_STARBOX';
      }
    });

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static customerInstallStarPlus(quote) {
    let code = '';

    let install = quote.ictpProviders.providers.find(p => p.subCategoryId === constants.ICTP_INSTALLATION_ID);

    if (
      quote.locations.some(l => l.solutionTypeId === SOLUTION_TYPES.BUSINESS_VOICE_PLUS) &&
      install &&
      install.selectedOption.productId === CUSTOMER_INSTALLATION_PRODUCT_ID
    ) {
      code = 'CUSTOMER_INSTALL_STAR_PLUS';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static solutionType(masterOrder) {
    let code = '';
    const locationQuotes = masterOrder.locations;

    for (let i = 0; i < locationQuotes.length; i++) {
      const loc = locationQuotes[i];
      const solutionTypeId = loc.solutionTypeId;

      for (let j = 0; j < loc.lineItems.length; j++) {
        const li = loc.lineItems[j];

        if (li.quantityWithOverride && !li.applicableSolutionTypes.includes(solutionTypeId)) {
          code = 'SOLUTION_TYPE_MISMATCH';
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static solutionTypeAddons(masterOrder) {
    let code = '';

    const solutionTypeId = masterOrder.solutionTypeId;

    for (let i = 0; i < masterOrder.lineItems.length; i++) {
      const li = masterOrder.lineItems[i];
      const isIctpItem = li.catalogFlags.some(d => d.flag.name === 'ictppsItem');

      if (!isIctpItem && li.quantityWithOverride && !li.applicableSolutionTypes.includes(solutionTypeId)) {
        code = 'SOLUTION_TYPE_MISMATCH_ADDONS';
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static location4gRequiresSdwan(locations) {
    let code = '';

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].calcResults.results.sdWanPrerequisite4gWarning === 'true') {
        code = 'LOCATION_4G_REQUIRES_SDWAN';
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static starCloudWithCustomerInstallation(quote) {
    const customerInstallItem = quote.lineItems.find(d => d.active && d.productId === CUSTOMER_INSTALLATION_PRODUCT_ID);
    let code = '';

    // TODO: Review KM-6523 requirements and bound to specific solution type if needed
    if (
      quote.orderType === ORDER_TYPES.ADD_ON &&
      customerInstallItem &&
      customerInstallItem.quantityWithOverride > 0 &&
      quote.solutionTypeId === SOLUTION_TYPES.BUSINESS_VOICE_PLUS
    ) {
      code = 'STAR_CLOUD_WITH_CUSTOMER_INSTALLATION';
    }

    // TODO: Review KM-6545 requirements and bound to specific solution type
    //       instead of excluding SWITCHVOX_SIP_STATION type
    if (
      quote.orderType !== ORDER_TYPES.ADD_ON &&
      customerInstallItem &&
      customerInstallItem.quantityWithOverride > 0 &&
      quote.numStarCloudLocations !== quote.numLocations &&
      ![SOLUTION_TYPES.SWITCHVOX_SIP_STATION, SOLUTION_TYPES.STANDALONE_NON_UCAAS].includes(quote.solutionTypeId)
    ) {
      code = 'STAR_CLOUD_WITH_CUSTOMER_INSTALLATION';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static _singleLineItemPerSubCategorySet(subCategoryIds, lineItems) {
    let valid = true;
    let numberOfDifferentItemsInAllSubCategories = 0;

    for (let i = 0; i < subCategoryIds.length; i++) {
      const subCategoryId = subCategoryIds[i];

      for (let j = 0; j < lineItems.length; j++) {
        const d = lineItems[j];

        if (d.lineItemSubCategoryId !== subCategoryId) {
          continue;
        }

        // Verify against Master Order quantity or allocated value
        if (d.quantityWithOverride > 0 || d.allocated > 0) {
          numberOfDifferentItemsInAllSubCategories++;
        }

        if (numberOfDifferentItemsInAllSubCategories > 1) {
          valid = false;
          break;
        }
      }

      if (!valid) {
        break;
      }
    }

    return valid;
  }

  static legacyLineItemsAddons(quote) {
    const subCategoryIds = [
      CATEGORY_IDS.ucaasAndTelecom.subCategories.telecomLines,
      CATEGORY_IDS.legacyLineProducts.subCategories.legacyLineProducts,
    ];
    const code = !this._singleLineItemPerSubCategorySet(subCategoryIds, quote.lineItems)
      ? 'LEGACY_LINE_ITEMS_LIMITS'
      : '';

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static legacyLineItemsLocations(quote) {
    const subCategoryIds = [
      CATEGORY_IDS.ucaasAndTelecom.subCategories.telecomLines,
      CATEGORY_IDS.legacyLineProducts.subCategories.legacyLineProducts,
    ];

    let code = '';

    for (let i = 0; i < quote.locations.length; i++) {
      const locationQuote = quote.locations[i];

      if (!this._singleLineItemPerSubCategorySet(subCategoryIds, locationQuote.lineItems)) {
        code = 'LEGACY_LINE_ITEMS_LIMITS';
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static onePerGroup(quote) {
    const lineItemsByGroup = {};
    const messagesByGroup = [];

    for (let i = 0; i < quote.lineItems.length; i++) {
      const li = quote.lineItems[i];

      for (let j = 0; j < li.catalogFlags.length; j++) {
        const flagData = li.catalogFlags[j];
        const flagName = flagData.flag.name;

        if (flagName !== 'onePerGroup') {
          continue;
        }

        let groupName = flagData.valueAsString;

        if (typeof groupName !== 'string' || !groupName.length) {
          if (config.showConsoleLog) {
            console.error('Validate.onePerGroup: valueAsString is empty for line item', li);
          }

          // onePerGroup flag found no need to loop through other flags
          break;
        }

        if (!Array.isArray(lineItemsByGroup[groupName])) {
          lineItemsByGroup[groupName] = [];
        }

        lineItemsByGroup[groupName].push(li.lineItemName);

        // onePerGroup flag found no need to loop through other flags
        break;
      }
    }

    for (let groupName in lineItemsByGroup) {
      if (lineItemsByGroup[groupName].length <= 1) {
        continue;
      }

      const message = '@Only one of the following can be selected:@';
      messagesByGroup.push([message, ...lineItemsByGroup[groupName]]);
    }

    return {
      code: messagesByGroup.length ? messagesByGroup : '',
      text: '',
    };
  }

  static negativeAmount({
    calcResult,
    packageIdSelected,
    isFinanced,
    grandTotalNrc,
    grandTotalRc,
    grandTotalIctpps,
    orderType,
    isWhiteLabelWholesale,
  }) {
    let code = '';

    if (isFinanced) {
      const dataPoint = 'negativeProposalUpfront' + packageIdSelected + 'Warning';

      if (calcResult[dataPoint] === 'true') {
        code = 'NEGATIVE_NRC_AMOUNT';
      }
    }

    if (
      code === '' &&
      (grandTotalNrc + grandTotalIctpps < 0 ||
        ((orderType !== ORDER_TYPES.ADD_ON || isWhiteLabelWholesale) && grandTotalRc < 0))
    ) {
      code = 'QUOTE_TOTALS_CANNOT_BE_NEGATIVE';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static starFaxDigitalMixedAtLocation(calcResultMO, locationQuotes) {
    let code = '';

    if (calcResultMO.starFaxDigitalMixedAtLocationWarning === 'true') {
      code = 'STAR_FAX_DIGITAL_MIXED_WARNING';
    }

    if (code === '') {
      for (let i = 0; i < locationQuotes.length; i++) {
        if (locationQuotes[i].calcResults.results.starFaxDigitalMixedAtLocationWarning === 'true') {
          code = 'STAR_FAX_DIGITAL_MIXED_WARNING';
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static email(value) {
    let text = '';

    if (value?.length && !value.match(REGEX_PATTERNS.email)) {
      text = 'msg_enter_correct_email';
    }

    return text;
  }

  static signatureSubmitBtn(availableDocuments, eSignature, OPTIONAL_FIELDS) {
    const envelopes = availableDocuments.map(d => eSignature[d.type]);
    const fields = [];
    const signerEmails = [];
    const ccEmails = [];

    envelopes.forEach(envelope =>
      Object.keys(envelope).forEach(name => {
        const value = envelope[name];

        if (name === 'signerEmail') {
          signerEmails.push(value);
        }

        if (['ccEmail1', 'ccEmail2'].includes(name)) {
          ccEmails.push(value);
        }

        if (name === 'ccEmails') {
          value.forEach(ccEmail => ccEmails.push(ccEmail));
        }

        fields.push({ name, value });
      })
    );

    // KM-12302: If the Signer Email(s) matches any of the CC Emails (CC Quote Owner included if checked)
    //           prevent signature form to be submitted
    for (let i = 0; i < signerEmails.length; i++) {
      const signerEmail = signerEmails[i];

      if (!signerEmail) {
        continue;
      }

      for (let j = 0; j < ccEmails.length; j++) {
        const ccEmail = ccEmails[j];

        if (signerEmail === ccEmail) {
          return 'msg_esig_submit_signer_and_cc_email_matches_error';
        }
      }
    }

    // KM-12424: If any CC is duplicated do not allow the submission of the Signature Request
    if (
      ccEmails.some((emailA, indexA, emails) =>
        emails.some(
          (emailB, indexB) =>
            typeof emailA === 'string' &&
            typeof emailB === 'string' &&
            emailA &&
            emailB &&
            indexA !== indexB &&
            emailA.toLowerCase() === emailB.toLowerCase()
        )
      )
    ) {
      return 'msg_esig_submit_cc_emails_are_same_error';
    }

    for (let i = 0; i < fields.length; i++) {
      const { name, value } = fields[i];

      if (['ccEmail1', 'ccEmail2'].includes(name) && value !== '' && !value.match(REGEX_PATTERNS.email)) {
        return 'msg_enter_correct_email';
      }

      if (OPTIONAL_FIELDS.includes(name)) {
        continue;
      }

      if (value.trim().length < MIN_LENGTH) {
        return 'msg_enter_all_required_fields';
      }

      if (name === 'signerEmail' && !value.match(REGEX_PATTERNS.email)) {
        return 'msg_enter_correct_email';
      }
    }

    return '';
  }

  static starPhoneOnlyExtensionExceedUsersWarning(calcResultMO, locationQuotes) {
    let code = '';

    if (calcResultMO.getValue('numSoftPhonesAllowed', 'integer') < 0) {
      code = 'STAR_PHONE_EXTENSION_EXCEED_USERS_WARNING';
    }

    if (code === '') {
      const { length } = locationQuotes;
      for (let i = 0; i < length; i++) {
        if (locationQuotes[i].calcResults.getValue('numSoftPhonesAllowed', 'integer') < 0) {
          code =
            '@' +
            ERROR_TEXT_COLLECTION['STAR_PHONE_EXTENSION_EXCEED_USERS_WARNING'] +
            '@ @msg_at@ ' +
            locationQuotes[i].locationName;
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static pciComplianceWarning(locationQuotes) {
    let code = '';
    let locationNames = [];
    const locationQuotesLength = locationQuotes.length;

    for (let i = 0; i < locationQuotesLength; i++) {
      if (locationQuotes[i].calcResults.getValue('pciComplianceWarning', 'boolean')) {
        locationNames.push(locationQuotes[i].locationName);
      }
    }

    if (locationNames.length) {
      code = '@' + ERROR_TEXT_COLLECTION['PCI_COMPLIANT_LOCATION_WARNING'] + '@ @msg_at@ ' + locationNames.join(', ');
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static bcUserMo(calcResult) {
    let code = '';

    if (calcResult.bizconUserPerLocationError === 'true') {
      code = 'BC_USER_MO';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static bcUserLocation(locationQuotes) {
    let code = '';
    const locationQuotesLength = locationQuotes.length;

    for (let i = 0; i < locationQuotesLength; i++) {
      if (locationQuotes[i].calcResults.results.bizconUserPerLocationError === 'true') {
        code = 'BC_USER_LOCATION';
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static leaseCustomPaymentAmount(quote) {
    let code = '';

    if (
      quote.isLeaseOptionAvailable &&
      quote.leaseOption === LEASE_OPTIONS.THIRD_PARTY_CUSTOM_AMOUNT &&
      quote.leaseCustomPaymentAmount <= 0
    ) {
      code = 'CUSTOM_LEASE_AMOUNT_MUST_BE_POSITIVE';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static customerInfoErrors(quote) {
    const messages = [];

    if (quote.serviceInfo.invalidFields.length) {
      const serviceMessage = '@msg_errors_in_service_info@';
      const fieldLabels = quote.serviceInfo.invalidFields.map(field => CONTACT_PROPS.serviceInfo[field].label);

      messages.push([serviceMessage, ...fieldLabels]);
    }

    if (quote.billingInfo.invalidFields.length) {
      const billingMessage = '@msg_errors_in_billing_info@';
      const fieldLabels = quote.billingInfo.invalidFields.map(field => CONTACT_PROPS.billingInfo[field].label);

      messages.push([billingMessage, ...fieldLabels]);
    }

    if (quote.shippingInfo.invalidFields.length) {
      const shippingMessage = '@msg_errors_in_shipping_info@';
      const fieldLabels = quote.shippingInfo.invalidFields.map(field => CONTACT_PROPS.shippingInfo[field].label);

      messages.push([shippingMessage, ...fieldLabels]);
    }

    return {
      code: messages.length > 0 ? messages : '',
      text: '',
    };
  }

  static async asyncIsDefaultPricebookIdEqualsQuotePricebookId(masterOrder, quoteOperationMode) {
    const { dealerId, customerId, businessContinuity, orderType, pricebookId, star2starLocationId, serviceType } =
      masterOrder;
    const operationMode = QUOTE_OPERATION_MODES[quoteOperationMode.toUpperCase()];

    let url = config.api.url;

    if (dealerId) {
      url += '/partners/' + encodeURIComponent(dealerId);
    }

    if (customerId >= 0) {
      url += '/customers/' + encodeURIComponent(customerId);
    }

    url += '/pricebooks?bc=' + businessContinuity.toString();

    // add order type prop to request
    url += '&orderType=' + orderType;

    // add quote mode prop to request
    url += '&mode=' + operationMode;

    if (serviceType) {
      url += '&serviceType=' + serviceType;
    }

    // add original pricebook id prop if mode is clone or revision
    if ([QUOTE_OPERATION_MODES.CLONE, QUOTE_OPERATION_MODES.REVISION].includes(operationMode)) {
      url += '&originalPricebookId=' + pricebookId;
    }

    if (masterOrder.orderType === ORDER_TYPES.ADD_ON) {
      url += '&s2sLocationId=' + encodeURIComponent(star2starLocationId);
    }

    const response = await $http.instance.api.get(url);

    return response.data.content.pricebookId === masterOrder.pricebookId;
  }

  static async asyncIsActiveQuote(quoteId) {
    let isActiveQuote = false;
    const url = config.api.url + '/quotes/' + quoteId + '/validateActiveQuoteRevision';

    try {
      const response = await $http.instance.api.get(url);
      isActiveQuote = response.data.content.isActiveQuote;
    } catch (error) {
      console.log(error);
      throw error;
    }

    return isActiveQuote;
  }

  static addendumErrors(quote, skipSubscriptionAgreementDate) {
    let code = '';

    for (let i = 0; i < quote.quoteAddendums.length; i++) {
      const addendum = quote.quoteAddendums[i];
      const { fields } = addendum;

      if (!addendum.active || addendum.signedOn) {
        continue;
      }

      for (let fieldType in fields) {
        if (skipSubscriptionAgreementDate && fieldType === ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE) {
          continue;
        }

        if (fields.hasOwnProperty(fieldType) && !!fields[fieldType] === false) {
          code = 'FILL_ALL_FIELDS_IN_ACTIVE_ADDENDUMS';
          break;
        }
      }

      if (code) {
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static allowedToAllocateOnConditionAtLocationFlagsValidation(masterOrder) {
    let code = '';
    const locationQuotes = masterOrder.locations;

    for (let i = 0; i < locationQuotes.length; i++) {
      const loc = locationQuotes[i];

      for (let j = 0; j < loc.lineItems.length; j++) {
        /** @type {LocationLineItemModel} */
        const li = loc.lineItems[j];

        if (li.isAllowedToAllocateOnConditionAtLocationValid === false && li.quantityWithOverride) {
          code = 'ALLOWED_TO_ALLOCATE_ON_CONDITION_AT_LOCATION_ERROR';
          break;
        }
      }

      if (code) {
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static invalidateItemsNotAllowedToAddOnConditionFlagsValidation(masterOrder) {
    let code = '';

    for (let j = 0; j < masterOrder.lineItems.length; j++) {
      /** @type {LineItemModel} */
      const lineItem = masterOrder.lineItems[j];

      if (lineItem.isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable) {
        code = 'INVALIDATE_ITEMS_NOT_ALLOWED_TO_ADD_ON_CONDITION_ERROR';
        break;
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static switchvoxOrSipStationMustBePresent(masterOrder, isSupportsSwitchvoxSIPStation) {
    let code = '';

    if (isSupportsSwitchvoxSIPStation && !masterOrder.switchvoxOnPremEnabled && !masterOrder.sipStationEnabled) {
      code = 'SWITCHVOX_OR_SIPSTATION_MUST_BE_PRESENT';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static contactCenterConcurrencyItemMustBePresent(masterOrder) {
    let code = '';

    if (masterOrder.contactCenterConcurrency) {
      code = 'CONTACT_CENTER_CONCURRENCY_ITEM_MUST_BE_PRESENT';

      for (let i = 0; i < masterOrder.lineItems.length; i++) {
        const lineItem = masterOrder.lineItems[i];

        if (!lineItem.hasProductTag(PRODUCT_TAGS.REQUIRED_FOR_CONCURRENCY)) {
          continue;
        }

        if (lineItem.quantityWithOverride > 0) {
          code = '';
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static smartOfficeGatewayKitValidation(calcResults, locationQuotes) {
    let code = '';

    const smartOfficeGatewayKitPerServerWarningMo = calcResults.getValue(
      'smartOfficeGatewayKitPerServerWarning',
      RESULT_TYPES.boolean
    );

    if (smartOfficeGatewayKitPerServerWarningMo) {
      code = 'SMART_OFFICE_GATEWAY_KIT_VALIDATION';
    } else {
      for (let i = 0; i < locationQuotes.length; i++) {
        if (locationQuotes[i].calcResults.getValue('smartOfficeGatewayKitPerServerWarning', RESULT_TYPES.boolean)) {
          code = 'SMART_OFFICE_GATEWAY_KIT_LOCATION_VALIDATION';
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static smartOfficeAccessDoorLicenseValidation(calcResults, locationQuotes) {
    let code = '';

    const smartOfficeLicencePerLocationWarningMo = calcResults.getValue(
      'smartOfficeLicencePerLocationWarning',
      RESULT_TYPES.boolean
    );

    if (smartOfficeLicencePerLocationWarningMo) {
      code = 'SMART_OFFICE_ACCESS_DOOR_LICENSE';
    } else {
      for (let i = 0; i < locationQuotes.length; i++) {
        if (locationQuotes[i].calcResults.getValue('smartOfficeLicencePerLocationWarning', RESULT_TYPES.boolean)) {
          code = 'SMART_OFFICE_ACCESS_DOOR_LOCATION_LICENSE';
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static smartOfficeLicenceOverallocationWarning(calcResults) {
    let code = '';

    const smartOfficeLicenceOverallocationWarning = calcResults.getValue(
      'smartOfficeLicenceOverallocationWarning',
      RESULT_TYPES.boolean
    );

    if (smartOfficeLicenceOverallocationWarning) {
      code = 'SMART_OFFICE_LICENCE_OVERALLOCATION';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static switchvoxApplianceValidation(calcResults) {
    let code = '';

    const switchvoxAppliancePerLocationWarning = calcResults.getValue(
      'switchvoxAppliancePerLocationWarning',
      RESULT_TYPES.boolean
    );

    if (switchvoxAppliancePerLocationWarning) {
      code = 'SWITCHVOX_APPLIANCE_VALIDATION';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static standaloneCPaaSValidationError(calcResults) {
    let code = '';

    const standaloneCPaaSValidationError = calcResults.getValue('standaloneCPaaSValidationError', RESULT_TYPES.boolean);

    if (standaloneCPaaSValidationError) {
      code = 'STANDALONE_CPAAS_VALIDATION';
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static numSangomaCXUsersExceedsNumUCaaSUsersError(calcResults) {
    return this.validateCalculationResultBoolean(
      calcResults,
      'numSangomaCXUsersExceedsNumUCaaSUsersError',
      'NUM_SANGOMA_CX_USERS_EXCEEDS_NUM_UCAAS_USERS'
    );
  }

  static sangomaCXUsersRequiredError(calcResults) {
    return this.validateCalculationResultBoolean(
      calcResults,
      'sangomaCXUsersRequiredError',
      'SANGOMA_CX_USERS_REQUIRED'
    );
  }

  static sangomaCXConcurrencyItemsValidation(masterOrder) {
    let code = '';

    if (masterOrder.sangomaCXConcurrency) {
      code = 'SANGOMA_CX_CONCURRENCY_ITEMS_VALIDATION';

      for (let i = 0; i < masterOrder.lineItems.length; i++) {
        const lineItem = masterOrder.lineItems[i];

        if (!lineItem.hasProductTag(PRODUCT_TAGS.REQUIRED_FOR_SANGOMA_CX_CONCURRENCY)) {
          continue;
        }

        if (lineItem.quantityWithOverride > 0) {
          code = '';
          break;
        }
      }
    }

    return {
      code,
      text: ERROR_TEXT_COLLECTION[code],
    };
  }

  static validateSfResponse(opportunity) {
    const { quoteOrderType } = opportunity;
    const missingFields = !quoteOrderType
      ? []
      : MANDATORY_SF_QUOTE_FIELDS_BY_QUOTE_TYPE[quoteOrderType].filter(field => !Boolean(opportunity[field]));
    let details = !quoteOrderType ? 'msg_missing_quote_order_type_from_sf' : '';

    if (missingFields.length > 0) {
      details = 'msg_missing_mandatory_sf_fields';
    }

    return {
      details,
      msgTemplateStringVariables: {
        missingFields: missingFields.join(', '),
      },
    };
  }
}

export default Validate;
