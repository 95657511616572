import _ from 'lodash';
import $http from '../common/$http';
import { v4 as UUID } from 'uuid';
import {
  AppStateModel,
  IctpLineItemModel,
  LocationIctpLineItemModel,
  LocationQuoteModel,
  PartnerLineItemModel,
  QuotesCollectionModel,
  ToolbarModel,

  // toolbar button models
  AmendmentModel,
  ApprovalModel,
  CancelAmendmentModel,
  FinalizeModel,
  FinalizedModel,
  PreliminaryButtonModel,
  PrintFinalButtonModel,
  SaveButtonModel,
  SendForSignatureModel,
  SignaturesButtonModel,
  SlexButtonModel,

  // HUD package models
  PackageOneModel,
  PackageTwoModel,
  PackageThreeModel,
  PackageFourModel,
  Validate,
  ServiceInfoModel,
  ShippingInfoModel,
  BillingInfoModel,

  // Section models
  AdminSectionModel,
  AddendumSectionModel,
  SangomaCxSettingsSectionModel,
  DaasSectionModel,
  PartnerInformationSectionModel,
  CustomerInformationSectionModel,
  QuoteInformationSectionModel,
} from '../Models';
import config from '../config';
import {
  constants,
  ORDER_TYPES,
  QUOTE_STATUS,
  ORDER_TYPES_LINKS,
  SOLUTION_TYPES,
  ERROR_CONTEXT_TYPES,
  ESIG_ENVELOPE_TYPES,
  CUSTOMIZE_PDF_OPTION_LIST,
  QUOTE_COPY_ACTION,
  USER_ROLES,
  CONTRACT_TERM_MONTH_OPTIONS,
  FINANCING_OPTIONS,
  ESIG_RENTAL_NOTICE,
  ADDENDUMS,
  ERROR_BANNER_IDS,
  QUOTE_OPERATION_MODES,
  QUOTE_SERVICE_TYPE,
} from '../common/enums';
import apiData from '../Storage/apiData';
import { AppStateContext, AuthContext, ErrorContext, PartnerContext, PriceBookContext } from '../Context';
import RequestBodyNormalize from '../api/RequestBodyNormalize';
import IntegrationApi from '../api/IntegrationApi';
import ConstantsModel from '../Models/ConstantsModel';
import isVariablesEqual from '../common/helpers/isVariablesEqual';
import QuoteStatusPollInterface from './Interfaces/QuoteStatusPollInterface';
import fixFloat from '../common/helpers/fixFloat';
import { getUserUTCTimezone } from '../common/helpers/getUserUTCTimezone';
import ApiErrorHandler from '../api/ApiErrorHandler';
import bindMultiple from '../common/helpers/bindMultiple';
import AppController from './AppController';
import AppRouter from '../AppRouter/AppRouter';
import { ADDENDUMS_ORDER } from '../common/enums/addendums';
import PriceBookVersionApi from '../api/PriceBookVersionApi';
import QuoteMarkerFieldOverridesApi from '../api/QuoteMarkerFieldOverridesApi';
import MarkerModel from '../Models/MarkerModel';
import QuoteTaxEstimatesApi from '../api/QuoteTaxEstimatesApi';
import TrackingApi from '../api/TrackingApi';
import { numLocationsExceededErrorBanner } from './helpers';
import AddLineItemsGlobalDialogModel from '../Models/AddLineItemsGlobalDialogModel';
import dayjs from 'dayjs';
import { QUOTE_OPERATION_STATE_ERROR, QUOTE_OPERATION_STATES } from '../common/enums/quoteOperationState';

// TODO move some const to external file?

// Collect customer contact fields required for submission
const CC_FIELDS_FOR_SUBMISSION = [
  'streetAddress1',
  'city',
  'stateId',
  'countryId',
  'zip',
  'btn',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactEmail',
  'billingContactOfficePhone',
  'businessContactFirstName',
  'businessContactLastName',
  'businessContactEmail',
  'businessContactOfficePhone',
];

// Key is calc property name, value is UI model property name
const DISCOUNT_FIELDS_MAP_EACH_PACKAGE = {
  madRecurAmount: 'madRecurring',
  madMaxUpfront: 'madRemain',
  madPoolUpfront: 'madTotal',
  madUpfrontAmount: 'madNonRecurring',
  madMaxRecur: 'madRecurringMax',
  madTotalFundsStandard: 'madTotalFundsStandard',
  madOverrideResetMetric: 'madOverrideResetMetric',
  spiffOverrideResetMetric: 'spiffOverrideResetMetric',
  additionalRecurringDiscount: 'additionalRecurringDiscount',
  additionalUpfrontDiscount: 'additionalUpfrontDiscount',
  calculatedMadRecurAmount: 'calculatedMadRecurAmount',
  calculatedMadUpfrontAmount: 'calculatedMadUpfrontAmount',
};

const DISCOUNT_FIELDS_MAP = {
  madMaxUpfrontAllowed3: 'madMaxUpfrontAllowed3',
  madMaxUpfrontAllowed4: 'madMaxUpfrontAllowed4',
};

const SAVE_OR_UPDATE_URL_MAPPING = {
  [ORDER_TYPES.NEW_CUSTOMER]: '/quotes/new-customer',
  [ORDER_TYPES.NEW_LOCATIONS]: '/quotes/new-location',
  [ORDER_TYPES.ADD_ON]: '/quotes/add-on',
  [ORDER_TYPES.REWRITE]: '/quotes/rewrite',
  [ORDER_TYPES.REDUCTION]: '/quotes',
};

const CALC_URL_MAPPING = {
  [ORDER_TYPES.NEW_CUSTOMER]: '/quotes/new-customer/calculation',
  [ORDER_TYPES.NEW_LOCATIONS]: '/quotes/new-location/calculation',
  [ORDER_TYPES.ADD_ON]: '/quotes/add-on/calculation',
  [ORDER_TYPES.REWRITE]: '/quotes/rewrite/calculation',
  [ORDER_TYPES.REDUCTION]: '/quotes/calculation',
};

const COPY_CONTACT_MAP = {
  COPY_FROM_MASTER_ORDER_SERVICE: { level: 'masterOrder', model: 'serviceInfo' },
  COPY_FROM_MASTER_ORDER_BILLING: { level: 'masterOrder', model: 'billingInfo' },
  COPY_FROM_MASTER_ORDER_SHIPPING: { level: 'masterOrder', model: 'shippingInfo' },
  COPY_FROM_PARTNER_MASTER_ORDER: { level: 'masterOrder', model: 'partner' },
  COPY_FROM_LOCATION_SERVICE: { level: 'location', model: 'serviceInfo' },
  COPY_FROM_LOCATION_BILLING: { level: 'location', model: 'billingInfo' },
  COPY_FROM_PARTNER_LOCATION: { level: 'location', model: 'partner' },
};

const FIELDS_FOR_CHECK_PARTNER = ['inactive', 'ignoredealer', 'dead'];

class QuoteController extends AppController {
  __redoRollUpCheck = false;
  __quoteRequestCache = {};
  __calcRequestCache = {};
  __madTotalFundsStandardCache = null;
  __madOverrideResetMetricCache = null;
  __spiffOverrideResetMetricCache = null;

  __onInitEvents = [];

  __inited = false;
  __initialValidation = true;

  __orderType = ORDER_TYPES.NEW_CUSTOMER;
  __partnerId = null;
  __customerId = null;
  __locationIds = [];
  __originalQuoteId = null;
  __businessContinuity = false;
  __switchvoxSipStation = false;
  __standaloneServiceNonUCaaS = false;
  __serviceType = '';
  __industryCodeId = null;
  __opportunityId = null;

  _retrigerCalc = false;
  _editedBeforeSaveComplete = false;

  rollUpDisabled = true;

  models = {
    amendmentModel: null,
    approvalModel: null,
    cancelAmendmentModel: null,
    finalizeModel: null,
    finalizedModel: null,
    preliminaryButtonModel: null,
    printFinalButtonModel: null,
    saveButtonModel: null,
    sendForSignatureModel: null,
    signaturesButtonModel: null,
    slexButtonModel: null,
    toolbarModel: null,
    packageTwoModel: null,
    packageOneModel: null,
    packageThreeModel: null,
    packageFourModel: null,
    adminSectionModel: null,
    addendumSectionModel: null,
    sangomaCxSettingsSectionModel: null,
    daasSectionModel: null,
    partnerInformationSectionModel: null,
    customerInformationSectionModel: null,
    quoteInformationSectionModel: null,
  };

  /** @type {QuotesCollectionModel} */
  quotes = null;

  /** @type {AppStateModel} */
  appState = null;

  __props = {
    pricebookIdDialog: {
      isOpen: false,
      onCancel: null,
    },
    copyAction: null,
  };

  _onQuoteChange(isUserInput, forceCalc = false) {
    if (this.appState.isSaveRequested) {
      this._editedBeforeSaveComplete = true;
    }

    // KM-4910: Reset MAD Override if
    // Acknowledgement box is unchecked
    // another Package is selected
    if (
      (this.__quoteRequestCache.confirmedSpiffDiscount === true && this.masterOrder.confirmedSpiffDiscount === false) ||
      this.__quoteRequestCache.packageIdSelected !== this.masterOrder.packageIdSelected
    ) {
      this.disableSpiffDiscountOverride();
    }

    if (
      !AuthContext.model.hasSalesOpsPermissions &&
      this.__quoteRequestCache.contractTermMonths !== this.masterOrder.contractTermMonths
    ) {
      this.masterOrder.overrideFreeMonthPromoRules = false;
    }

    const quotes = this.quotes.toJS();
    const quote = quotes[0];

    const quoteRequestCache = this._quoteRequestCacheCleanUp(
      RequestBodyNormalize(SAVE_OR_UPDATE_URL_MAPPING[quote.orderType], quote)
    );
    const quoteChanged = !isVariablesEqual(this.__quoteRequestCache, quoteRequestCache);

    if (isUserInput && !this.appState.isEdited && quoteChanged) {
      this.appState.isEdited = true;
    }

    if (this.appState.isEdited && this.appState.isCalculated) {
      this.appState.isCalculated = false;
    }

    this.validateQuote(quote);

    // KM-8190: Compare quote data against calculation endpoint schema
    // And reset sales exception approval in case if any data from that scope been changed
    const currentCalcRequestData = this._calcRequestCacheCleanUp(
      RequestBodyNormalize(CALC_URL_MAPPING[quote.orderType], quote)
    );

    this.validateAndResetSeApproval(this.__calcRequestCache, currentCalcRequestData);

    if (
      this.__calcRequestCache.numLocations !== currentCalcRequestData.numLocations &&
      [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS].includes(quote.orderType)
    ) {
      this.asyncValidateNumLocations(currentCalcRequestData.numLocations);
    }

    if (quote.showOvernightGuaranteeWarning) {
      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_overnight_guarantee_reset_warning',
        autoHideDelay: 10000,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.WARNING,
      });
    }

    this.renewSaveSchemaCache(quote);

    // Triggers calculation request
    if (this.appState.isCalcRequested) {
      this._retrigerCalc = true;
    } else if (this.appState.isCalcAllowed && (AppStateContext.model.isAutoCalculateEnabled || forceCalc)) {
      this.requestCalculation(quotes, this._retrigerCalc).then(this.handleCalculation);
    } else {
      this.checkRollUp(quote);
    }

    this.renderView();
  }

  constructor({
    orderType,
    partnerId,
    customerId,
    locationIds = [],
    originalQuoteId,
    businessContinuity = false,
    switchvoxSipStation = false,
    standaloneServiceNonUCaaS = false,
    serviceType,
    industryCodeId,
    opportunityId = null,
  } = {}) {
    super();

    this.quotes = new QuotesCollectionModel(this);
    this.models.addLineItemsGlobalDialog = new AddLineItemsGlobalDialogModel(this);
    this.models.toolbarModel = new ToolbarModel(this);

    // toolbar button models
    this.models.amendmentModel = new AmendmentModel(this);
    this.models.approvalModel = new ApprovalModel(this);
    this.models.cancelAmendmentModel = new CancelAmendmentModel(this);
    this.models.finalizeModel = new FinalizeModel(this);
    this.models.finalizedModel = new FinalizedModel(this);
    this.models.preliminaryButtonModel = new PreliminaryButtonModel(this);
    this.models.printFinalButtonModel = new PrintFinalButtonModel(this);
    this.models.saveButtonModel = new SaveButtonModel(this);
    this.models.sendForSignatureModel = new SendForSignatureModel(this);
    this.models.signaturesButtonModel = new SignaturesButtonModel(this);
    this.models.slexButtonModel = new SlexButtonModel(this);

    // HUD package models
    this.models.packageOneModel = new PackageOneModel(this);
    this.models.packageTwoModel = new PackageTwoModel(this);
    this.models.packageThreeModel = new PackageThreeModel(this);
    this.models.packageFourModel = new PackageFourModel(this);

    // Section models
    this.models.adminSectionModel = new AdminSectionModel(this);
    this.models.addendumSectionModel = new AddendumSectionModel(this);
    this.models.sangomaCxSettingsSectionModel = new SangomaCxSettingsSectionModel(this);
    this.models.daasSectionModel = new DaasSectionModel(this);
    this.models.partnerInformationSectionModel = new PartnerInformationSectionModel(this);
    this.models.customerInformationSectionModel = new CustomerInformationSectionModel(this);
    this.models.quoteInformationSectionModel = new QuoteInformationSectionModel(this);

    this.appState = new AppStateModel(this);
    this.constantsModel = new ConstantsModel(this);
    this.invalidateSalesException = false;

    this.__orderType = orderType;
    this.__partnerId = partnerId;
    this.__customerId = customerId;
    this.__locationIds = locationIds;
    this.__originalQuoteId = originalQuoteId;
    this.__businessContinuity = (orderType === ORDER_TYPES.ADD_ON ? Boolean(businessContinuity) : false) || false;
    this.__switchvoxSipStation = switchvoxSipStation;
    this.__standaloneServiceNonUCaaS = standaloneServiceNonUCaaS;
    this.__serviceType = serviceType;
    this.__industryCodeId = industryCodeId;
    this.__opportunityId = opportunityId;

    apiData.partnerCanSOBO = null;

    this.addOrFireInitEvent(() => {
      if (![ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(this.__orderType)) {
        this.validateQuote();
        this.__initialValidation = false;
      }
    });

    AuthContext.model.customerOrder = this.customerOrder;
    PriceBookContext.model.clearPBContext();
    PartnerContext.model.clearPartnerContext();

    bindMultiple(
      this,
      this.cancelAmendment,
      this.cancelSignature,
      this.changeLocationName,
      this.changeSignatureDocuments,
      this.checkRollUp,
      this.checkMad,
      this.checkSpiff,
      this.closeCopyQuoteModal,
      this.getCopyContactModel,
      this.asyncGetPartnerFlags,
      this.getQuoteHistoryMaster,
      this.getQuoteHistoryQuote,
      this.getSignatureHistory,
      this.handleCalculation,
      this.handleCopyContacts,
      this.handleCopyToAllLocations,
      this.handleLocationsSave,
      this.handleMasterOrderSave,
      this.handlePricebookIdDialogCancel,
      this.handleQuoteClone,
      this.handleQuoteCreateRevision,
      this.isQuoteContainUnderAllocatedItems,
      this.listenAppStateContext,
      this.onChangeDealerDemoKit,
      this.onQuoteFinalize,
      this.onQuoteUnFinalize,
      this.onRetractApproval,
      this.onSubmitForApproval,
      this.refreshSignatureDetails,
      this.reminderSignature,
      this.rePushToTracking,
      this.requestAmend,
      this.requestAvailableDocuments,
      this.requestChangeCounterSignerEmail,
      this.requestCheckpoints,
      this.asyncRequestMasterOrderSave,
      this.requestSalesExceptions,
      this.requestVoidEsr,
      this.rollUpOrder,
      this.sendReminderToSigner,
      this.setBulkMarkup,
      this.submitSignature,
      this.syncToTracking,
      this.toggleAutoCalculation,
      this.asyncValidateNumLocations,
      this.handleLeaseCreditApplicationSubmit,
      this.billingInfoAdjustmentsForWlw,
      this.previewPdfUrlAndValues,
      this.downloadDocumentsSignatureUrlAndValues,
      this.getSignatureDownloadEndpoint,
      this.addMultipleLocations,
      this.asyncRequestMarkers,
      this.asyncFetchTaxEstimates,
      this.asyncGenerateTaxEstimates,
      this.resetTaxEstimates,
      this.asyncOnClickSaveBtn,
      this.selectLocationQuote
    );

    // KM-5300: Force wipe line items data
    // It has to be re-retrieved based by price book ID
    apiData.lineItems = [];

    AppStateContext.model.addBroadcastListener(this.listenAppStateContext);

    this.__props.pricebookIdDialog.onCancel = this.handlePricebookIdDialogCancel;

    this.interfaces.quoteStatusPoll = new QuoteStatusPollInterface(this);
  }

  listenAppStateContext() {
    if (AppStateContext.model.hasDataToOperateQuote) {
      this.asyncInit().then(result => {
        if (!result) {
          return false;
        }

        ErrorContext.model.setProps({
          id: ERROR_BANNER_IDS.QUOTE_ANNOUNCEMENT_BANNER,
          isShown: false, // KM-13535
          message: apiData.announcement.headline,
          details: apiData.announcement.body,
          autoHideDelay: 0,
          showCloseButton: true,
          type: ERROR_CONTEXT_TYPES.INFO,
          priority: 1,
        });
      });

      return true;
    }
  }

  addOrFireInitEvent(event) {
    if (typeof event !== 'function') {
      return false;
    }

    if (this.__inited) {
      event.call(this);
    } else {
      this.__onInitEvents.push(event);
    }

    return true;
  }

  async asyncInit() {
    if (this.__inited) {
      return false;
    }

    this.resetQuoteModel();
    this.masterOrder.businessContinuity = this.__businessContinuity;
    this.masterOrder.switchvoxSipStation = this.__switchvoxSipStation;
    this.masterOrder.standaloneServiceNonUCaaS = this.__standaloneServiceNonUCaaS;
    this.masterOrder.sangomaCXStandalone = this.__serviceType === QUOTE_SERVICE_TYPE.sangomaCXStandalone;

    this.setDefaultQuoteTitle();

    this.appState.quoteLoading = true;
    this.renderView();

    // Sales Force Opportunity quote
    if (this.__opportunityId) {
      try {
        const salesForceOpportunity = await IntegrationApi.getSalesForceOpportunity(this.__opportunityId);
        await this.asyncInitSalesForceQuote(salesForceOpportunity);
      } catch (e) {
        // Break init sequence
        // TODO: Display some understandable error banner
        console.log(e);
        throw e;
      }
    }

    this.masterOrder.orderType = this.__orderType;

    if (this.__partnerId) {
      await this.asyncGetPartnerFlags({ billingDealerId: this.__partnerId });
    }

    if (this.__orderType === ORDER_TYPES.NEW_CUSTOMER) {
      this.masterOrder.serviceInfo.industryCodeId = this.__industryCodeId;
    }

    // TODO: partnerId naming should be refactored
    //       In some places it stands for billingDealerId and in another for dealerId
    // this.__partnerId is a billingDealerId in this case. Received from Create Quote page.
    // Nulled this.__partnerId will only happen in case of quote retrieve.
    // Further quote initialization processed via addOrFireInitEvent added in router.
    if (this.__partnerId && !this.__opportunityId) {
      if (this.__orderType === ORDER_TYPES.NEW_CUSTOMER) {
        this.masterOrder.serviceInfo.industryCodeId = this.__industryCodeId;

        // New/New quote initialization
        await this.asyncInitNewQuote(this.__partnerId, this.__serviceType);
      } else {
        if (!this.__customerId) {
          throw new Error('customerId is required to init quote with order type other than New/New');
        }

        if (this.__originalQuoteId) {
          // Clone/Revision of non New/New quote initialized
          // To do a clone/revision we need to retrieve original quote data first
          // Further quote initialization are done in quote retrieve handler
          await this.asyncRequestQuoteRetrieve(this.__originalQuoteId, null, true);
        } else {
          await this.initOrderTypeQuote(
            this.__orderType,
            this.__partnerId,
            this.__customerId,
            this.__locationIds,
            this.__serviceType
          );
        }
      }
    }

    const { customerId, orderType, numLocations, numberOfLocationsWithTrackingId } = this.masterOrder;

    if ([ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS].includes(orderType)) {
      const numLocationsExceeded = await TrackingApi.numLocationsExceeded(
        customerId,
        numLocations,
        numberOfLocationsWithTrackingId
      );

      if (numLocationsExceeded) {
        numLocationsExceededErrorBanner();
      }
    }

    this.__inited = true;

    // Trigger onInit events
    for (let i = 0; i < this.__onInitEvents.length; i++) {
      const event = this.__onInitEvents[i];

      if (typeof event !== 'function') {
        continue;
      }

      await event.call(this);
    }

    this.appState.quoteLoading = false;
    this.renderView();

    return true;
  }

  async asyncInitSalesForceQuote(opportunity) {
    // Redirect to existing quote if already exists
    // This is a duplicated logic. Also present on Create Quote screen.
    if (opportunity.existingQuoteId) {
      AppRouter.replace('/quotes/' + opportunity.existingQuoteId);

      return false;
    }

    this.appState.quoteLoading = true;
    this.renderView();

    const {
      quoteOrderType,
      dealerId,
      billingDealerId,
      locationId,
      opportunityId,
      dealerName,
      customerAccountId,
      customerId,
      customerName,
      locationName,
      serviceInfo,
      name,
    } = opportunity;

    await this.asyncGetPartnerFlags({ billingDealerId, dealerId });

    const serviceType = this.__serviceType;
    const orderType = quoteOrderType;

    this.masterOrder.setProps({
      salesforceOpportunityId: opportunityId,
      salesforceCustomerAccountId: customerAccountId,
      orderType,
      dealerId,
      billingDealerId,
      dealerName,
      customerId,
      customerName,
    });

    this.__orderType = orderType;
    this.__partnerId = billingDealerId;

    if (customerId) {
      this.__customerId = customerId;
    }

    if (locationId) {
      this.__locationIds = [locationId];
    }

    this.masterOrder.pricebookId = (
      await this.asyncRequestPriceBookId(dealerId, customerId, locationId, serviceType)
    ).content?.pricebookId;

    const setDefaultPackage = orderType !== ORDER_TYPES.ADD_ON;

    await this.asyncLoadPricebookCatalog(
      orderType,
      serviceType,
      this.masterOrder.pricebookId,
      null,
      setDefaultPackage,
      dealerId
    );

    this.appState.isPriceBookRequested = false;

    if (orderType === ORDER_TYPES.NEW_CUSTOMER) {
      await this.asyncSetBillingDealer(billingDealerId);

      if (PartnerContext.model.isWhiteLabelWholesale) {
        this.billingInfoAdjustmentsForWlw();
      }
    } else if ([ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON].includes(quoteOrderType)) {
      const dealerContacts = await IntegrationApi.getDealerContacts({ billingDealerId });

      this.masterOrder.dealerContacts = Array.isArray(dealerContacts) ? dealerContacts : [];
      this.masterOrder.star2starLocationId = locationId;

      // Make use of existing initial quote info logic for ex/new and Addon quotes
      const initialQuoteInfo = await this.getQuoteFromTrackingAPI(orderType, {
        billingDealerId,
        customerId,
        locationId,
        userId: AuthContext.model.userId,
        pricebookId: this.masterOrder.pricebookId,
        dealerId: dealerId,
      });

      await this.asyncUpdateQuoteWithInitialQuoteInfo({
        initialQuoteInfo,
        customerId,
        billingDealerId,
        orderType,
        locationIds: locationId ? [locationId] : [],
      });

      // Set Master Order props received from Sales Force on top of existed initial quote info
      this.masterOrder.customerName = customerName;

      if (locationName) {
        this.masterOrder.serviceInfo.locationName = locationName;
      }
    }

    // Extend Service Info by Sales Force Data
    for (let propName in serviceInfo) {
      const value = serviceInfo[propName];

      if (!value) {
        continue;
      }

      this.masterOrder.serviceInfo[propName] = value;
    }

    if (name) {
      this.masterOrder.quoteTitle = name;
    }

    AuthContext.model.customerOrder = this.masterOrder;
    this.appState.quoteLoading = false;
    this.validateQuote();

    // Trigger calculation right after quote initialization if there are no validation errors
    if (!this.models.saveButtonModel.errorsCnt) {
      this._onQuoteChange(true, true);
    }

    const { details, msgTemplateStringVariables } = Validate.validateSfResponse(opportunity);

    if (details) {
      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_insufficient_sf_data',
        details,
        msgTemplateStringVariables,
        autoHideDelay: 0,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.ERROR,
        priority: 9999,
      });
    }

    this.renderView();
  }

  _autoAddLineItemsOnQuoteInit() {
    const autoAddedLineItems = apiData.lineItems
      .filter(item => item.active)
      .filter(activeItem => activeItem.catalogFlags?.length)
      .filter(itemWithFlag =>
        itemWithFlag.catalogFlags.some(catalog => ['isFixedOnUi', 'autoAddOnNewQuote'].includes(catalog.flag.name))
      );

    for (let i = 0; i < autoAddedLineItems.length; i++) {
      const data = autoAddedLineItems[i];

      // Special case for ICTPPS line items
      // For ICTPPS line items count = 1 means that this provider is selected
      if (data.lineItemCategoryId === constants.ICTP_CATEGORY_ID) {
        // KM-5799 skip non-selectable items, active skipped above
        if (!data.selectable) {
          continue;
        }

        const quantity = data.catalogFlags.find(d => d.flag.name === 'defaultIctppsItem') ? 1 : 0;

        this.addLineItem({
          ...data,
          quantity,
        });

        // Break further logic
        continue;
      }

      if (!data.allowedToAdd) {
        continue;
      }

      // Non ICTPPS items logic
      const autoAddOnNewQuote = data.catalogFlags.find(d => d.flag.name === 'autoAddOnNewQuote');

      if (autoAddOnNewQuote && !data.selectable) {
        continue;
      }

      this.addLineItem(
        autoAddOnNewQuote
          ? {
              ...data,
              // KM-5496: Use "autoAddOnNewQuote" string value as a default quantity value
              quantity: Number(autoAddOnNewQuote.valueAsString),
            }
          : data
      );
    }
  }

  get props() {
    const quotes = this.quotes.toJS();

    const customerOrder = quotes[0];
    const locationQuotes = [];

    for (let i = 1; i < quotes.length; i++) {
      locationQuotes.push(quotes[i]);
    }

    const appState = this.appState.toJS();
    const toolbar = this.models.toolbarModel.toJS();

    // toolbar buttons
    const amendmentButton = this.models.amendmentModel.toJS();
    const approvalButton = this.models.approvalModel.toJS();
    const cancelAmendmentButton = this.models.cancelAmendmentModel.toJS();
    const finalizeButton = this.models.finalizeModel.toJS();
    const finalizedButton = this.models.finalizedModel.toJS();
    const preliminaryButton = this.models.preliminaryButtonModel.toJS();
    const printFinalButton = this.models.printFinalButtonModel.toJS();
    const saveButton = this.models.saveButtonModel.toJS();
    const sendForSignatureButton = this.models.sendForSignatureModel.toJS();
    const signaturesButton = this.models.signaturesButtonModel.toJS();
    const slexButton = this.models.slexButtonModel.toJS();

    // HUD packages

    const appErrors = appState.errors;
    const packageOne = this.models.packageOneModel.toJS();
    const packageTwo = this.models.packageTwoModel.toJS();
    const packageThree = this.models.packageThreeModel.toJS();
    const packageFour = this.models.packageFourModel.toJS();

    const addLineItemsGlobalDialog = this.models.addLineItemsGlobalDialog.toJS();

    const adminSection = this.models.adminSectionModel.toJS();
    const addendumSection = this.models.addendumSectionModel.toJS();
    const sangomaCxSettingsSection = this.models.sangomaCxSettingsSectionModel.toJS();
    const daasSection = this.models.daasSectionModel.toJS();
    const partnerInformationSection = this.models.partnerInformationSectionModel.toJS();
    const customerInformationSection = this.models.customerInformationSectionModel.toJS();
    const quoteInformationSection = this.models.quoteInformationSectionModel.toJS();

    return {
      ...this.__props,
      quotes,
      customerOrder,
      locationQuotes,
      appState,
      appErrors,
      toolbar,
      toolbarButtons: {
        amendmentButton,
        approvalButton,
        cancelAmendmentButton,
        finalizeButton,
        finalizedButton,
        preliminaryButton,
        printFinalButton,
        saveButton,
        sendForSignatureButton,
        signaturesButton,
        slexButton,
      },
      HUDPackages: {
        packageOne,
        packageTwo,
        packageThree,
        packageFour,
      },
      selectedQuote: this.selectedQuote,
      isQuoteOverAllocated: this.isQuoteOverAllocated(customerOrder),
      addLineItemsGlobalDialog,
      sections: {
        adminSection,
        addendumSection,
        sangomaCxSettingsSection,
        daasSection,
        partnerInformationSection,
        customerInformationSection,
        quoteInformationSection,
      },
    };
  }

  _isLineItemRecurring(lineItem) {
    const category = apiData.categories.find(d => d.id === lineItem.lineItemCategoryId);

    return category === undefined ? null : category.recurring;
  }

  _calcBundleDealerNet(parent, level = 0) {
    let dealerNet = 0;
    const subLineItems = parent.subLineItems;

    if (!subLineItems.length) {
      // Top level item is not a bundle
      if (level === 0) {
        return null;
      }

      // Else return child's dealerNet value
      return parent.dealerNet;
    }

    const isParentRecurring = this._isLineItemRecurring(parent);

    // Bundle has it's own dealerNet
    if (parent.dealerNet !== 0) {
      // Return null for bundle price if it is a top level item
      if (level === 0) {
        return null;
      }

      // Else add bundle dealerNet value into SUM
      dealerNet = fixFloat(dealerNet) + fixFloat(parent.dealerNet);
    }

    for (let i = 0; i < subLineItems.length; i++) {
      const childId = subLineItems[i].subLineItemId;
      const child = apiData.lineItems.find(d => d.lineItemId === childId);

      // If child not found it indicates DB broken links
      if (!child) {
        if (config.showConsoleLog) {
          console.warn(
            `Line Item [${parent.lineItemId}] has sub Line Item [${childId}] link. But child data missing in API response.`
          );
        }

        continue;
      }

      const isChildRecurring = this._isLineItemRecurring(child);

      // [Non]Recurring type of parent and child should match
      if (isParentRecurring !== isChildRecurring) {
        // Skip child item if types does not match
        continue;
      }

      dealerNet += this._calcBundleDealerNet(child, level + 1);
    }

    return dealerNet;
  }

  async asyncInitNewQuote(partnerId, serviceType) {
    await this.asyncSetBillingDealer(partnerId);

    this.masterOrder.pricebookId = (
      await this.asyncRequestPriceBookId(this.masterOrder.dealerId, this.masterOrder.customerId, undefined, serviceType)
    ).content?.pricebookId;

    const { orderType, pricebookId, dealerId } = this.masterOrder;

    await this.asyncLoadPricebookCatalog(orderType, serviceType, pricebookId, null, true, dealerId);

    this.appState.isPriceBookRequested = false;

    AuthContext.model.customerOrder = this.customerOrder;

    if (PartnerContext.model.isWhiteLabelWholesale) {
      this.billingInfoAdjustmentsForWlw();
    }

    // Trigger calculation right after quote initialization if there are no validation errors
    if (!this.models.saveButtonModel.errorsCnt) {
      this._onQuoteChange(true, true);
    }
  }

  addLineItem(data, doRecursion = true, syncLocations = true) {
    const lineItem = data.existing
      ? this.masterOrder.lineItems.addExisting(data, doRecursion)
      : this.masterOrder.lineItems.add(data, doRecursion);

    if (this.masterOrder.orderType === ORDER_TYPES.REDUCTION) {
      lineItem.quantity = 0;
    }

    if (syncLocations) {
      for (let i = 1; i < this.quotes.items.length; i++) {
        this.quotes.items[i].syncLineItems();
      }
    }

    return lineItem;
  }

  rollUpOrder() {
    this.rollUpDisabled = true;
    this.renderView();

    this.masterOrder.numStarCloudLocations = this.masterOrder.numStarCloudLocationsCalculated;

    this.masterOrder.lineItems.items.forEach(
      /** @type {LineItemModel} */ li => {
        if (li.allocated > li.quantityWithOverride && li.lineItemCategoryId !== constants.ICTP_CATEGORY_ID) {
          if (li.allocatedOverride !== null || li.overrideQuantity !== null) {
            li.overrideQuantity = li.allocated;
          } else if (li.cellTypes.quantity === 'NumberInput') {
            li.quantity = li.allocated;
          }
        }
      }
    );

    if (this.quotes.locationQuotes.length > this.masterOrder.numLocations) {
      this.masterOrder.numLocations = this.quotes.locationQuotes.length;
    }

    // Roll up ictp values
    for (let i = 0; i < this.masterOrder.ictpProviders.providers.length; i++) {
      const selectedProvider = this.masterOrder.ictpProviders.providers[i].selectedOption;

      if (selectedProvider.price < selectedProvider.allocated) {
        let priceType = selectedProvider.overrideValue !== null ? 'overrideValue' : 'dealerNet';
        const ictpChilds = [];

        // get all childs of MO ICTPPS line item
        for (let j = 0; j < this.quotes.locationQuotes.length; j++) {
          for (let k = 0; k < this.quotes.locationQuotes[j].ictpProviders.providers.length; k++) {
            const locationIctpItem = this.quotes.locationQuotes[j].ictpProviders.providers[k].selectedOption;

            if (selectedProvider.uuid === locationIctpItem.parentUuid) {
              ictpChilds.push(locationIctpItem);
            }
          }
        }

        // check if any of location ICTPPS line items is overridden
        const ictpChildIsOverridden = ictpChilds.some(provider => provider.overrideValue !== null);

        // check if dealerNet is editable, if not edit override value if it is available to edit
        if (
          priceType === 'dealerNet' &&
          !selectedProvider.isPartner &&
          ictpChildIsOverridden &&
          AuthContext.model.hasSalesOpsPermissions
        ) {
          priceType = 'overrideValue';
        }

        selectedProvider[priceType] = selectedProvider.allocated;
      }
    }

    // Roll up num lines
    if (
      [2, 4].includes(this.masterOrder.packageIdSelected) &&
      this.masterOrder.numLines < this.masterOrder.numLinesAllocated
    ) {
      if (this.masterOrder.useNumLinesDefault) {
        this.masterOrder.useNumLinesDefault = false;
      }

      this.masterOrder.numLines = this.masterOrder.numLinesAllocated;
    }

    if (this.masterOrder.numSwitchvoxOnPremUsersAllocated > this.masterOrder.numSwitchvoxOnPremUsers) {
      this.masterOrder.numSwitchvoxOnPremUsers = this.masterOrder.numSwitchvoxOnPremUsersAllocated;
    }

    if (this.masterOrder.smartOfficeLocationsQty < this.masterOrder.smartOfficeLocationsQtyByLocation) {
      this.masterOrder.smartOfficeLocationsQty = this.masterOrder.smartOfficeLocationsQtyByLocation;
    }

    this.__redoRollUpCheck = true;
    this.modelChanged();
  }

  _isQuoteRequireRollUp(quoteData) {
    if (quoteData.numStarCloudLocationsCalculated > quoteData.numStarCloudLocations) {
      return true;
    }

    if (
      quoteData.locations.length > quoteData.numLocations ||
      quoteData.ictpProviders.isPriceExceeded ||
      ([2, 4].includes(quoteData.packageIdSelected) && quoteData.numLines < quoteData.numLinesAllocated)
    ) {
      return true;
    }

    // No need to loop thru line items allocated values in case there is only master order in a quote
    if (quoteData.locations.length === 0) {
      return false;
    }

    for (let i = 0; i < quoteData.lineItems.length; i++) {
      let lineItem = quoteData.lineItems[i];

      if (!lineItem.active || lineItem.hidden || lineItem.getFlagValue('skipRollUp') === 'TRUE') {
        continue;
      }

      if (lineItem.allocated > lineItem.quantityWithOverride) {
        return true;
      }
    }

    if (quoteData.numSwitchvoxOnPremUsersAllocated > quoteData.numSwitchvoxOnPremUsers) {
      return true;
    }

    if (quoteData.smartOfficeLocationsQty < quoteData.smartOfficeLocationsQtyByLocation) {
      return true;
    }

    return false;
  }

  isQuoteOverAllocated(quoteData) {
    return this._isQuoteRequireRollUp(quoteData);
  }

  checkRollUp(quoteData) {
    this.rollUpDisabled = !this._isQuoteRequireRollUp(quoteData);

    if (this.rollUpDisabled === false && this.__redoRollUpCheck) {
      this.checkRollUpWarning();
    }

    this.__redoRollUpCheck = false;

    return this.rollUpDisabled;
  }

  checkRollUpWarning() {
    ErrorContext.model.setProps({
      isShown: true,
      message: 'msg_failed_roll_up_warning_message',
      details: 'msg_failed_roll_up_warning_details',
      autoHideDelay: 0,
      showCloseButton: true,
      type: ERROR_CONTEXT_TYPES.WARNING,
    });
  }

  async asyncQuoteCloneOrCreateRevision(action, resetOverrides, industryCodeId) {
    if (!this.__orderType) {
      return false;
    }

    const quotes = this.quotes.items;
    const masterOrder = this.quotes.masterOrder;
    const locationQuotes = this.quotes.locationQuotes;
    const locationsS2sIds = this.quotes.locationsS2sIds;
    const originalQuoteId = masterOrder.id;
    const originalQuoteServiceType = masterOrder.serviceType;
    masterOrder.quoteOperationMode = QUOTE_OPERATION_MODES[action.toUpperCase()];
    masterOrder.originalPricebookId = masterOrder.pricebookId;

    // Reset line items data
    const lineItemsCount = masterOrder.lineItems.items.length;

    for (let i = 0; i < lineItemsCount; i++) {
      /** @type {LineItemModel} */
      const lineItem = masterOrder.lineItems.items[i];

      // Reset line item overrides
      if (resetOverrides) {
        lineItem.overrideValue = null;
        lineItem.overrideQuantity = null;
        lineItem.numPortsOverride = null;

        lineItem.locationSiblings.forEach(
          /** @type {LocationLineItemModel} */ locationLineItem => {
            locationLineItem.overrideValue = null;
            locationLineItem.overrideQuantity = null;
          }
        );
      }

      // Reset line item UUID on master order level
      lineItem.quoteLineItemUuid = UUID();
    }

    // Reset quotes data
    for (let i = 0; i < quotes.length; i++) {
      /** @type {QuoteModel} */
      const quote = quotes[i];

      quote.setProps({
        id: null,
        quoteStatus: QUOTE_STATUS.OPEN,
        trackingLocationId: null,
        amendmentTaskNumber: '',
        lockedInPrintFlow: false,
        useESigDocumentsFlow: masterOrder.useESigDocumentsFlowDefaultValue,
      });

      // Reset locationUuid for all locations
      if (i > 0) {
        quote.locationUuid = UUID();
      }
    }

    this.appState.isEdited = true;
    this.appState.isCalcRequested = false;
    this.appState.isCalculated = true;
    this.appState.isSaveRequested = false;
    this.appState.isEmailValidationRequested = false;

    // KM-7858: Wipe the alternate description and reset to not use one for discounts
    masterOrder.spiffDiscount.nonRecurringDiscountAltDescription = null;
    masterOrder.spiffDiscount.recurringDiscountAltDescription = null;

    // Reset props for clone action
    if (action === QUOTE_COPY_ACTION.CLONE) {
      this.copyAction = QUOTE_COPY_ACTION.CLONE;

      for (let i = 0; i < quotes.length; i++) {
        /** @type {QuoteModel} */
        const quote = quotes[i];
        const locationName = quote.serviceInfo.locationName;

        quote.serviceInfo = new ServiceInfoModel(quote);
        quote.shippingInfo = new ShippingInfoModel(quote);
        quote.billingInfo = new BillingInfoModel(quote);

        if (PartnerContext.model.isWhiteLabelWholesale) {
          this.billingInfoAdjustmentsForWlw();
        }

        quote.serviceInfo.locationName = locationName;
        // KM-12486: reset star2starLocationId on clone; KM-12535 do not reset for addons
        if (quote.orderType !== ORDER_TYPES.ADD_ON) {
          quote.star2starLocationId = null;
        }
      }

      masterOrder.masterOrderId = null;
      masterOrder.isRevisionOfQuoteId = null;
      masterOrder.locationName =
        this.__orderType === ORDER_TYPES.NEW_CUSTOMER ? 'Master Order' : masterOrder.serviceInfo.locationName;
      masterOrder.dba = null;
      masterOrder.saSigningDate = null;
      masterOrder.taxExempt = null;
      masterOrder.salesforceOpportunityId = null;
      masterOrder.salesforceCustomerAccountId = null;

      if (this.__orderType === ORDER_TYPES.NEW_CUSTOMER) {
        masterOrder.customerName = null;
        masterOrder.customerId = 0;
      }

      if (this.__orderType === ORDER_TYPES.ADD_ON) {
        masterOrder.star2starLocationId = this.__locationIds[0];
      }

      if (industryCodeId) {
        masterOrder.serviceInfo.industryCodeId = industryCodeId;
      }
    }

    // Store original quote id into variable
    // This will block partner selection
    if (action === QUOTE_COPY_ACTION.REVISION) {
      this.copyAction = QUOTE_COPY_ACTION.REVISION;
      masterOrder.isRevisionOfQuoteId = originalQuoteId;
    }

    // Update Quote prior new pricebook catalog
    const initialOrderType = masterOrder.orderType;
    const initialPricebookId = masterOrder.pricebookId;
    const initialPricebookVersionId = masterOrder.pricebookVersionId;

    // Collect sub-set of line items data to use after new pricebook version load
    const quoteDataSubSet = {
      lineItems: masterOrder.lineItems.items.map(d => {
        const {
          quantity,
          productId,
          quoteLineItemUuid,
          partnerProductName,
          markupValue,
          numPortsOverride,
          overrideQuantity,
          overrideValue,
          dealerNet,
          isPartner,
          existing,
        } = d;

        // Collect dealerNet of Partner added and ICTP partner line items
        const isDealerNetMutable = d instanceof PartnerLineItemModel || (d instanceof IctpLineItemModel && isPartner);

        const props = {
          quantity,
          productId,
          quoteLineItemUuid,
          partnerProductName,
          markupValue,
          dealerNet,
          existing,
        };

        if (!isDealerNetMutable) {
          delete props.dealerNet;
        }

        if (!resetOverrides) {
          Object.assign(props, {
            numPortsOverride,
            overrideQuantity,
            overrideValue,
          });
        }

        return props;
      }),
      locations: locationQuotes.map(l => ({
        locationUuid: l.locationUuid,
        lineItems: l.lineItems.items.map(d => {
          const { parentQuoteLineItemUuid, quantity, isPartner, dealerNet, overrideValue, overrideQuantity } = d;

          // Collect ICTP's partner line items dealerNet only
          // Partner added line items does not have editable price on locations
          const isDealerNetMutable = d instanceof LocationIctpLineItemModel && isPartner;

          const props = {
            parentQuoteLineItemUuid,
            quantity,
            dealerNet: isDealerNetMutable ? dealerNet : undefined,
          };

          if (!resetOverrides) {
            Object.assign(props, {
              overrideValue,
              overrideQuantity,
            });
          }

          return props;
        }),
      })),
    };

    masterOrder.pricebookVersionId = null;

    // Remove all line items on Master Order and Locations level
    masterOrder.lineItems.wipe();

    // KM-8594: Collect productIds of suppressed line items
    const suppressedLineItemProductIds = apiData.lineItems
      .filter(d => masterOrder.suppressedLineItemIds.includes(d.lineItemId))
      .map(d => d.productId);

    // KM-13222: Get intended pricebookId for the new Quote draft
    const pricebookResponse = await this.asyncRequestPriceBookId(
      masterOrder.dealerId,
      masterOrder.customerId,
      masterOrder.star2starLocationId,
      masterOrder.serviceType
    );
    const intendedPricebookId = pricebookResponse.content.pricebookId;
    this.appState.isPriceBookRequested = false;

    const isResetOverrides = AuthContext.model.hasSalesOpsPermissions ? resetOverrides : true;
    const setPackage = this.customerOrder.isSelectedPackageOverride ? isResetOverrides : false;

    // KM-10880: addendums reset
    if (!(AuthContext.model.isSalesOps && !resetOverrides && action === QUOTE_COPY_ACTION.REVISION)) {
      this.customerOrder.quoteAddendums = ADDENDUMS;
    }

    if (masterOrder.sipStationEnabled) {
      masterOrder.contractTermMonths = CONTRACT_TERM_MONTH_OPTIONS.FIVE_YEARS.value;
    }

    PriceBookContext.model.clearPBContext();

    await this.asyncLoadPricebookCatalog(
      initialOrderType,
      originalQuoteServiceType,
      intendedPricebookId,
      null,
      setPackage,
      masterOrder.dealerId
    );

    const currentPricebookVersionId = masterOrder.pricebookVersionId;

    // Log pricebook ID / version change
    if (config.showConsoleLog) {
      if (initialPricebookId === intendedPricebookId && initialPricebookVersionId === currentPricebookVersionId) {
        console.info('Clone / Revision: pricebookId and pricebookVersionId remains the same as in original quote.');
      } else {
        console.groupCollapsed('Clone / Revision');

        if (initialPricebookId !== intendedPricebookId) {
          console.log('pricebookId changed from ' + initialPricebookId + ' to ' + intendedPricebookId);
          console.log('Current pricebookVersionId is ' + currentPricebookVersionId);
        } else if (initialPricebookVersionId !== currentPricebookVersionId) {
          console.log('pricebookId remains the same as in original quote and is equal to ' + intendedPricebookId);
          console.log(
            'pricebookVersionId changed from ' + initialPricebookVersionId + ' to ' + currentPricebookVersionId
          );
        }

        console.groupEnd();
      }
    }

    if (PartnerContext.model.isWhiteLabelWholesale && masterOrder.isFinanced) {
      masterOrder.packageIdSelected = PriceBookContext.model.getDefaultPackageId();
    }

    // KM-13322: There might be a case when new Pricebook missing a package from original quote (Pricebook)
    //           in that case perform a mapping based on predefined map values.
    masterOrder.packageIdSelected = PriceBookContext.model.mapPackageIdIfNotAvailable(masterOrder.packageIdSelected);

    // Collect productIds that are missing in new pricebook (version)
    const removedProductIds = [];

    // Map items from new catalog with old one by pricebookId
    // TODO: Add support of multiple active items with same productId
    for (let i = 0; i < quoteDataSubSet.lineItems.length; i++) {
      const d = quoteDataSubSet.lineItems[i];
      const products = apiData.lineItems.filter(({ productId }) => productId === d.productId);
      const activeProduct = products.find(({ active }) => active);

      if (activeProduct) {
        d.lineItemId = activeProduct.lineItemId;

        continue;
      }

      const inactiveProduct = products.find(({ active }) => active === false);

      if (!inactiveProduct) {
        // productId is not available in current pricebook
        // Mark such items as removed, they will be skipped on data update loop
        removedProductIds.push(d.productId);

        continue;
      }

      d.lineItemId = inactiveProduct.lineItemId;
    }

    // Auto added line items is already in quote at this point
    // Add new line items or update existed with data collected from original quote
    for (let i = 0; i < quoteDataSubSet.lineItems.length; i++) {
      const props = quoteDataSubSet.lineItems[i];

      // Skip products that are not available in current pricebook
      if (removedProductIds.includes(props.productId)) {
        continue;
      }

      const existedModel = this.masterOrder.lineItems.items.find(d => d.lineItemId === props.lineItemId);

      // Update existed model if it is unique item
      // (quote can contain only one instance of the item)
      // It can happen when line item is auto added item
      // So it is already added at catalog init
      // But we still need to update quantities to match original quote
      if (existedModel?.isUniqueByLineItemId) {
        existedModel.setProps(props);

        continue;
      }

      this.addLineItem(props, true, false);
    }

    // Sync location line items
    this.quotes.locationQuotes.forEach(
      /** @type {LocationQuoteModel} */ l => {
        l.lineItems = quoteDataSubSet.locations.find(d => l.locationUuid === d.locationUuid).lineItems;
      }
    );

    // Update ICTP selected items
    for (let i = 0; i < this.masterOrder.ictpProviders.providers.length; i++) {
      /** @type {ProviderModel} */
      const providerModel = this.masterOrder.ictpProviders.providers[i];
      /** @type {IctpLineItemModel} */
      const originallySelectedItem = providerModel.options.find(d => d.active && d.quantity > 0);

      if (!originallySelectedItem) {
        continue;
      }

      providerModel.selectOption(originallySelectedItem.lineItemId, false);
    }

    // KM-8594: Map suppressed items' productId to lineItemId
    if (!resetOverrides) {
      const suppressedLineItemIds = apiData.lineItems
        .filter(d => d.active && suppressedLineItemProductIds.includes(d.productId))
        .map(d => d.lineItemId);

      masterOrder.suppressedLineItemIds = suppressedLineItemIds;
    }

    // Set financing option to rental if it is available
    if (PriceBookContext.model.isSupportsRental && masterOrder.orderType !== ORDER_TYPES.ADD_ON) {
      masterOrder.financingOption = FINANCING_OPTIONS.RENTAL_GREATAMERICA;
    }

    // KM-6868: Display some generic message in case some products are missing in new pricebook or version
    if (removedProductIds.length) {
      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_pricebook_missing_some_products_message',
        autoHideDelay: 0,
        showCloseButton: true,
      });
    }

    this._onQuoteChange(true);

    // Clear Signature History
    masterOrder.signatureHistory = [];

    // Use config value for ESigFlow toggle state
    if (
      ORDER_TYPES.ADD_ON !== masterOrder.orderType ||
      (ORDER_TYPES.ADD_ON === masterOrder.orderType && masterOrder.isRental)
    ) {
      masterOrder.useESigDocumentsFlow = !Boolean(apiData.properties.esignature.defaultToPrintFlow);
    }

    // Reset MAD overrides if any
    if (resetOverrides) {
      masterOrder.madPoolOverride = false;
      masterOrder.spiffOverrideEnabled = false;
      masterOrder.madUpfrontOverride = 0;
      masterOrder.madRecurringOverride = 0;
      masterOrder.spiffOverride = 0;
      masterOrder.confirmedSpiffDiscount = false;
      masterOrder.suppressedLineItemIds = [];
      masterOrder.additionalRcDiscountPercent = 0;
      masterOrder.additionalUpfrontDiscountPercent = 0;
    }

    // Reset expiration date override
    masterOrder.expirationDateOverride = null;

    // TODO: This code hides ALS and Supply Shortage banners on quote init
    //       Originally added in scope of KM-10723. It has to be reviewed and reworked
    // if (ErrorContext.model.isShown) {
    //   ErrorContext.model.setProps({
    //     isShown: false,
    //   });
    // }

    this.setDefaultQuoteTitle();

    const { billingDealerId, customerId, star2starLocationId, businessContinuity, serviceType } = this.masterOrder;
    const bcChunk = '?bc=' + Number(businessContinuity);
    const serviceTypeChunk = serviceType !== '' ? '&serviceType=' + serviceType : '';

    switch (this.__orderType) {
      case ORDER_TYPES.NEW_CUSTOMER:
        AppRouter.pushWithoutCallback(`partners/${billingDealerId}/new-customer${bcChunk}${serviceTypeChunk}`);
        break;

      case ORDER_TYPES.NEW_LOCATIONS:
        if (!this.__originalQuoteId) {
          AppRouter.pushWithoutCallback(
            `partners/${billingDealerId}/customers/${customerId}/locations/new${bcChunk}${serviceTypeChunk}`
          );
        }
        break;

      case ORDER_TYPES.ADD_ON:
        if (!this.__originalQuoteId) {
          AppRouter.pushWithoutCallback(
            `partners/${billingDealerId}/customers/${customerId}/locations/${star2starLocationId}/add-on${bcChunk}${serviceTypeChunk}`
          );
        }
        break;

      case ORDER_TYPES.REDUCTION:
        AppRouter.pushWithoutCallback(
          `partners/${billingDealerId}/customers/${customerId}/locations/${star2starLocationId}/reduction${bcChunk}${serviceTypeChunk}`
        );
        break;

      case ORDER_TYPES.REWRITE:
        AppRouter.pushWithoutCallback(
          `partners/${billingDealerId}/customers/${customerId}/rewrite?locationIds=${locationsS2sIds}&${bcChunk}${serviceTypeChunk}`
        );
        break;

      default:
        console.log(`%cOrder type is incorrect: ${this.__orderType}.`, 'color: red; forn-weight: bold;');
        break;
    }
  }

  handleQuoteClone(resetOverrides = true, industryCodeId) {
    if (this.__orderType === ORDER_TYPES.NEW_CUSTOMER) {
      this.asyncQuoteCloneOrCreateRevision(QUOTE_COPY_ACTION.CLONE, resetOverrides, industryCodeId);
    } else {
      const props = {
        pathname: '/create/' + this.masterOrder.id + '/' + ORDER_TYPES_LINKS[this.__orderType],
        state: { resetOverrides },
      };

      AppRouter.push(props);
    }
  }

  handleQuoteCreateRevision(resetOverrides = true) {
    return this.asyncQuoteCloneOrCreateRevision(QUOTE_COPY_ACTION.REVISION, resetOverrides);
  }

  resetQuoteModel() {
    this.quotes = new QuotesCollectionModel(this);
    this.masterOrder.orderType = this.__orderType;
  }

  async asyncSetBillingDealer(billingDealerId) {
    const data = {
      billingDealerId,
      quoteId: this.masterOrder.id,
    };
    const apiFunction = data.quoteId ? IntegrationApi.getQuoteDealerContacts : IntegrationApi.getDealerContacts;

    try {
      const [response, crmResponse] = await Promise.all([
        apiFunction(data),
        IntegrationApi.getShippingInfoByPartner({ partnerId: billingDealerId }),
      ]);

      let isParnterEnable = true;

      FIELDS_FOR_CHECK_PARTNER.forEach(key => {
        if (crmResponse[key] || response.length === 0) {
          this.appState.forceLocked = true;
          isParnterEnable = false;
        }
      });

      if (!isParnterEnable) {
        ErrorContext.model.setProps({
          isShown: true,
          message: 'msg_this_quote_is_assigned_to_a_partner_who_is_currently_disabled',
        });

        this.renderView();
      }

      this.masterOrder.billingDealerId = billingDealerId;
      this.masterOrder.dealerContacts = Array.isArray(response) ? response : [];

      if (!this.masterOrder.dealerId) {
        const dealers = apiData.dealerships;
        const dealer = dealers.find(_dealer => _dealer.billingDealerId === billingDealerId);

        if (dealer) {
          this.masterOrder.dealerId = dealer.dealerId;
          this.masterOrder.dealerName = dealer.dealerName;
          this.masterOrder.dealerIsAgent = dealer.agent || false;
        }
      }

      this.appState.errors.setProps({
        dealerContactUserId: Validate.dealerContactUserId(this.masterOrder).code,
      });

      return isParnterEnable ? response : false;
    } finally {
      this.renderView();
    }
  }

  async asyncOnClickSaveBtn() {
    const { quoteStatus, orderType, customerId, numLocations, numberOfLocationsWithTrackingId } = this.masterOrder;
    const validStatusList = [
      QUOTE_STATUS.OPEN,
      QUOTE_STATUS.FINALIZED,
      QUOTE_STATUS.UNFINALIZED,
      QUOTE_STATUS.SE_PENDING_APPROVAL,
      QUOTE_STATUS.SENT_FOR_SIGNATURE,
      QUOTE_STATUS.SIGNED_AND_FINALIZED,
      QUOTE_STATUS.AMENDING,
    ];

    if (quoteStatus && !validStatusList.includes(quoteStatus)) {
      return;
    }

    this.appState.isSaveRequested = true;
    this.renderView();

    const callback = [
      QUOTE_STATUS.FINALIZED,
      QUOTE_STATUS.SENT_FOR_SIGNATURE,
      QUOTE_STATUS.SIGNED_AND_FINALIZED,
    ].includes(quoteStatus)
      ? this.handleLocationsSave
      : this.handleMasterOrderSave;

    if ([ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS].includes(orderType)) {
      const numLocationsExceeded = await TrackingApi.numLocationsExceeded(
        customerId,
        numLocations,
        numberOfLocationsWithTrackingId
      );

      if (numLocationsExceeded) {
        numLocationsExceededErrorBanner();
      }
    }

    try {
      const response = await this.asyncRequestMasterOrderSave();
      return callback(response);
    } catch (e) {
      if (e.message === QUOTE_OPERATION_STATE_ERROR) {
        this.appState.quoteOperationStateIsUpdating = true;

        ErrorContext.model.setProps({
          isShown: true,
          details: 'msg_quote_operation_state_updating_banner_message',
          autoHideDelay: 0,
          showCloseButton: true,
          type: ERROR_CONTEXT_TYPES.ERROR,
          priority: 9999,
        });

        this.renderView();

        return;
      }

      throw e;
    }
  }

  prelimPdfBtnEndpoint() {
    return '/quotes/preliminary/' + this.masterOrder.id + '/pdf/' + this.masterOrder.packageIdSelected;
  }

  partnerPdfEndpoint() {
    return '/quotes/partner/' + this.masterOrder.id + '/pdf';
  }

  modelChanged(model) {
    this._onQuoteChange(true);
  }

  useDataForDiscount() {
    const itemResult = this.masterOrder.calcResults.results;
    const packageIdSelected = this.masterOrder.packageIdSelected;

    _.forEach(DISCOUNT_FIELDS_MAP, (modelPropName, calcDataPointName) => {
      switch (itemResult[calcDataPointName]) {
        case 'null':
          this.masterOrder.spiffDiscount[modelPropName] = 0;
          break;
        case 'false':
          this.masterOrder.spiffDiscount[modelPropName] = false;
          break;
        case 'true':
          this.masterOrder.spiffDiscount[modelPropName] = true;
          break;
        default:
          this.masterOrder.spiffDiscount[modelPropName] = itemResult[calcDataPointName]
            ? parseFloat(itemResult[calcDataPointName])
            : 0;
          break;
      }
    });

    _.forEach(DISCOUNT_FIELDS_MAP_EACH_PACKAGE, (modelPropName, calcDataPointName) => {
      switch (itemResult[calcDataPointName]) {
        case 'null':
          this.masterOrder.spiffDiscount[modelPropName] = 0;
          break;
        case 'false':
          this.masterOrder.spiffDiscount[modelPropName] = false;
          break;
        case 'true':
          this.masterOrder.spiffDiscount[modelPropName] = true;
          break;
        default:
          this.masterOrder.spiffDiscount[modelPropName] = itemResult[calcDataPointName + packageIdSelected]
            ? parseFloat(itemResult[calcDataPointName + packageIdSelected])
            : 0;
          break;
      }
    });

    this.masterOrder.spiffDiscount.updateSliderLimits();
  }

  /** @return {CustomerOrderModel} */
  get customerOrder() {
    return this.quotes.customerOrder;
  }

  /**
   * New alias for Customer Order
   * @return {CustomerOrderModel}
   */
  get masterOrder() {
    return this.quotes.customerOrder;
  }

  get appErrors() {
    return this.appState.errors;
  }

  get selectedQuote() {
    return this.quotes.selectedQuote;
  }

  get dealers() {
    if (
      apiData.dealerships.length > 0 &&
      this.masterOrder.billingDealerId &&
      this.masterOrder.orderType !== ORDER_TYPES.NEW_CUSTOMER
    ) {
      const dealers = apiData.dealerships.find(d => d.billingDealerId === this.masterOrder.billingDealerId);
      return [dealers];
    }

    return apiData.dealerships;
  }

  selectLocationQuote(locationQuote, doRender = true) {
    this.quotes.selectedQuote = locationQuote;

    // KM-4539 Mixed ICTP
    // this.sumLocMixed();

    if (doRender) {
      this.renderView();
    }
  }

  selectLocationQuoteById(id) {
    const locationQuote = this.quotes.locationQuotes.find(d => d.id === id);

    if (locationQuote) {
      this.selectLocationQuote(locationQuote);

      return true;
    }

    return false;
  }

  addLocationQuote(locationQuote, singleLocation = false, triggerQuoteChange = true) {
    const newLocationQuote = locationQuote || new LocationQuoteModel(this.quotes);

    this.quotes.items.push(newLocationQuote);

    if (singleLocation) {
      const serviceInfo = this.masterOrder.serviceInfo.toJSON();
      const shippingInfo = this.masterOrder.shippingInfo.toJSON();
      const billingInfo = this.masterOrder.billingInfo.toJSON();

      newLocationQuote.syncLineItems(true);
      newLocationQuote.ictpProviders.init();

      newLocationQuote.serviceInfo.setProps(serviceInfo);
      newLocationQuote.shippingInfo.setProps(shippingInfo);
      newLocationQuote.billingInfo.setProps(billingInfo);
      newLocationQuote.ictpDates.dateInstall = this.masterOrder.ictpDates.dateInstall;
      newLocationQuote.ictpDates.dateShippingRequested = this.masterOrder.ictpDates.dateShippingRequested;
      newLocationQuote.serviceInfo.locationName = 'Location #1';

      if ([2, 4].includes(this.masterOrder.packageIdSelected)) {
        newLocationQuote.numLines = this.masterOrder.numLines;
      }

      newLocationQuote.ictpProviders.providers.forEach(provider => {
        provider.selectedOption.dealerNet = provider.selectedOption.available;
      });

      newLocationQuote.allocateAllCustomerLevelPackagedApplications = true;
    } else {
      newLocationQuote.syncLineItems();
      newLocationQuote.ictpProviders.init();
    }

    this.selectLocationQuote(newLocationQuote, false);
    this.rollUpDisabled = this.quotes.locationQuotes.length <= this.masterOrder.numLocations;

    if (Validate.isLocationUniqueUI(this.quotes.locationsNames) === '' && this.masterOrder.id) {
      Validate.isLocationUniqueBE(this.masterOrder.id, newLocationQuote.serviceInfo.locationName).then(({ code }) => {
        newLocationQuote.nameIsUniqueOnBe = code === '';
        this.appState.errors.isLocationUnique = Validate.isAllLocationsUnique(
          this.quotes.locationsNames,
          this.masterOrder
        );
      });
    }

    if (triggerQuoteChange) {
      this._onQuoteChange(true);
    }
    // KM-4539 Mixed ICTP
    // this.setMixed();
  }

  cloneLocationQuote(numberOfIterations = 1) {
    const _serviceInfo = [
      'locationName',
      'streetAddress1',
      'streetAddress2',
      'city',
      'stateId',
      'zip',
      'contactOfficePhone',
      'contactMobilePhone',
    ];
    const _billingInfo = [
      'billToId',
      'contactFirstName',
      'contactLastName',
      'contactEmail',
      'contactOfficePhone',
      'contactMobilePhone',
    ];
    const _shippingInfo = [
      'contactFirstName',
      'contactLastName',
      'streetAddress1',
      'streetAddress2',
      'city',
      'stateId',
      'countryId',
      'zip',
    ];

    // TODO: Investigate. Does toJS returned model reference instead of new object
    const activeQuote = JSON.parse(JSON.stringify(this.quotes.items[this.selectedQuote.index].toJS()));

    for (let i = 0; i < numberOfIterations; i++) {
      // trigger quote change only on last location add
      const triggerQuoteChange = i === numberOfIterations - 1;

      const newLocationQuote = new LocationQuoteModel(this.quotes);

      newLocationQuote.smartOffice = activeQuote.smartOffice;
      newLocationQuote.solutionTypeId = activeQuote.solutionTypeId;
      newLocationQuote.syncLineItems();

      // KM-13813: Copy number of lines value from original location
      newLocationQuote.numLines = activeQuote.numLines;

      _serviceInfo.forEach(f => delete activeQuote.serviceInfo[f]);
      _billingInfo.forEach(f => delete activeQuote.billingInfo[f]);
      _shippingInfo.forEach(f => delete activeQuote.shippingInfo[f]);

      newLocationQuote.shippingInfo.setProps(activeQuote.shippingInfo);
      newLocationQuote.billingInfo.setProps(activeQuote.billingInfo);
      newLocationQuote.serviceInfo.setProps(activeQuote.serviceInfo);

      activeQuote.lineItems.forEach(item => {
        const locationLi = newLocationQuote.lineItems.items.find(d => d.parentUuid === item.parentUuid);
        locationLi.quantity = item.quantity;
      });

      this.addLocationQuote(newLocationQuote, false, triggerQuoteChange);
    }
  }

  copyBtnDisabled() {
    return (
      [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SENT_FOR_SIGNATURE, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(
        this.customerOrder.quoteStatus
      ) && this.quotes.locationQuotes.length >= this.customerOrder.numLocations
    );
  }

  async requestLocationDelete() {
    const selectedQuote = this.quotes.locationQuotes[this.selectedQuote.index - 1];
    const { id } = selectedQuote;
    const url = config.api.url + `/location/${id}`;

    // don't send request if location is not saved
    if (id === null) {
      return this.deleteLocationQuote();
    }

    return $http.instance.api.delete(url).then(() => {
      this.deleteLocationQuote();
    });
  }

  async requestLocationFinalize() {
    const locationQuote = this.quotes.locationQuotes[this.selectedQuote.index - 1];

    locationQuote.forceDisabled = true;
    this.renderView();

    const locationId = locationQuote.id;
    const uploadAndFinalize = this.customerOrder.quoteStatus === QUOTE_STATUS.SIGNED_AND_FINALIZED;
    let url = config.api.url + `/location/${locationId}/${uploadAndFinalize ? 'upload-finalize' : 'finalize'}`;

    try {
      const { status } = (await $http.instance.api.post(url))?.data;

      if (status === 200) {
        this.quotes.locationQuotes.find(el => el.id === locationId).quoteStatus = QUOTE_STATUS.FINALIZED;
      }
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      locationQuote.forceDisabled = false;
      this.renderView();
    }
  }

  async requestLocationUnFinalize() {
    const selectedQuote = this.quotes.locationQuotes[this.selectedQuote.index - 1];
    const locationId = selectedQuote.id;
    const url = config.api.url + `/location/${locationId}/unfinalize`;

    return $http.instance.api.post(url).then(data => {
      if (data.status === 200) {
        this.quotes.locationQuotes.find(el => el.id === locationId).quoteStatus = QUOTE_STATUS.OPEN;
        this._onQuoteChange(true);
      }
    });
  }

  deleteLocationQuote() {
    const index = this.selectedQuote.index;

    this.quotes.items.splice(index, 1);
    this.appState.errors.isLocationUnique = Validate.isAllLocationsUnique(this.quotes.locationsNames, this.masterOrder);
    this.selectLocationQuote(this.quotes.items[index - 1]);
    this.rollUpDisabled = this.quotes.locationQuotes.length <= this.masterOrder.numLocations;

    this._onQuoteChange(true);
  }

  changeLocationName(fieldId, value) {
    const location = this.quotes.items[this.selectedQuote.index];

    if (fieldId === 'locationName') {
      location.serviceInfo[fieldId] = value;

      let locationNameNotFill = this.quotes.items.some(el => el.locationName === '');

      this.appState.errors.locationName = locationNameNotFill ? 'FILL_LOCATION_NAME' : '';

      if (locationNameNotFill) {
        ErrorContext.model.setProps({
          isShown: true,
          message: 'msg_please_fill_in_location_name',
        });
      }
    }

    location.serviceInfo.onChange(fieldId, value);

    if (fieldId === 'locationName' && this.masterOrder.dealerContactUserId !== null && value !== '') {
      if (Validate.isLocationUniqueUI(this.quotes.locationsNames) === '' && this.masterOrder.id) {
        this.appState.locationNameValidationRequestsCount++;
        this.renderView();

        Validate.isLocationUniqueBE(this.masterOrder.id, value)
          .then(({ code }) => {
            location.nameIsUniqueOnBe = code === '';

            this.appState.errors.isLocationUnique = Validate.isAllLocationsUnique(
              this.quotes.locationsNames,
              this.masterOrder
            );
          })
          .finally(() => {
            this.appState.locationNameValidationRequestsCount--;
            this.renderView();
          });
      }
    }
  }

  getContactsFromPartner(targetModel) {
    IntegrationApi.getShippingInfoByPartner({ partnerId: this.masterOrder.billingDealerId })
      .then(parnterModel => {
        this.setContactsFromPartner(targetModel, parnterModel);
      })
      .catch(e => {
        throw e;
      })
      .finally(() => {
        this._onQuoteChange(true);
      });
  }

  setContactsFromPartner(targetModel, partnerModel) {
    targetModel.contactFirstName = partnerModel.firstname;
    targetModel.contactLastName = partnerModel.lastname;
    targetModel.streetAddress1 = partnerModel.address;
    targetModel.streetAddress2 = partnerModel.address2;
    targetModel.city = partnerModel.city;
    targetModel.stateId = partnerModel.stateId;
    targetModel.zip = partnerModel.zip;
    targetModel.countryId = partnerModel.countryId;
    targetModel.companyName = partnerModel.name;
  }

  getCopyContactModel({ level, model }) {
    const locationIndex = this.selectedQuote.index;

    if (level === 'masterOrder') {
      return this.masterOrder[model];
    } else if (level === 'location') {
      return this.quotes.items[locationIndex][model];
    }
  }

  handleCopyContacts(key, uuid, modelName) {
    const TARGET_MODEL = this.quotes.items.find(model => model[modelName].uuid === uuid)[modelName];

    TARGET_MODEL.copyFromValue = key;

    if (['COPY_FROM_PARTNER_MASTER_ORDER', 'COPY_FROM_PARTNER_LOCATION'].includes(key)) {
      TARGET_MODEL.cloneFromModel = null;
      this.getContactsFromPartner(TARGET_MODEL);
    } else if (key) {
      TARGET_MODEL.cloneFromModel = this.getCopyContactModel(COPY_CONTACT_MAP[key]);
      this._onQuoteChange(true);
    }
  }

  handleCopyToAllLocations(model, copyFromValue) {
    // Loop start from one because skip Master Order
    for (let i = 1; i < this.quotes.items.length; i++) {
      if (this.quotes.items[i].status === QUOTE_STATUS.FINALIZED) {
        continue;
      }

      this.quotes.items[i][model].cloneFromModel = this.masterOrder[model];
      this.quotes.items[i][model].syncCheckBoxValue = true;
      this.quotes.items[i][model].copyFromValue = copyFromValue;
    }

    this._onQuoteChange(true);
  }

  async initOrderTypeQuote(orderType, partnerId, customerId, locationIds = [], serviceType) {
    this.appState.quoteLoading = true;
    this.renderView();

    const partnersData = await IntegrationApi.getPartnersByUserId({ userId: AuthContext.model.userId });
    const partner = partnersData.find(d => d.billingDealerId === partnerId);

    const [pricebookResponse, dealerContacts] = await Promise.all([
      this.asyncRequestPriceBookId(partner.dealerId, customerId, locationIds[0], serviceType),
      IntegrationApi.getDealerContacts({ billingDealerId: partnerId }),
    ]);

    this.appState.isPriceBookRequested = false;

    const pricebookId = pricebookResponse?.content?.pricebookId;
    const setDefaultPackage = this.__originalQuoteId ? false : orderType !== ORDER_TYPES.ADD_ON;

    AuthContext.model.customerOrder = this.masterOrder;

    this.masterOrder.dealerContacts = Array.isArray(dealerContacts) ? dealerContacts : [];
    this.masterOrder.billingDealerId = partner.billingDealerId;
    this.masterOrder.customerId = customerId;
    // TODO: Investigate star2starLocationId value for REWRITE orders
    this.masterOrder.star2starLocationId = locationIds[0];
    this.masterOrder.dealerId = partner.dealerId;
    this.masterOrder.dealerName = partner.dealerName;
    this.masterOrder.dealerIsAgent = partner.agent;
    this.masterOrder.orderType = orderType;
    this.masterOrder.pricebookId = pricebookId;

    this.renderView();

    const [initialQuoteInfo] = await Promise.all([
      this.getQuoteFromTrackingAPI(orderType, {
        billingDealerId: partnerId,
        customerId,
        // TODO: Investigate for REWRITE
        locationId: locationIds[0],
        userId: AuthContext.model.userId,
        pricebookId,
        dealerId: partner.dealerId,
      }),
      // TODO: KM-11771: asyncGetPartnerFlags loaded in asyncInit
      //       Verify is additional call needed here or not.
      // this.asyncGetPartnerFlags(this.masterOrder.dealerId),
      this.asyncLoadPricebookCatalog(
        orderType,
        serviceType,
        pricebookId,
        null,
        setDefaultPackage,
        this.masterOrder.dealerId
      ),
    ]);

    await this.asyncUpdateQuoteWithInitialQuoteInfo({
      initialQuoteInfo,
      customerId,
      billingDealerId: partnerId,
      orderType,
      locationIds,
    });

    // Trigger calculation right after quote initialization if there are no validation errors
    if (!this.models.saveButtonModel.errorsCnt) {
      this._onQuoteChange(true, true);
    }

    this.appState.quoteLoading = false;

    this.validateQuote();
    this.renderView();
  }

  async asyncUpdateQuoteWithInitialQuoteInfo({ initialQuoteInfo, customerId, billingDealerId, orderType, locationIds }) {
    const newProps = {
      customerId,
      billingDealerId,
      overnightGuarantee: initialQuoteInfo.overnightGuarantee,
      shippingInfo: initialQuoteInfo.shippingInfo,
      contractTermMonths: initialQuoteInfo.contractTermMonths,
      dealerContactUserId: initialQuoteInfo.dealerContactUserId || initialQuoteInfo.userId,
      perExtensionPricing: initialQuoteInfo.perExtensionPricing,
      solutionTypeId: initialQuoteInfo.solutionTypeId,
      customerAttributes: initialQuoteInfo.customerAttributes,
      creditDocumentsOnFile: initialQuoteInfo.creditDocumentsOnFile,
      creditDocumentsFromQuoteId: initialQuoteInfo.creditDocumentsFromQuoteId,
      greatAmericaRentalAgreementOnFile: initialQuoteInfo.greatAmericaRentalAgreementOnFile,
      greatAmericaRentalAgreementFromQuoteId: initialQuoteInfo.greatAmericaRentalAgreementFromQuoteId,
      // KM-10242: Read customer name from initial quote info
      // Instead of doing heavy request for all customers and lookup by ID
      customerName: initialQuoteInfo.customerName,
      isCustomerNameChanged: true,
      existingDaasCustomerFromQuoteId: initialQuoteInfo.existingDaasCustomerFromQuoteId,
      existingDaasCustomer: initialQuoteInfo.existingDaasCustomer,
      daasWorkspaceType: initialQuoteInfo.daasWorkspaceType,
      contactCenterConcurrency: initialQuoteInfo.contactCenterConcurrencyFromQuoteId !== null,
      existingSangomaCXCustomer: initialQuoteInfo.existingSangomaCXCustomer,
      existingSangomaCXCustomerFromQuoteId: initialQuoteInfo.existingSangomaCXCustomerFromQuoteId,
      sangomaCXUserType: initialQuoteInfo.sangomaCXUserType,
      sangomaCXUserTypeAtCustomerLevel: initialQuoteInfo.sangomaCXUserType,
      sangomaCXDataExportFromQuoteId: initialQuoteInfo.sangomaCXDataExportFromQuoteId,
      speechAnalyticsActive: initialQuoteInfo.existingSpeechAnalyticsCustomer,
      existingSpeechAnalyticsCustomer: initialQuoteInfo.existingSpeechAnalyticsCustomer,
      existingSpeechAnalyticsCustomerFromQuoteId: initialQuoteInfo.existingSpeechAnalyticsCustomerFromQuoteId,
    };

    if (initialQuoteInfo.partnerLogoPath) {
      newProps.partnerLogoPath = initialQuoteInfo.partnerLogoPath;
      newProps.cobrandingEnabled = true;
    }

    if (typeof initialQuoteInfo.existingAlsAgreement === 'boolean') {
      newProps.existingAlsAgreement = initialQuoteInfo.existingAlsAgreement;
    }

    if (initialQuoteInfo.partnerLogoPath !== undefined) {
      newProps.partnerLogoPath = initialQuoteInfo.partnerLogoPath;
    }

    // Set default values for ADD_ON
    if (orderType === ORDER_TYPES.ADD_ON) {
      newProps.serviceInfo = initialQuoteInfo.serviceInfo;
      newProps.billingInfo = initialQuoteInfo.billingInfo;
      newProps.locationPackageType = initialQuoteInfo.locationPackageType;
      newProps.packageIdSelected = initialQuoteInfo.locationPackageType;
      newProps.numLocations = 1;
      newProps.numLines = 0;
      newProps.useNumLinesDefault = false;
      newProps.billingInfo.billTo = null;
      newProps.availableLineProductGpCode = initialQuoteInfo.availableLineProductGpCode;
      newProps.existingStarFaxGpCode = initialQuoteInfo.existingStarFaxGpCode;
      newProps.remoteWorkLocation = initialQuoteInfo.remoteWorkLocation;
      newProps.financingOption = initialQuoteInfo.financingOption;
      newProps.daasScheduleType = initialQuoteInfo.daasScheduleType;
      newProps.smartOfficeFromQuoteId = initialQuoteInfo.smartOfficeFromQuoteId;
      newProps.sangomaCXConcurrency = !!initialQuoteInfo.sangomaCXConcurrencyFromQuoteId;
      newProps.addOnFinancingTermMonths = initialQuoteInfo.addOnFinancingTermMonths;

      const isPackageApplicable =
        newProps.packageIdSelected && !PriceBookContext.model.flags['hidePackage' + newProps.packageIdSelected];

      if (!isPackageApplicable) {
        newProps.packageIdSelected = PriceBookContext.model.getDefaultPackageId();
      }

      if (!this.masterOrder.isRental) {
        newProps.useESigDocumentsFlow = false;
      }

      if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
        newProps.sipStationEnabledFromQuoteId = initialQuoteInfo.sipStationEnabledFromQuoteId;

        // if 'switchvoxOnPremEnabledFromQuoteId' is null, other fields will also be null, so no need to override default values
        if (initialQuoteInfo.switchvoxOnPremEnabledFromQuoteId !== null) {
          newProps.switchvoxOnPremEnabledFromQuoteId = initialQuoteInfo.switchvoxOnPremEnabledFromQuoteId;
          newProps.switchvoxOnPremMaintTypeEffective = initialQuoteInfo.switchvoxOnPremMaintTypeEffective;
          newProps.switchvoxOnPremMaintTermYearsEffective = initialQuoteInfo.switchvoxOnPremMaintTermYearsEffective;
          newProps.switchvoxOnPremMaintType = initialQuoteInfo.switchvoxOnPremMaintTypeEffective;
          newProps.switchvoxOnPremMaintTermYears = initialQuoteInfo.switchvoxOnPremMaintTermYearsEffective;
          newProps.numSwitchvoxOnPremUsers = 0;
        }

        if (this.masterOrder.sipStationExistsInOriginalLocation) {
          newProps.contractTermMonths = CONTRACT_TERM_MONTH_OPTIONS.THREE_YEARS.value;
        }
      }

      if (initialQuoteInfo.remoteWorkLocation) {
        this.appState.forceLocked = true;
        this.appState.accessForbidden = true;

        ErrorContext.model.setProps({
          isShown: true,
          message: 'msg_remote_work_location_addon_error_banner',
          autoHideDelay: 0,
          showCloseButton: true,
          type: ERROR_CONTEXT_TYPES.ERROR,
        });
      }
    }

    if (orderType === ORDER_TYPES.REWRITE) {
      newProps.serviceInfo = initialQuoteInfo.serviceInfo;
      newProps.billingInfo = initialQuoteInfo.billingInfo;
      newProps.sangomaCXConcurrency = !!initialQuoteInfo.sangomaCXConcurrencyFromQuoteId;
    }

    this.masterOrder.setProps(newProps);

    // Blueface hosted location is not valid solution type for Add-Ons
    if (newProps.solutionTypeId === SOLUTION_TYPES.BLUEFACE) {
      // Force lock quote from editing
      this.appState.forceLocked = true;

      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_addon_solution_type_blueface_error_message',
        details: 'msg_addon_solution_type_blueface_error_details',
        autoHideDelay: 0,
        showCloseButton: false,
      });
    }

    // KM-8577: Display some warning in case call to CRM failed
    if (initialQuoteInfo.salesforceServiceAvailable !== null && !initialQuoteInfo.salesforceServiceAvailable) {
      ErrorContext.model.setProps({
        isShown: true,
        details: 'msg_unable_to_detect_als',
        autoHideDelay: 10000,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.WARNING,
      });
    }

    // Ext/New
    if (orderType === ORDER_TYPES.NEW_LOCATIONS) {
      this.masterOrder.serviceInfo.locationName = 'New Location(s)';

      if (PartnerContext.model.isWholeSale) {
        this.masterOrder.contractTermMonths = CONTRACT_TERM_MONTH_OPTIONS.THREE_YEARS.value;
      }
    }
    // Addon
    else if (orderType === ORDER_TYPES.ADD_ON) {
      const locationsData = await IntegrationApi.getLocationsByCustomerId({ customerId });
      const location = locationsData.find(d => d.id === locationIds[0]);

      this.masterOrder.serviceInfo.locationName = location.name;
    }
    // Rewrite
    else if (orderType === ORDER_TYPES.REWRITE) {
      try {
        await this.addMultipleLocations(this.__locationIds, customerId);
      } catch (e) {
        throw new Error(e);
      }
    }

    if (PartnerContext.model.isWhiteLabelWholesale && !this.__originalQuoteId) {
      this.billingInfoAdjustmentsForWlw();
    }
  }

  getQuoteFromTrackingAPI(orderType, params) {
    const orderTypePathMapping = {
      [ORDER_TYPES.NEW_LOCATIONS]: 'new-location',
      [ORDER_TYPES.ADD_ON]: 'addon',
      [ORDER_TYPES.REWRITE]: 'rewrite',
    };
    const orderTypePath = orderTypePathMapping[orderType];

    const queryString = Object.keys(params)
      .map(key => (params[key] ? key + '=' + encodeURIComponent(params[key].toString().toLowerCase()) : ''))
      .join('&');
    const url = $http.instance.apiDomainURI + '/quotes/cr/' + orderTypePath + '?' + queryString;

    return $http.instance.api.get(url).then(r => {
      return r.data.content;
    });
  }

  validateQuote(quote = this.masterOrder.toJS()) {
    const customerContactFirstError = CC_FIELDS_FOR_SUBMISSION.map(propName =>
      Validate.customerContact(quote, propName)
    ).find(d => d.code !== '');

    // Validate for all order types
    this.appState.errors.setProps({
      lineItems: quote.dealerDemoKit ? '' : Validate.lineItems(quote).code,
      numLocations: Validate.numLocations(quote).code,
      billingDealerId: Validate.dealerId(quote).code,
      dealerContactUserId: Validate.dealerContactUserId(quote).code,
      quoteTitle: Validate.title(quote).code,
      serviceInfo: (customerContactFirstError || {}).code || '',
      inactiveLineItems: Validate.inactiveLineItems(quote.lineItems).code,
      isLocationUnique: Validate.isAllLocationsUnique(this.quotes.locationsNames, quote),
      allocationsForFinalizedMo: Validate.allocationsForFinalizedMo(quote.quoteStatus, this.isQuoteOverAllocated(quote))
        .code,
      customerInstallStarPlus: Validate.customerInstallStarPlus(quote).code,
      starCloudWithCustomerInstallation: Validate.starCloudWithCustomerInstallation(quote).code,
      customerNameLength: Validate.customerNameLength(quote).code,
      onePerGroup: Validate.onePerGroup(quote).code,
      leaseCustomPaymentAmount: Validate.leaseCustomPaymentAmount(quote).code,
      customerInfoErrors: Validate.customerInfoErrors(quote).code,
      addendumErrors: Validate.addendumErrors(quote, this.masterOrder.skipSubscriptionAgreementDate).code,
      invalidateItemsNotAllowedToAddOnConditionError:
        Validate.invalidateItemsNotAllowedToAddOnConditionFlagsValidation(quote).code,
      switchvoxOrSipStationMustBePresent: Validate.switchvoxOrSipStationMustBePresent(
        quote,
        PriceBookContext.model.isSupportsSwitchvoxSIPStation
      ).code,
      contactCenterConcurrencyItemMustBePresent: Validate.contactCenterConcurrencyItemMustBePresent(quote).code,
      sangomaCXConcurrencyItemsValidation: Validate.sangomaCXConcurrencyItemsValidation(quote).code,
    });

    // KM-8364: In case of dealerDemoKit === true
    // Some calculation dependant validation rules (KM-8299, KM-8300):
    //  * should be force passed (Dealer Demo Kit = ON)
    //  * or force revalidated against currently available calc results (Dealer Demo Kit = OFF)
    if (this.__quoteRequestCache.dealerDemoKit !== quote.dealerDemoKit) {
      this.appState.errors.setProps({
        salesExceptionsRules: quote.dealerDemoKit ? [] : Validate.checkSalesExceptionsRules(quote.calcResults.results),
        numStarBoxesError: quote.dealerDemoKit
          ? ''
          : Validate.numStarBoxes(quote.calcResults.results, quote.locations).code,
      });
    }

    // Validate for non SalesOps users
    if (AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) {
      this.appState.errors.setProps({
        saveButtonSeApproval: Validate.saveButtonSeApproval(quote).code,
      });
    }
    // KM-4622
    // Validate for Add-on order type
    if (ORDER_TYPES.ADD_ON === this.__orderType) {
      let errorProps = {
        NRC_MRC_LineItems: Validate.NRC_MRC_LineItems(quote).code,
        solutionType: Validate.solutionTypeAddons(quote).code,
        legacyLineItemsAddons: Validate.legacyLineItemsAddons(quote).code,
      };

      if (!PartnerContext.model.isWhiteLabelWholesale) {
        errorProps.blankIctpPartner = Validate.blankIctpPartner(this.masterOrder).code;
        errorProps.ictpConfirmationRequired = Validate.ictpConfirmationRequired(this.masterOrder).code;
      }

      this.appState.errors.setProps(errorProps);

      if (this.appState.errors.addOnFinalizeAllow === '' || !this.appState.errors.addOnFinalizeAllow) {
        const locationId = quote.star2starLocationId || this.__locationIds[0];

        Validate.addOnFinalize(locationId).then(valid => {
          this.appState.errors.addOnFinalizeAllow = valid;
          this.renderView();
        });
      }
    }
    // Validation for any order type except Add-on
    else {
      this.appState.errors.setProps({
        solutionType: Validate.solutionType(quote).code,
        legacyLineItemsLocations: Validate.legacyLineItemsLocations(quote).code,
        allowedToAllocateOnConditionAtLocationError:
          Validate.allowedToAllocateOnConditionAtLocationFlagsValidation(quote).code,
      });
    }

    if (
      // Always validate on page load, details - KM-4585
      this.__initialValidation ||
      // Validate only when we editing customer name, to prevent multiple api calls
      (this.masterOrder.isCustomerNameChanged && this.appState.isEdited)
    ) {
      if (
        ![ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION, ORDER_TYPES.NEW_LOCATIONS].includes(this.__orderType) &&
        !this.appState.locked &&
        this.appState.errors.customerNameLength === ''
      ) {
        Validate.requestCustomerNameValidation(quote)
          .then(({ code }) => {
            this.appState.errors.customerNameIsUnique = code || '';
          })
          .catch(() => {
            this.appState.errors.customerNameIsUnique = '';
          })
          .finally(() => {
            this.appState.isCustomerNameValidationRequested = false;
            this.renderView();
          });
      }

      this.masterOrder.isCustomerNameChanged = false;
    } else if (this.appState.isCustomerNameValidationRequested) {
      this.appState.isCustomerNameValidationRequested = false;
    }
  }

  handleCalculation(r) {
    this.appState.isCalcRequested = false;

    if (r === false) {
      this.appState.isCalculated = false;

      this.renderView();

      return false;
    } else if (r === null) {
      this.appState.isCalculated = true;

      this.renderView();

      return false;
    }

    if (this._retrigerCalc) {
      this._retrigerCalc = false;
      this._onQuoteChange(false);

      return null;
    }

    this.appState.isCalculated = true;

    const results = r.data.content;
    const locationResults = results.locationResults;
    const locationQuotes = this.quotes.locationQuotes;

    delete results.locationResults;

    for (let i = 0; i < locationResults.length; i++) {
      const result = locationResults[i];
      const locationQuote = locationQuotes.find(d => d.locationUuid === result.locationUuid);

      if (!locationQuote) {
        if (config.showConsoleLog) {
          console.error('Unable to set calculation results for location quote ' + result.locationUuid);
        }

        continue;
      }

      locationQuote.calcResults.results = result;
    }

    delete results.locationResults;

    this.masterOrder.calcResults.results = results;

    // Validate calc dependant properties
    this.appState.errors.setProps({
      spiffAmount: Validate.spiffAmount(
        this.masterOrder.calcResults.results.spiffWarningPooled,
        this.masterOrder.packageIdSelected
      ).code,
      spiffAvailableAmountRc: Validate.spiffDiscountAvailableRC(this.masterOrder.spiffDiscount.rcAvailable).code,
      spiffAvailableAmountNrc: Validate.spiffDiscountAvailableNRC(this.masterOrder.spiffDiscount.nrcAvailable).code,
      salesExceptionsRules: this.masterOrder.dealerDemoKit
        ? []
        : Validate.checkSalesExceptionsRules(this.masterOrder.calcResults.results),
      unlimitedCallRoutingCountMixedWarning: Validate.countMixedWarning(
        this.masterOrder.calcResults.results,
        this.quotes.locationQuotes
      ).code,
      unlimitedCallRoutingLimitWarning: Validate.limitWarning(
        this.masterOrder.calcResults.results,
        this.quotes.locationQuotes
      ).code,
      numStarBoxesError: this.masterOrder.dealerDemoKit
        ? ''
        : Validate.numStarBoxes(this.masterOrder.calcResults.results, this.quotes.locationQuotes).code,
      location4gRequiresSdwan: Validate.location4gRequiresSdwan(this.quotes.locationQuotes).code,
      madPoolOverAllocated: Validate.madPoolOverAllocated(
        this.masterOrder.calcResults.getValue('madPoolOverAllocated' + this.masterOrder.packageIdSelected, 'boolean')
      ).code,
      spiffOverAllocated: Validate.spiffOverAllocated(
        this.masterOrder.calcResults.getValue('spiffOverAllocated' + this.masterOrder.packageIdSelected, 'boolean')
      ).code,
      madUpfrontOverAllocated: Validate.madUpfrontOverAllocated(
        this.masterOrder.calcResults.getValue('madUpfrontOverAllocated' + this.masterOrder.packageIdSelected, 'boolean')
      ).code,
      madRecurOverAllocated: Validate.madRecurOverAllocated(
        this.masterOrder.calcResults.getValue('madRecurOverAllocated' + this.masterOrder.packageIdSelected, 'boolean')
      ).code,
      negativeAmount: Validate.negativeAmount({
        calcResult: this.masterOrder.calcResults.results,
        packageIdSelected: this.masterOrder.packageIdSelected,
        isFinanced: this.masterOrder.isFinanced,
        grandTotalNrc: this.masterOrder.lineItemsCatalog.grandTotalNonRecurring,
        grandTotalRc: this.masterOrder.lineItemsCatalog.grandTotalRecurring,
        grandTotalIctpps: this.masterOrder.ictpProviders.grandTotal,
        orderType: this.masterOrder.orderType,
        isWhiteLabelWholesale: PartnerContext.model.isWhiteLabelWholesale,
      }).code,
      starFaxDigitalMixedAtLocationWarning: Validate.starFaxDigitalMixedAtLocation(
        this.masterOrder.calcResults.results,
        this.quotes.locationQuotes
      ).code,
      starPhoneOnlyExtensionExceedUsersWarning: Validate.starPhoneOnlyExtensionExceedUsersWarning(
        this.masterOrder.calcResults,
        this.quotes.locationQuotes
      ).code,
      pciComplianceWarning: Validate.pciComplianceWarning(this.quotes.locationQuotes).code,
      bcUserMo: Validate.bcUserMo(this.masterOrder.calcResults.results).code,
      bcUserLocation: Validate.bcUserLocation(this.quotes.locationQuotes).code,
      smartOfficeGatewayKitValidation: Validate.smartOfficeGatewayKitValidation(
        this.masterOrder.calcResults,
        this.quotes.locationQuotes
      ).code,
      smartOfficeAccessDoorLicenseValidation: Validate.smartOfficeAccessDoorLicenseValidation(
        this.masterOrder.calcResults,
        this.quotes.locationQuotes
      ).code,
      smartOfficeLicenceOverallocationWarning: Validate.smartOfficeLicenceOverallocationWarning(
        this.masterOrder.calcResults
      ).code,
      switchvoxApplianceValidation: Validate.switchvoxApplianceValidation(this.masterOrder.calcResults).code,
      standaloneCPaaSValidationError: Validate.standaloneCPaaSValidationError(this.masterOrder.calcResults).code,
      numSangomaCXUsersExceedsNumUCaaSUsersError: Validate.numSangomaCXUsersExceedsNumUCaaSUsersError(
        this.masterOrder.calcResults
      ).code,
      sangomaCXUsersRequiredError: Validate.sangomaCXUsersRequiredError(this.masterOrder.calcResults).code,
    });

    // Create latest Master Order + Locations request body cache with valid numbers after calculation
    const quote = this.masterOrder.toJS();
    const calcRequestBody = RequestBodyNormalize(CALC_URL_MAPPING[quote.orderType], quote);
    const calcRequestCache = this._calcRequestCacheCleanUp(calcRequestBody);

    // KM-7814: Repeat calculation requests to obtain valid data
    if (
      Object.keys(this.__calcRequestCache).length &&
      !isVariablesEqual(this.__calcRequestCache, calcRequestCache) &&
      // KM-8478: Repeat calculation request only in case if quote is in edited state
      // That will prevent redundant calculation POST request on quote retrieve
      this.appState.isEdited
    ) {
      this.validateAndResetSeApproval(this.__calcRequestCache, calcRequestCache);
      this.renewSaveAndCalcSchemaCache(quote);

      return this.requestCalculation([quote], true).then(this.handleCalculation);
    }

    this.renewSaveAndCalcSchemaCache(quote);

    this.useDataForDiscount();
    this.checkMad();

    if (this.masterOrder.spiffDiscount.isSupportsSeparateSpiffOverride) {
      this.checkSpiff();
    }

    this.checkRollUp(quote);
    this.validateQuote(quote);

    this.renderView();
  }

  async requestCalculation(quotes = this.quotes.toJS(), forceReCalc = false) {
    let quote = quotes[0];

    const endpoint = CALC_URL_MAPPING[quote.orderType];
    const url = config.api.url + endpoint;

    quote.inputVersion = config.version;

    const calcRequestBody = RequestBodyNormalize(endpoint, quote, url);
    const calcRequestCache = this._calcRequestCacheCleanUp(RequestBodyNormalize(endpoint, quote));

    const dataUnchanged = isVariablesEqual(this.__calcRequestCache, calcRequestCache);

    if (dataUnchanged && !forceReCalc) {
      if (config.showConsoleLog) {
        console.log(
          '%cCalculation request prevented. Request Body JSON not changed.',
          'color: #2874A6; font-style: italic;'
        );
      }

      this.checkRollUp(quote);

      return null;
    }

    if (config.showConsoleLog) {
      console.groupCollapsed('Calculation Request Body: previous / current');
      console.log('Previous', this.__calcRequestCache);
      console.log('Current', calcRequestCache);
      console.groupEnd();
    }

    this.appState.isCalcRequested = true;
    this.rollUpDisabled = true;

    this.renderView();

    return $http.instance.api
      .post(url, calcRequestBody)
      .then(r => {
        return r.status === 200 ? r : false;
      })
      .catch(e => {
        this.appState.isCalcRequested = false;
        this.checkRollUp(quote);
        this.renderView();
        throw e;
      });
  }

  async requestCalculationRetrieve(quoteId) {
    const url = config.api.url + '/results/' + quoteId;

    this.appState.isCalcRequested = true;
    this.appState.isCalculated = false;

    return $http.instance.api.get(url).catch(e => {
      this.appState.isCalcRequested = false;

      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_unable_to_load_calculation_results',
        details: 'msg_reload_the_page_to_prevent_broken_quote_data',
      });

      this.renderView();

      throw e;
    });
  }

  handleMasterOrderSave(r) {
    if (!r.data || r.data.status !== 200 || r instanceof Error) {
      ErrorContext.model.setProps({
        autoHideDelay: 0,
        isShown: true,
        message: 'msg_quotes_save_failed',
        showCloseButton: true,
      });

      return false;
    }

    const {
      id,
      locations,
      packageIdSelected,
      trackingCheckpointsSent,
      trackingMarkersSent,
      trackingProductsSent,
      trackingLocationSent,
      quoteAddendums,
    } = r.data.content;
    const { masterOrder } = this;
    const idChanged = masterOrder.id !== id;
    const idBeganExist = masterOrder.id === null && id !== null;

    if (quoteAddendums && quoteAddendums.length > 0) {
      r.data.content.quoteAddendums = quoteAddendums.sort(
        (a, b) => ADDENDUMS_ORDER[a.documentType] - ADDENDUMS_ORDER[b.documentType]
      );
    }

    // Update models with a data returned from BE
    masterOrder.setProps(r.data.content);
    masterOrder.packageIdReturnedByApi = packageIdSelected;

    if (config.eSignatureEnabled && idBeganExist) {
      this.requestAvailableDocuments(id);
    }

    if (config.eSignatureEnabled && PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      this.fetchSipStationDocumentsAvailable(id);
    }

    if (config.showConsoleLog) {
      console.groupCollapsed('Master Order and Location Quotes are Saved');
      console.trace();
      console.groupEnd();
    }

    if (this._editedBeforeSaveComplete && config.showConsoleLog) {
      const msg = 'Quote were edited in the middle of save process.'.concat(
        idChanged ? ' Some data were lost after redirect.' : ''
      );

      console.log('%c' + msg, 'color: red; font-weight: bold;');
    }

    // KM-6596: Display some warning in case rq-be had issues communicating with tracking
    // Check both Master Order and all locations
    if (
      [trackingCheckpointsSent, trackingProductsSent, trackingMarkersSent, trackingLocationSent].includes(false) ||
      locations.some(d =>
        [d.trackingCheckpointsSent, d.trackingProductsSent, d.trackingMarkersSent, d.trackingLocationSent].includes(
          false
        )
      )
    ) {
      ErrorContext.model.setProps({
        autoHideDelay: 0,
        details: 'msg_warning_for_be_to_tracking_communication_fault',
        isShown: true,
      });

      this.appState.isEdited = true;
    }

    if (idChanged) {
      AppRouter.replaceWithoutCallback('/quotes/' + id);
    }

    this.getExpirationData();

    // Update quotes cache data after save because there might be some new data applied to models after save (Ex. IDs)
    this.renewSaveSchemaCache();
    this.appState.isSaveRequested = false;
    this.appState.isEdited = this._editedBeforeSaveComplete;
    this._editedBeforeSaveComplete = false;

    this.renderView();
  }

  handleLocationsSave(r) {
    if (!r.data || r.data.status !== 200 || r instanceof Error) {
      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_locations_save_failed',
        autoHideDelay: 0,
        showCloseButton: true,
      });

      return false;
    }

    const data = r.data.content;
    const locationQuotes = this.quotes.locationQuotes;

    for (let i = 0; i < data.length; i++) {
      const result = data[i];
      const locationQuote = locationQuotes.find(d => d.locationUuid === result.locationUuid);

      if (!locationQuote) {
        if (config.showConsoleLog) {
          console.error('Unable to update location quote ' + result.locationUuid + ' with BE provided data on save');
        }

        continue;
      }

      locationQuote.setProps(result);
    }

    this.appState.isEdited = false;

    // KM-6596: Display some warning in case rq-be had issues communicating with tracking
    // Check all locations
    if (
      data.some(d =>
        [d.trackingCheckpointsSent, d.trackingProductsSent, d.trackingMarkersSent, d.trackingLocationSent].includes(
          false
        )
      )
    ) {
      ErrorContext.model.setProps({
        isShown: true,
        details: 'msg_warning_for_be_to_tracking_communication_fault',
        autoHideDelay: 0,
      });

      this.appState.isEdited = true;
    }

    this.appState.isSaveRequested = false;
    this.renderView();
  }

  async asyncRequestMasterOrderSave(quotes = this.quotes.toJSON()) {
    let requestBodyRaw = quotes[0];

    let endpoint = '';
    let url = config.api.url;

    if (
      [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SENT_FOR_SIGNATURE, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(
        this.masterOrder.quoteStatus
      )
    ) {
      endpoint = '/quotes/{id}/locations';
    } else {
      endpoint = SAVE_OR_UPDATE_URL_MAPPING[requestBodyRaw.orderType];

      if (requestBodyRaw.id) {
        endpoint += '/{id}';
      }
    }

    url = (url + endpoint).replace('{id}', requestBodyRaw.id);

    // TODO: Verify. Is there a getter for sequence for LocationQuoteModel?
    for (let i = 0; i < requestBodyRaw.locations.length; i++) {
      requestBodyRaw.locations[i].sequence = i;
    }

    if (requestBodyRaw.orderType === ORDER_TYPES.ADD_ON && requestBodyRaw.isRevisionOfQuoteId) {
      let locationName = requestBodyRaw.locationName;
      const patternOld = /^Add-On - (.*)? - OrigQuoteId:[0-9]+$/i;
      // KM-11793: locationName format for Add-Ons was changed by request of tracking system
      const patternNew = /^(.*)? - Add-On - [0-9]{4}\/[0-9]{2}\/[0-9]{2} - OrigQuoteId:[0-9]+$/i;

      if (patternOld.test(locationName)) {
        locationName = locationName.match(patternOld)[1];
      }

      if (patternNew.test(locationName)) {
        locationName = locationName.match(patternNew)[1];
      }

      requestBodyRaw.locationName = locationName;
    }

    requestBodyRaw.inputVersion = config.version;
    requestBodyRaw.invalidateSalesException = this.invalidateSalesException;

    if (this.invalidateSalesException) {
      this.invalidateSalesException = false;
    }

    const requestBody = RequestBodyNormalize(endpoint, requestBodyRaw, url);

    try {
      const responseBody = await $http.instance.api.post(url, requestBody);

      if (responseBody?.data?.content?.quoteOperationState === QUOTE_OPERATION_STATES.UPDATING) {
        throw new Error(QUOTE_OPERATION_STATE_ERROR);
      }

      return responseBody;
    } catch (e) {
      if ((e?.request?.status === 0 || e.message === QUOTE_OPERATION_STATE_ERROR) && requestBodyRaw.id) {
        try {
          const state = await TrackingApi.quoteOperationState(requestBodyRaw.id);

          if (state === QUOTE_OPERATION_STATES.UPDATING) {
            throw new Error(QUOTE_OPERATION_STATE_ERROR);
          }
        } catch (e) {
          // Display default error banner in case of any error with quoteOperationState request
          console.log(e);
          throw e;
        }
      }

      throw e;
    } finally {
      this.appState.isSaveRequested = false;
      this.renderView();
    }
  }

  async asyncRequestQuoteRetrieve(quoteId, selectedLocationId = null, triggeredByCloneAction = false) {
    // TODO: NaN quoteId situation is no longer possible due to new router with strict URL matching
    //       Verify and remove this validation.
    if (isNaN(parseInt(quoteId, 10))) {
      const { billingDealerId: id, businessContinuity: bc } = this.masterOrder;
      AppRouter.replaceWithoutCallback('/partners/' + id + '/new-customer?bc=' + Number(bc));

      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_quote_retrieve_invalid_id_message',
        details: 'msg_quote_retrieve_invalid_id_details',
        autoHideDelay: 10000,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.ERROR,
      });

      throw new Error('Invalid quote ID format.');
    }

    this.appState.quoteLoading = true;
    this.renderView();

    const url = config.api.url + '/quotes/' + encodeURIComponent(quoteId);

    try {
      const quoteData = (await $http.instance.api.get(url)).data.content;

      if (quoteData.quoteOperationState === QUOTE_OPERATION_STATES.UPDATING) {
        throw new Error(QUOTE_OPERATION_STATE_ERROR);
      }

      if (!triggeredByCloneAction) {
        await this.asyncGetPartnerFlags({ dealerId: quoteData.dealerId });
      }

      const pricebookId = quoteData.pricebookId;
      const pricebookVersionId = quoteData.pricebookVersionId;
      const orderType = quoteData.orderType;
      let serviceType = '';

      if (quoteData.sangomaCXStandalone) {
        serviceType = QUOTE_SERVICE_TYPE.sangomaCXStandalone;
      }

      if (quoteData.standaloneServiceNonUCaaS) {
        serviceType = QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
      }

      await this.asyncLoadPricebookCatalog(
        orderType,
        serviceType,
        pricebookId,
        pricebookVersionId,
        true,
        quoteData.dealerId
      );

      this.resetQuoteModel();

      AuthContext.model.customerOrder = this.customerOrder;

      if (quoteData.quoteAddendums && quoteData.quoteAddendums.length > 0) {
        quoteData.quoteAddendums = quoteData.quoteAddendums.sort(
          (a, b) => ADDENDUMS_ORDER[a.documentType] - ADDENDUMS_ORDER[b.documentType]
        );
      }

      this.__orderType = quoteData.orderType;
      this.masterOrder.setProps(quoteData);
      this.masterOrder.ictpProviders.init();
      this.masterOrder.isCustomerNameChanged = false;
      this.masterOrder.packageIdReturnedByApi = quoteData.packageIdSelected;

      if (config.eSignatureEnabled) {
        await this.requestAvailableDocuments();
      }

      await this.getExpirationData(quoteData.id);

      // KM-6596: Display some warning in case rq-be had issues communicating with tracking
      // Check both Master Order and all locations
      if (
        [
          quoteData.trackingCheckpointsSent,
          quoteData.trackingProductsSent,
          quoteData.trackingMarkersSent,
          quoteData.trackingLocationSent,
        ].includes(false) ||
        quoteData.locations.some(d =>
          [d.trackingCheckpointsSent, d.trackingProductsSent, d.trackingMarkersSent, d.trackingLocationSent].includes(
            false
          )
        )
      ) {
        ErrorContext.model.setProps({
          isShown: true,
          details: 'msg_warning_for_be_to_tracking_communication_fault',
          autoHideDelay: 0,
        });

        this.appState.isEdited = true;
      }

      // Quote clone is triggered
      if (this.__originalQuoteId) {
        this.masterOrder.id = null;

        await this.initOrderTypeQuote(
          this.__orderType,
          this.__partnerId,
          this.__customerId,
          this.__locationIds,
          this.__serviceType
        );
        await this.asyncQuoteCloneOrCreateRevision(QUOTE_COPY_ACTION.CLONE, this.resetOverrides);
      }
      // Casual retrieve
      else {
        await this.asyncSetBillingDealer(this.masterOrder.billingDealerId);

        const masterOrder = this.masterOrder.toJS();

        this.__initialValidation = true;
        this.checkRollUp(masterOrder);
        this.validateQuote(masterOrder);
        this.__initialValidation = false;

        await Promise.all([
          this.requestCalculationRetrieve(this.masterOrder.id).then(this.handleCalculation),
          this.requestSalesExceptions(true),
          this.getSignatureHistory(),
        ]);

        if ([ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS].includes(masterOrder.orderType)) {
          await this.asyncValidateNumLocations();
        }

        if (PartnerContext.model.isWhiteLabelWholesale) {
          this.customerOrder.billingInfo.disableBillToId = true;
        }
      }

      if (selectedLocationId) {
        this.selectLocationQuoteById(selectedLocationId);
      }

      if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
        await this.fetchSipStationDocumentsAvailable(quoteData.id);
      }

      return quoteData;
    } catch (e) {
      if (e.message === QUOTE_OPERATION_STATE_ERROR) {
        this.appState.quoteOperationStateIsUpdating = true;

        ErrorContext.model.setProps({
          isShown: true,
          details: 'msg_quote_operation_state_updating_banner_message',
          autoHideDelay: 0,
          showCloseButton: true,
          type: ERROR_CONTEXT_TYPES.ERROR,
          priority: 9999,
        });

        this.renderView();

        return;
      }

      if (e?.response) {
        if (e.response.status === 403) {
          this.appState.accessForbidden = true;
          this.appState.forceLocked = true;
        }

        if (e.response.status === 404) {
          AppRouter.push('/not-found');
        }
      }

      throw e;
    } finally {
      this.appState.quoteLoading = false;
      this.renderView();
    }
  }

  requestSalesExceptions(refresh = false) {
    const url = config.api.url + '/quotes/' + this.masterOrder.id + '/sales-exceptions';

    if (refresh) {
      this.appState.isSalesExceptionsRequestInProgress = true;
      this.masterOrder.salesExceptions = [];
      this.renderView();
    }

    return $http.instance.api
      .get(url)
      .then(r => {
        if (r.status === 200) {
          this.masterOrder.salesExceptions = r.data.content.salesExceptions;
          this.masterOrder.statusBeforeSalesException = r.data.content.statusBeforeSalesException;

          if (!this.appState.isEdited) {
            this.masterOrder.quoteStatus = r.data.content.quoteStatus;
            this.renewSaveAndCalcSchemaCache();
          }
        }
      })
      .finally(() => {
        this.appState.isSalesExceptionsRequestInProgress = false;

        if (refresh) {
          this.renderView();
        }
      });
  }

  requestIctppsDetails(quote, isMasterOrder = true) {
    let endpoint = '';
    let calcRequestBody = {};
    apiData.ictppsDetails = [];

    if (isMasterOrder) {
      endpoint = `/quotes/ictpps/calculation`;
      calcRequestBody = RequestBodyNormalize(endpoint, quote);
    } else {
      endpoint = `/location/ictpps/calculation`;
      calcRequestBody = RequestBodyNormalize(endpoint, this.masterOrder);
      calcRequestBody.locationLineItems = quote.lineItems;
    }
    const url = config.api.url + endpoint;

    $http.instance.api.post(url, calcRequestBody).then(r => {
      apiData.ictppsDetails = r.data.content;
      this.renderView();
    });
  }

  async onQuoteFinalize() {
    this.appState.isFinalizeRequested = true;
    this.renderView();

    try {
      const url = config.api.url + '/quotes/' + this.masterOrder.id + '/finalize';
      const r = await $http.instance.api.post(url, {
        trackingLocationId: this.masterOrder.trackingLocationId,
      });

      const locationsData = r.data.content.locations;
      const locationQuotes = this.quotes.locationQuotes;

      for (let i = 0; i < locationsData.length; i++) {
        const result = locationsData[i];
        const locationQuote = locationQuotes.find(d => d.locationUuid === result.locationUuid);

        if (!locationQuote) {
          if (config.showConsoleLog) {
            console.error(
              'Unable to update location quote ' + result.locationUuid + ' tracking ID on Master Order finalization'
            );
          }

          continue;
        }

        locationQuote.trackingLocationId = result.trackingLocationId;
      }

      this.masterOrder.setProps(r.data.content);
    } catch (e) {
      // TODO: Update logic to match save process when quoteOperationState === UPDATING
      //       Once BE will support it for quote finalize
      console.log(e);
      throw e;
    } finally {
      this.appState.isFinalizeRequested = false;
      this.renderView();
    }
  }

  onQuoteUnFinalize() {
    this.appState.isUnFinalizeRequested = true;
    this.renderView();

    this.requestQuoteUnFinalize().then(r => {
      this.masterOrder.setProps(r.data.content);
      this.getExpirationData();
      this.appState.isUnFinalizeRequested = false;
      this.renderView();
    });
  }

  async requestQuoteUnFinalize() {
    const url = config.api.url + '/quotes/' + this.masterOrder.id + '/unfinalize';

    return $http.instance.api.post(url, {
      trackingLocationId: this.masterOrder.trackingLocationId,
    });
  }

  onSubmitForApproval(value) {
    this.appState.quoteLoading = true;
    this.renderView();
    this.requestSubmitForApproval(value).then(() => {
      this.masterOrder.quoteStatus = QUOTE_STATUS.SE_PENDING_APPROVAL;
      this.appState.quoteLoading = false;
      this.renderView();
    });
  }

  async requestSubmitForApproval(value) {
    const url = config.api.url + '/quotes/' + this.masterOrder.id + '/sales-exceptions';

    return $http.instance.api.post(url, {
      initialNote: value,
    });
  }

  onRetractApproval(value) {
    this.appState.quoteLoading = true;
    this.renderView();
    this.requestRetractApproval(value).then(r => {
      this.masterOrder.quoteStatus = r.data.content.quoteStatus;
      this.appState.quoteLoading = false;
      this.appState.errors.saveButtonSeApproval = '';
      this.renderView();
    });
  }

  async requestRetractApproval(value) {
    const url = config.api.url + '/quotes/' + this.masterOrder.id + '/sales-exceptions';

    return $http.instance.api.put(url, {
      withdrawnNote: value,
    });
  }

  setDefaultQuoteTitle() {
    const date = dayjs().format('MM/DD/YYYY');
    let title = null;

    if (this.masterOrder.quoteTitle === null) {
      switch (this.masterOrder.orderType) {
        case ORDER_TYPES.NEW_CUSTOMER:
          title = `New Master Order - ${date}`;
          break;
        case ORDER_TYPES.NEW_LOCATIONS:
          title = `Existing Master Order - ${date}`;
          break;
        case ORDER_TYPES.ADD_ON:
          title = `Add-On Order - ${date}`;
          break;
        case ORDER_TYPES.REDUCTION:
          title = `Reduction Order - ${date}`;
          break;
        case ORDER_TYPES.REWRITE:
          title = `Rewrite - ${date}`;
          break;
        default:
          break;
      }

      this.masterOrder.quoteTitle = title;
    }
  }

  toggleAutoCalculation() {
    AppStateContext.model.isAutoCalculateEnabled = !AppStateContext.model.isAutoCalculateEnabled;

    if (AppStateContext.model.isAutoCalculateEnabled && this.appState.isCalcAllowed) {
      this._onQuoteChange(true, true);
    } else {
      this.renderView();
    }
  }

  setMixed() {
    for (let i = 0; i < this.quotes.locationQuotes.length; i++) {
      for (let j = 0; j < this.quotes.locationQuotes[i].ictpProviders.providers.length; j++) {
        const loc = this.quotes.locationQuotes[i].ictpProviders.providers[j].selectedOption;

        if (
          this.masterOrder.ictpProviders.providers.find(
            el =>
              el.selectedOption.lineItemSubCategoryId === loc.lineItemSubCategoryId &&
              el.selectedOption.lineItemId !== loc.lineItemId
          )
        ) {
          this.masterOrder.ictpProviders.mixed = true;
          this.sumLocMixed();
          return;
        }
      }
    }
    this.masterOrder.ictpProviders.mixed = false;
  }

  sumLocMixed() {
    for (let el in this.masterOrder.ictpProviders.mixedDealerNet) {
      if (this.masterOrder.ictpProviders.mixedDealerNet.hasOwnProperty(el)) {
        this.masterOrder.ictpProviders.mixedDealerNet[el] = 0;
      }
    }

    for (let i = 0; i < this.quotes.locationQuotes.length; i++) {
      for (let j = 0; j < this.quotes.locationQuotes[i].ictpProviders.providers.length; j++) {
        const loc = this.quotes.locationQuotes[i].ictpProviders.providers[j].selectedOption;

        this.masterOrder.ictpProviders.providers.forEach(el => {
          if (el.selectedOption.lineItemSubCategoryId === loc.lineItemSubCategoryId) {
            this.masterOrder.ictpProviders.mixedDealerNet[loc.lineItemSubCategoryId] += loc.dealerNet;
          }
        });
      }
    }
  }

  _quoteRequestCacheCleanUp(quote) {
    delete quote.inputVersion;

    return quote;
  }

  _calcRequestCacheCleanUp(quote) {
    delete quote.inputVersion;

    return quote;
  }

  async asyncRequestPriceBookId(
    partnerId = this.masterOrder.dealerId,
    customerId = this.masterOrder.customerId,
    star2starLocationId,
    serviceType
  ) {
    this.appState.isPriceBookRequested = true;
    let url = config.api.url;

    // Force customerId to Zero for New/New quotes
    // On quote retrieve API returns customerId which is assigned in the model
    // But 0 has to be used for New/New anyway
    if (this.masterOrder.orderType === ORDER_TYPES.NEW_CUSTOMER) {
      customerId = 0;
    }

    if (partnerId) {
      // dealerId is used. "partner" is billingDealerId. So the parameter naming is a bit off.
      url += '/partners/' + encodeURIComponent(partnerId);
    }

    if (customerId >= 0) {
      url += '/customers/' + encodeURIComponent(customerId);
    }

    url += '/pricebooks?bc=' + this.masterOrder.businessContinuity.toString();

    if (serviceType) {
      url += '&serviceType=' + serviceType;
    }

    // add order type prop to request
    url += '&orderType=' + this.masterOrder.orderType;

    // add quote mode prop to request
    url += '&mode=' + this.masterOrder.quoteOperationMode;

    // add original pricebook id prop if mode is clone or revision
    if ([QUOTE_OPERATION_MODES.CLONE, QUOTE_OPERATION_MODES.REVISION].includes(this.masterOrder.quoteOperationMode)) {
      url += '&originalPricebookId=' + this.masterOrder.originalPricebookId;
    }

    if (this.masterOrder.orderType === ORDER_TYPES.ADD_ON) {
      url += '&s2sLocationId=' + encodeURIComponent(star2starLocationId);
    }

    try {
      const response = await $http.instance.api.get(url);

      return response.data;
    } catch (response) {
      this.appState.forceLocked = true;

      return response.data;
    }
  }

  async asyncLoadPricebookCatalog(
    orderType = null,
    serviceType = null,
    _pricebookId = null,
    _pricebookVersionId = null,
    setPackage = true,
    partnerId
  ) {
    this.appState.isCatalogRequested = true;
    this.renderView();

    try {
      const pricebookCatalog = await PriceBookVersionApi.asyncGetPricebookCatalog(
        orderType,
        _pricebookId,
        _pricebookVersionId,
        serviceType,
        partnerId
      );
      const { draftNo, flags, pricebookId, pricebookVersionId, version, globalExpirationDate } = pricebookCatalog;

      PriceBookContext.model.setProps({
        draftNo,
        pricebookId,
        pricebookVersionId,
        version,
        flags,
      });

      apiData.lineItems = pricebookCatalog.lineItems;

      this._autoAddLineItemsOnQuoteInit();

      this.masterOrder.pricebookId = pricebookId;
      this.masterOrder.pricebookVersionId = pricebookVersionId;
      this.masterOrder.globalExpirationDate = globalExpirationDate;

      if (setPackage) {
        this.masterOrder.packageIdSelected = PriceBookContext.model.getDefaultPackageId();
      }

      if (!PriceBookContext.model.isSupportsRental) {
        this.customerOrder.financingOption = FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR;
      }

      this.masterOrder.setProps(PriceBookContext.model.defaultCustomerOrderModelValues);

      this.masterOrder.ictpProviders.init();
      this.quotes.locationQuotes.forEach(l => l.ictpProviders.init());

      this.validateQuote();

      return apiData.lineItems;
    } catch (e) {
      return ApiErrorHandler(e);
    } finally {
      this.appState.isCatalogRequested = false;
      this.renderView();
    }
  }

  handlePricebookIdDialogCancel() {
    this.__props.pricebookIdDialog.isOpen = false;
    this.renderView();
  }

  checkMad() {
    const mad = this.masterOrder.spiffDiscount.isSupportsSeparateSpiffOverride
      ? this.masterOrder.spiffDiscount.madOverrideResetMetric
      : this.masterOrder.spiffDiscount.madTotalFundsStandard;
    const cachedPropName = this.masterOrder.spiffDiscount.isSupportsSeparateSpiffOverride
      ? '__madOverrideResetMetricCache'
      : '__madTotalFundsStandardCache';

    if (this[cachedPropName]) {
      if (mad !== this[cachedPropName]) {
        if (
          this.masterOrder.spiffDiscount.additionalRcDiscountPercent > 0 ||
          this.masterOrder.spiffDiscount.additionalUpfrontDiscountPercent > 0
        ) {
          this.masterOrder.spiffDiscount.showAdditionalDiscountRemovedBanner();
        }

        this.masterOrder.spiffDiscount.isConfirmed = false;
        this.masterOrder.spiffDiscount.additionalRcDiscountPercent = 0;
        this.masterOrder.spiffDiscount.additionalUpfrontDiscountPercent = 0;

        this._onQuoteChange(false, true);
      }
    }

    this[cachedPropName] = mad;
  }

  checkSpiff() {
    const spiff = this.masterOrder.spiffDiscount.spiffOverrideResetMetric;

    if (this.__spiffOverrideResetMetricCache) {
      if (spiff !== this.__spiffOverrideResetMetricCache) {
        if (
          this.masterOrder.spiffDiscount.additionalRcDiscountPercent > 0 ||
          this.masterOrder.spiffDiscount.additionalUpfrontDiscountPercent > 0
        ) {
          this.masterOrder.spiffDiscount.showAdditionalDiscountRemovedBanner();
        }

        this.masterOrder.spiffDiscount.isConfirmed = false;
        this.masterOrder.spiffDiscount.additionalRcDiscountPercent = 0;
        this.masterOrder.spiffDiscount.additionalUpfrontDiscountPercent = 0;

        this._onQuoteChange();
      }
    }

    this.__spiffOverrideResetMetricCache = spiff;
  }

  disableSpiffDiscountOverride() {
    this.masterOrder.spiffDiscount.isConfirmed = false;
    this.masterOrder.spiffDiscount.madPoolOverride = false;
    this.masterOrder.spiffDiscount.spiffOverrideEnabled = false;
    let message = '';

    if (this.__quoteRequestCache.madPoolOverride && this.__quoteRequestCache.spiffOverrideEnabled) {
      message = 'msg_mad_and_spiff_override_is_unchecked';
    } else if (this.__quoteRequestCache.madPoolOverride && !this.__quoteRequestCache.spiffOverrideEnabled) {
      message = 'msg_mad_override_is_unchecked';
    } else if (!this.__quoteRequestCache.madPoolOverride && this.__quoteRequestCache.spiffOverrideEnabled) {
      message = 'msg_spiff_override_is_unchecked';
    }

    // Alert user with a red banner if MAD or SPIFF override switched OFF automatically
    if (message !== '') {
      ErrorContext.model.setProps({
        isShown: true,
        message,
        details: 'msg_mad_override_disabled_alert',
        autoHideDelay: 0,
      });
    }

    this.useDataForDiscount();
  }

  quoteHasNonAutoAddedLineItems() {
    const lineItems = this.quotes.items[0].lineItems.items;

    return !lineItems.every(item =>
      item.catalogFlags.some(catalog => ['isFixedOnUi', 'autoAddOnNewQuote'].includes(catalog.flag.name))
    );
  }

  isQuoteContainUnderAllocatedItems() {
    for (let i = 0; i < this.masterOrder.lineItems.items.length; i++) {
      /** @type {LineItemModel} */
      let li = this.masterOrder.lineItems.items[i];

      if (
        !li.active ||
        li.hidden ||
        li.getFlagValue('skipRollUp') === 'TRUE' ||
        li.catalogFlags.some(d => ['isFixedOnUi'].includes(d.flag.name))
      ) {
        continue;
      }

      if (li.allocated < li.quantityWithOverride) {
        return true;
      }
    }

    if ([2, 4].includes(this.masterOrder.packageIdSelected)) {
      if (this.masterOrder.numLinesAllocated < this.masterOrder.numLines) {
        return true;
      }
    }

    return Boolean(
      (this.masterOrder.numLocations !== 1 && this.quotes.items.length - 1 < this.masterOrder.numLocations) ||
        this.masterOrder.ictpProviders.priceIsUnderAllocated()
    );
  }

  getQuoteHistoryMaster() {
    let url = config.api.url + '/quotes/history/master/' + this.masterOrder.masterOrderId;

    return $http.instance.api.get(url).then(r => {
      if (r.status === 200) {
        this.masterOrder.quoteHistory = r.data.content;
        this.renderView();
      }
    });
  }

  // TODO: This method doesn't seems to be used anywhere. Verify and remove if not used.
  getQuoteHistoryQuote() {
    let url = config.api.url + '/quotes/history/' + this.masterOrder.id;

    return $http.instance.api.get(url).then(r => {
      if (r.status === 200) {
        console.log(r, 'quotes');
      }
    });
  }

  requestAvailableDocuments(quoteId = this.masterOrder.id, data = false) {
    const url = config.api.url + '/' + quoteId + '/availableDocuments';

    this.masterOrder.availableDocuments = [];
    this.renderView();

    return $http.instance.api.get(url).then(r => {
      if (r.status === 200) {
        let bundles = null;

        const additionalCreditBundle = this.customerOrder.eSignature[ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT];
        const isAdditionalCredit = (data.type || data.bundleType) === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT;

        // bundleType exists only if we re-submitting bundle
        if (data.bundleType) {
          bundles = [r.data.content.bundles.find(bundle => bundle.type === data.bundleType)];
        } else if (isAdditionalCredit) {
          bundles = [r.data.content.bundles.find(bundle => bundle.type === data.type)];
        } else {
          // Force reorder availableDocuments by bundleOrder but don't blindly rely on API response order
          bundles = r.data.content.bundles.sort((a, b) => a.bundleOrder - b.bundleOrder);

          for (let i = 0; i < bundles.length; i++) {
            // Force reorder documents in each bundle by order but don't blindly rely on API response order
            bundles[i].documents.sort((a, b) => a.order - b.order);
          }
        }

        this.masterOrder.availableDocuments = bundles;
        this.customerOrder.amendButtonEnabled = r.data.content.amendButtonEnabled;
        this.customerOrder.allowToVoid = r.data.content.allowToVoid;

        if (!this.appState.isEdited) {
          this.masterOrder.quoteStatus = r.data.content.quoteStatus;
          this.masterOrder.statusBeforeSalesException = r.data.content.statusBeforeSalesException;
          this.getExpirationData();
          this.renewSaveAndCalcSchemaCache();
        }

        if (r.data.content.creditCustomerSigningDate && !additionalCreditBundle.creditCustomerSigningDate) {
          additionalCreditBundle.creditCustomerSigningDate = r.data.content.creditCustomerSigningDate;
        }

        if (data) {
          this.customerOrder.eSignature.init(data, isAdditionalCredit);
        }

        this.customerOrder.creditDocumentsFromQuoteId = r.data.content.creditDocumentsFromQuoteId;
        this.customerOrder.greatAmericaRentalAgreementFromQuoteId =
          r.data.content.greatAmericaRentalAgreementFromQuoteId;

        this.renderView();
      }
    });
  }

  changeSignatureDocuments(documentName, documentOrder, bundleIndex) {
    const bundles = this.masterOrder.availableDocuments;

    for (let i = 0; i < bundles.length; i++) {
      for (let j = 0; j < bundles[i].documents.length; j++) {
        let document = bundles[i].documents[j];
        if (document.order === documentOrder && document.documentType === documentName && bundleIndex === i) {
          document.selected = !document.selected;

          if (document.documentType === 'CUSTOM_DOCUMENT' && !document.selected) {
            this.customerOrder.eSignature.onChangeRequestProps(bundles[i].type, 'customDocument', null);
          }
        }
      }
    }

    this.masterOrder.availableDocuments = bundles;
    this.renderView();
  }

  submitSignature(closeModal, availableDocuments = this.masterOrder.availableDocuments) {
    this.appState.isSubmitSignatureRequested = true;
    this.appState.quoteLoading = true;

    this.renderView();
    const endpoint = '/{quoteId}/esignature';
    const url = (config.api.url + endpoint).replace('{quoteId}', this.masterOrder.id);

    let reqBody = [];
    /** @type {ElectronicSignatureModel} */
    const eSignature = this.masterOrder.eSignature.toJSON();

    for (let i = 0; i < availableDocuments.length; i++) {
      const d = availableDocuments[i];
      const envelope = eSignature[availableDocuments[i].type];
      const selectedDocuments = [];
      const envelopeType = d.type;
      const documents = d.documents;

      for (let j = 0; j < documents.length; j++) {
        const document = documents[j];

        if (!document.selected) {
          continue;
        }

        selectedDocuments.push(document.documentType);
      }

      delete envelope.ccEmailsCheckbox;

      if (envelopeType === ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER || envelopeType === ESIG_ENVELOPE_TYPES.AMENDMENT) {
        envelope.eSignaturePdfContext = eSignature.customizePdfOptionsMap;
      }

      if (envelopeType === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT) {
        envelope.eSignaturePdfContext = {
          creditCustomerSigningDate: envelope.creditCustomerSigningDate,
        };

        delete envelope.creditCustomerSigningDate;
      }

      if (envelope.ccEmail1) {
        envelope.ccEmails.push(envelope.ccEmail1);
        delete envelope.ccEmail1;
      }
      if (envelope.ccEmail2) {
        envelope.ccEmails.push(envelope.ccEmail2);
        delete envelope.ccEmail2;
      }

      if (this.masterOrder.isRental && envelopeType === ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER) {
        envelope.messageNonCustomizable = ESIG_RENTAL_NOTICE;
      }

      reqBody.push({
        ...envelope,
        bundleType: envelopeType,
        documentTypes: selectedDocuments,
        userTimeZone: getUserUTCTimezone(),
      });
    }

    // TODO: Resolve issue with "/quote/{quoteId}/esignature" schema parsing
    // The problem is there are GET and POST endpoints with same name
    // reqBody = RequestBodyNormalize(endpoint, reqBody);

    $http.instance.api
      .post(url, reqBody)
      .then(r => {
        this.getSignatureHistory();
        this.appState.quoteLoading = false;

        if (r.status === 200) {
          this.appState.eSigatureFlow = true;
          this.appState.isSubmitSignatureRequested = false;
          this.interfaces.quoteStatusPoll.poll();

          closeModal();
        }
      })
      .catch(error => {
        this.appState.quoteLoading = false;
        this.appState.isSubmitSignatureRequested = false;
        this.renderView();

        throw error;
      });
  }

  getSignatureHistory() {
    const url = config.api.url + '/' + this.masterOrder.id + '/esignature';

    return $http.instance.api
      .get(url)
      .then(r => {
        if (r.status === 200) {
          this.masterOrder.signatureHistory = r.data.content;

          this.renderView();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  cancelSignature(eSignatureRequestId) {
    if (typeof eSignatureRequestId !== 'number') {
      eSignatureRequestId = this.masterOrder.signatureHistory[0].id;
    }

    const url = config.api.url + '/' + this.masterOrder.id + '/esignature/' + eSignatureRequestId + '/cancel';

    this.appState.quoteLoading = true;
    this.appState.isSignatureCancelRequested = true;

    this.renderView();

    $http.instance.api
      .post(url)
      .then(r => {
        this.appState.quoteLoading = false;

        if (r.status === 200) {
          const data = r.data.content;

          if (data) {
            const index = this.masterOrder.signatureHistory.findIndex(d => d.id === data.id);

            if (index) {
              this.masterOrder.signatureHistory[index] = data;
            }
          }

          setTimeout(() => {
            this.getSignatureHistory();
            this.requestAvailableDocuments();
          }, 2000);
        }

        this.appState.isSignatureCancelRequested = false;
        this.interfaces.quoteStatusPoll.poll();
      })
      .catch(error => {
        this.appState.quoteLoading = false;
        this.appState.isSignatureCancelRequested = false;

        throw error;
      });
  }

  downloadDocumentsSignatureUrlAndValues(data) {
    const documentTypes = data.eSignatureDocuments.map(d => d.documentType);

    return this._previewDocumentsUrlAndValues(documentTypes, data.eSignaturePdfContext, data.bundleType);
  }

  previewPdfUrlAndValues(bundleIndex) {
    const selectedBundle = this.customerOrder.availableDocuments[bundleIndex];
    const availableDocuments = selectedBundle.documents;
    const selectedDocuments = [];

    for (let i = 0; i < availableDocuments.length; i++) {
      if (availableDocuments[i].selected) {
        selectedDocuments.push(availableDocuments[i].documentType);
      }
    }

    if (
      this.customerOrder.availableDocuments[bundleIndex].type === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT &&
      !this.masterOrder.eSignature.customizePdfOptionsMap.creditCustomerSigningDate
    ) {
      this.masterOrder.eSignature.customizePdfOptionsMap.creditCustomerSigningDate =
        this.masterOrder.eSignature[ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT].creditCustomerSigningDate;
    }

    return this._previewDocumentsUrlAndValues(
      selectedDocuments,
      this.masterOrder.eSignature.customizePdfOptionsMap.toJSON(),
      selectedBundle.type
    );
  }

  _previewDocumentsUrlAndValues(documentTypes, eSignaturePdfContext = null, bundleType = '') {
    const id = this.masterOrder.id;
    const values = {
      token: AuthContext.model.token,
      userTimezone: getUserUTCTimezone(),
    };

    if (bundleType) {
      values.bundleType = bundleType;
    }

    documentTypes.forEach((documentType, index) => {
      if (!Array.isArray(values.documentType)) {
        values.documentType = [];
      }

      values.documentType.push(documentType);
    });

    if (eSignaturePdfContext !== null && typeof eSignaturePdfContext === 'object') {
      if (eSignaturePdfContext.creditCustomerSigningDate) {
        values.creditCustomerSigningDate = eSignaturePdfContext.creditCustomerSigningDate;
      }

      for (let i = 0; i < CUSTOMIZE_PDF_OPTION_LIST.length; i++) {
        const key = CUSTOMIZE_PDF_OPTION_LIST[i];
        const value = Boolean(eSignaturePdfContext[key]);

        values[key] = value;
      }
    }

    return {
      url: config.api.url + '/' + id + '/previewDocuments',
      values,
    };
  }

  reminderSignature(eSignatureRequestId, eSignerId) {
    const url =
      config.api.url +
      '/' +
      this.masterOrder.id +
      '/esignature/' +
      eSignatureRequestId +
      '/esigner/' +
      eSignerId +
      '/reminder';

    $http.instance.api
      .post(url)
      .then(r => {
        if (r.status === 200) {
          setTimeout(() => this.getSignatureHistory(), 2000);
        }
      })
      .catch(error => {
        throw error;
      });
  }

  refreshSignatureDetails(signatureId) {
    if (signatureId) {
      const url = config.api.url + '/' + this.masterOrder.id + '/esignature/' + signatureId + '/synchronize';

      $http.instance.api
        .post(url)
        .then(r => {
          if (r.status === 200) {
            const findIndex = this.masterOrder.signatureHistory.findIndex(sigRequest => sigRequest.id === signatureId);

            this.masterOrder.signatureHistory[findIndex] = r.data.content;
            this.renderView();
          }
        })
        .catch(error => {
          throw error;
        });
    }
  }

  requestAmend(quoteId = this.masterOrder.id) {
    const url = config.api.url + '/quotes/' + quoteId + '/amend';
    this.appState.quoteLoading = true;
    this.renderView();

    $http.instance.api
      .post(url)
      .then(r => {
        if (r.status === 200) {
          this.appState.quoteLoading = false;
          this.masterOrder.setProps(r.data.content);

          this.renewCalcSchemaCache();
          this.renderView();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  cancelAmendment(quoteId = this.masterOrder.id) {
    const url = config.api.url + '/quotes/' + quoteId + '/cancelAmendment';
    this.appState.quoteLoading = true;
    this.renderView();

    $http.instance.api
      .post(url)
      .then(r => {
        if (r.status === 200) {
          this.appState.quoteLoading = false;
          this.masterOrder.setProps(r.data.content);

          if (!this.appState.isEdited) {
            this.renewCalcSchemaCache();
          }

          this.renderView();

          // KM-8103
          this.requestAvailableDocuments();

          // KM-11166
          this.fetchExtendedInfo();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  requestChangeCounterSignerEmail(eSignatureId, eSignerId, email, initialEmail, quoteId = this.masterOrder.id) {
    const url =
      config.api.url +
      '/' +
      quoteId +
      '/esignature/' +
      eSignatureId +
      '/esigner/' +
      eSignerId +
      '/update?email=' +
      encodeURIComponent(email);

    $http.instance.api
      .post(url)
      .then(r => {
        if (r.data.status !== 200) {
          this.customerOrder.eSignature.onChangeSignerEmail(eSignatureId, eSignerId, initialEmail);
        }
      })
      .catch(error => {
        this.customerOrder.eSignature.onChangeSignerEmail(eSignatureId, eSignerId, initialEmail);

        throw error;
      });
  }

  closeCopyQuoteModal() {
    this.copyAction = null;
    this.renderView();
  }

  rePushToTracking(isQuote) {
    let urlPath = '';

    if (isQuote) {
      urlPath = '/quotes/' + this.customerOrder.id + '/repush-to-tracking';
      this.appState.rePushMasterOrderInProgress = true;
    } else {
      urlPath = '/location/' + this.quotes.items[this.selectedQuote.index].id + '/repush-to-tracking';
      this.appState.rePushLocationInProgress = true;
    }

    const url = config.api.url + urlPath;

    this.renderView();

    $http.instance.api
      .post(url)
      .then(() => {
        ErrorContext.model.setProps({
          isShown: true,
          message: 'msg_completed',
          details: 'msg_re_push_all_markers_product_data',
          autoHideDelay: 10000,
          showCloseButton: true,
          type: ERROR_CONTEXT_TYPES.INFO,
        });
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        this.appState.rePushMasterOrderInProgress = false;
        this.appState.rePushLocationInProgress = false;

        this.renderView();
      });
  }

  syncToTracking() {
    this.appState.isSyncToTrackingRequested = true;
    this.renderView();

    const selectedLocation = this.quotes.items[this.selectedQuote.index];
    const url = config.api.url + '/location/' + selectedLocation.id + '/push-to-tracking';

    $http.instance.api.post(url).then(r => {
      if (r.status === 200) {
        this.masterOrder.setProps(r.data.content);

        this.appState.isSyncToTrackingRequested = false;
        this.renderView();
      }
    });
  }

  requestVoidEsr(eSignatureId, crmApprovalTicket, quoteId = this.masterOrder.id) {
    const url =
      config.api.url + '/' + quoteId + '/esignature/' + eSignatureId + '/void?crmApprovalTicket=' + crmApprovalTicket;

    $http.instance.api.post(url).then(res => {
      if (res.status === 200) {
        this.interfaces.quoteStatusPoll.poll();

        this.requestAvailableDocuments().then(() => {
          this.getSignatureHistory();
          this.fetchExtendedInfo();
        });
      }
    });
  }

  renewSaveSchemaCache(quoteData = this.masterOrder.toJSON()) {
    this.__quoteRequestCache = this._quoteRequestCacheCleanUp(
      RequestBodyNormalize(SAVE_OR_UPDATE_URL_MAPPING[quoteData.orderType], quoteData)
    );
  }

  renewCalcSchemaCache(quoteData = this.masterOrder.toJSON()) {
    this.__calcRequestCache = this._calcRequestCacheCleanUp(
      RequestBodyNormalize(CALC_URL_MAPPING[quoteData.orderType], quoteData)
    );
  }

  renewSaveAndCalcSchemaCache(quoteData = this.masterOrder.toJSON()) {
    this.renewSaveSchemaCache(quoteData);
    this.renewCalcSchemaCache(quoteData);
  }

  async asyncGetPartnerFlags({ billingDealerId, dealerId }) {
    if (!dealerId) {
      const partnersData = await IntegrationApi.getPartnersByUserId({ userId: AuthContext.model.userId });
      const partner = partnersData.find(d => Number(d.billingDealerId) === Number(billingDealerId));

      dealerId = partner.dealerId;
    }

    const url = config.api.url + '/partners/' + encodeURIComponent(dealerId);

    try {
      const res = await $http.instance.apiWithoutGlobalHandler.get(url);

      const { canSOBO, pricebookId, tier, type } = res.data.content;
      apiData.partnerCanSOBO = res.data.content.canSOBO;

      if (res.data.content.partnerLogoPath) {
        this.customerOrder.partnerLogoPath = res.data.content.partnerLogoPath;

        if (this.customerOrder.id === null) {
          this.customerOrder.cobrandingEnabled = true;
        }
      }

      PartnerContext.model.setProps({
        canSOBO,
        pricebookId,
        tier,
        type,
      });
    } catch (error) {
      const statusCode = error.response.status;

      if (statusCode === 404) {
        apiData.partnerCanSOBO = false;

        return error;
      } else {
        return ApiErrorHandler(error);
      }
    } finally {
      this.renderView();
    }
  }

  setBulkMarkup(catalogType, value) {
    const CATALOG_MAP = {
      bulkNonRecurring: 'nonRecurring',
      bulkRecurring: 'recurring',
    };
    const categories = this.masterOrder.lineItemsCatalog[CATALOG_MAP[catalogType]];
    const priceRatio = value / 100;

    for (let i = 0, length = categories.length; i < length; i++) {
      const { columns, lineItems } = categories[i];

      // Skip categories that does not have markup column visible
      if (columns.every(column => column.alias !== 'markupValue')) {
        continue;
      }

      for (let j = 0, subLength = lineItems.length; j < subLength; j++) {
        /** @type {LineItemModel} */
        const lineItem = lineItems[j];

        // Skip line items that has flag disableMarkup
        if (lineItem.getFlagValue('disableMarkup') === 'TRUE') {
          continue;
        }

        lineItem.markupValue = fixFloat(lineItem.price * priceRatio, 2);
      }
    }

    this._onQuoteChange(true);
  }

  requestCheckpoints() {
    this.appState.isCheckpointsRequested = true;
    this.renderView();

    // Clear previous checkpoints to avoid incorrect data
    this.customerOrder.checkpoints = [];

    const selectedQuote = this.quotes.items[this.selectedQuote.index];
    const id = selectedQuote.id;
    const isMasterOrder = this.selectedQuote.index === 0;
    const levelPath = isMasterOrder ? 'quotes' : 'location';
    const url = config.api.url + '/' + levelPath + '/' + id + '/checkpoints';

    return $http.instance.api
      .get(url)
      .then(res => {
        this.customerOrder.checkpoints = res.data.content;
        this.appState.isCheckpointsRequested = false;
      })
      .catch(error => {
        return error;
      })
      .finally(() => {
        this.renderView();
      });
  }

  async asyncValidateNumLocations(numLocations = this.masterOrder.numLocations) {
    const numLocationsExceeded = await TrackingApi.numLocationsExceeded(
      this.masterOrder.customerId,
      numLocations,
      this.masterOrder.numberOfLocationsWithTrackingId
    );

    this.customerOrder.numLocationsCountWarning = numLocationsExceeded;
  }

  sendReminderToSigner(signatureId, signerId, quoteId = this.masterOrder.id) {
    const url = config.api.url + '/' + quoteId + '/esignature/' + signatureId + '/esigner/' + signerId + '/reminder';

    return $http.instance.api
      .post(url)
      .then(r => {
        if (r.status === 200) {
          ErrorContext.model.setProps({
            isShown: true,
            details: 'msg_reminder_has_been_sent',
            autoHideDelay: 5000,
            showCloseButton: true,
            type: ERROR_CONTEXT_TYPES.SUCCESS,
          });
        }
      })
      .catch(error => {
        throw error;
      });
  }

  getSignatureDownloadEndpoint(signatureId, quoteId = this.masterOrder.id) {
    return `/${quoteId}/esignature/${signatureId}/file`;
  }

  // TODO Refactor? move loop logic to Provider Model prop
  onChangeDealerDemoKit(value) {
    if (value) {
      this.customerOrder.ictpProviders.providers.forEach(provider => {
        const options = provider.options;

        if (options.length > 1) {
          const lineItemId = options.find(option => option.isPartner).lineItemId;

          provider.selectOption(lineItemId);
          provider.selectedOption.dealerNet = 0;
        }
      });
    }

    this.customerOrder.dealerDemoKit = value;
    this._onQuoteChange(true);
  }

  handleLeaseCreditApplicationSubmit(data) {
    const url = config.api.url + '/quotes/' + encodeURIComponent(this.masterOrder.id) + '/leasenotification';

    return $http.instance.api.post(url, data);
  }

  validateBillingEmail(email) {
    const url = config.api.url + '/validations/email';

    return $http.instance.api.post(url, { email });
  }

  billingInfoAdjustmentsForWlw() {
    this.handleCopyContacts('COPY_FROM_PARTNER_MASTER_ORDER', this.customerOrder.billingInfo.uuid, 'billingInfo');
    this.customerOrder.billingInfo.syncCheckBoxValue = true;

    this.renderView();
  }

  validateAndResetSeApproval(prevCalcRequestBody, currentCalcRequestBody) {
    const prevBody = JSON.parse(JSON.stringify(prevCalcRequestBody));
    const currentBody = JSON.parse(JSON.stringify(currentCalcRequestBody));

    delete prevBody.locations;
    delete currentBody.locations;

    const calcDataBeenChanged = !isVariablesEqual(prevBody, currentBody);

    if (
      calcDataBeenChanged &&
      this.masterOrder.quoteStatusLabel === QUOTE_STATUS.SE_APPROVED &&
      !this.invalidateSalesException
    ) {
      this.invalidateSalesException = true;

      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_se_approved_quote_changed_notification',
        details: 'msg_reload_browser_to_revert',
        autoHideDelay: 10000,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.WARNING,
      });
    }
  }

  getExpirationData(quoteId = this.masterOrder.id) {
    const url = config.api.url + '/quotes/' + quoteId + '/expiration';

    return $http.instance.api
      .get(url)
      .then(r => {
        if (r.status === 200) {
          this.masterOrder.expired = r.data.content.expired;
          this.masterOrder.globalExpirationDate = r.data.content.globalExpirationDate;

          if (this.masterOrder.expired) {
            ErrorContext.model.setProps({
              isShown: true,
              message: 'msg_expired_warning',
              autoHideDelay: 0,
              showCloseButton: true,
              type: ERROR_CONTEXT_TYPES.WARNING,
            });
          }

          this.renderView();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  getAvailableLocationsToAdd(customerId = this.masterOrder.customerId) {
    return IntegrationApi.getLocationsByCustomerId({ customerId }).then(response => {
      if (Array.isArray(response)) {
        return response.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }

  async addMultipleLocations(locationIds, customerId, userInput = false) {
    const locationsInitialInfo = await IntegrationApi.getLocationsInitialInfo({
      customerId,
      locationIds,
    });

    let hasRemoteWorkLocation = false;

    for (let i = 0, length = locationsInitialInfo.length; i < length; i++) {
      const locationQuote = new LocationQuoteModel(this.quotes);
      const props = locationsInitialInfo[i];
      props.star2starLocationId = props.locationId;

      if (props.remoteWorkLocation) {
        hasRemoteWorkLocation = true;
      }

      delete props.locationId;

      locationQuote.setProps(props);

      this.quotes.items.push(locationQuote);

      locationQuote.syncLineItems();
      locationQuote.ictpProviders.init();
    }

    if (hasRemoteWorkLocation) {
      this.appState.forceDisableQuoteControls = true;

      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_remote_work_location_rewrite_error_banner',
        autoHideDelay: 0,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.ERROR,
      });
    }

    if (userInput) {
      this._onQuoteChange(userInput);
    }
  }

  fetchExtendedInfo(quoteId = this.masterOrder.id) {
    const url = config.api.url + '/quotes/' + quoteId + '/extendedInfo';

    return $http.instance.api
      .get(url)
      .then(r => {
        if (r.status === 200) {
          this.masterOrder.setProps(r.data.content);

          this.renderView();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  fetchSipStationDocumentsAvailable(quoteId = this.masterOrder.id) {
    const url = config.api.url + '/' + quoteId + '/isSwitchvoxOrSIPStationSignatureNeeded';

    return $http.instance.api
      .get(url)
      .then(r => {
        if (r.status === 200) {
          this.masterOrder.isSipStationDocumentsAvailable = r.data.content;

          this.renderView();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  async asyncRequestMarkers() {
    this.appState.isMarkersRequested = true;
    this.renderView();

    const [markersFields, markerOverrides] = await Promise.all([
      PriceBookVersionApi.getMarkers(PriceBookContext.model.pricebookVersionId),
      QuoteMarkerFieldOverridesApi.getMarkerOverrides(this.masterOrder.id),
    ]);

    this.appState.isMarkersRequested = false;
    this.initMarkers(markersFields, markerOverrides);
    this.renderView();
  }

  initMarkers(markersFields, markerOverrides) {
    this.customerOrder.markers = markersFields.reduce(
      (all, { editable, fieldName, markerSubType, markerType, overrideCalculationFieldName, value }) => {
        let marker = all.find(marker => marker.markerType === markerType);

        if (!marker) {
          marker = new MarkerModel(this.masterOrder);
          marker.setProps({
            markerType,
            markerSubType,
          });

          all.push(marker);
        }

        marker[fieldName] = value;

        if (overrideCalculationFieldName) {
          marker[fieldName] = this.masterOrder.calcResults.getValue(overrideCalculationFieldName);
        }

        if (editable) {
          marker.editableFields.push(fieldName);
        }

        return all;
      },
      []
    );

    markerOverrides.forEach(markerOverride => {
      /** @type {MarkerModel} */
      const marker = this.customerOrder.markers.find(marker => marker.markerType === markerOverride.markerType);
      const prop = markerOverride.fieldName + 'Field';

      marker.onChange(prop, markerOverride.value);
    });
  }

  saveMarkerOverrides() {
    QuoteMarkerFieldOverridesApi.createUpdateMarkerOverrides(
      this.masterOrder.id,
      this.masterOrder.buildMarkerOverridesRequest()
    );
  }

  async asyncFetchTaxEstimates(quoteId = this.masterOrder.id) {
    this.masterOrder.taxEstimates = (await QuoteTaxEstimatesApi.getTaxEstimates(quoteId)) || [];

    this.renderView();
  }

  async asyncGenerateTaxEstimates() {
    this.appState.taxEstimatesGenerating = true;
    this.renderView();

    this.masterOrder.taxEstimates = (await QuoteTaxEstimatesApi.generateTaxEstimates(this.masterOrder.id)) || [];
    this.appState.taxEstimatesGenerating = false;

    this.renderView();
  }

  resetTaxEstimates() {
    this.customerOrder.taxEstimates = [];

    this.renderView();
  }
}

export default QuoteController;
