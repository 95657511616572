import React from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import {
  ORDER_TYPES,
  QUOTE_STATUS,
  QUOTE_COPY_ACTION,
  ESIG_ENVELOPE_TYPES,
  PACKAGE_TYPE_IDS,
  PRICEBOOK_TAGS,
  FINANCING_OPTIONS,
  ERROR_CONTEXT_TYPES,
  WORKSPACE_TYPE_OPTIONS,
} from '../../../../common/enums';
import PackageDetailsComponent from '../../../PackageDetailsComponent';
import SubmitWizardComponent from '../../../SubmitWizardComponent';
import ApprovalComponent from '../../../ApprovalComponent';
import SlexComponent from '../../../SlexComponent';
import ICTPPSDetails from '../../../IctppsDetails';
import {
  checkRequiredFieldsForFinalizeLoc,
  checkLastLocationFinalize,
} from '../../../../common/check-required-for-finalize';
import * as formatter from '../../../../common/helpers/format';
import config from '../../../../config';
import ERROR_TEXT_COLLECTION from '../../../../common/ERROR_TEXT_COLLECTION';
import translator from '../../../../common/translate';
import Validate from '../../../../Models/Validate';
import apiData from '../../../../Storage/apiData';
import { AppStateContext, AuthContext, ErrorContext, PartnerContext, PriceBookContext } from '../../../../Context';
import bindMultiple from '../../../../common/helpers/bindMultiple';
import { ToggleControl, SelectControl, LabelField } from '../../../Controls';
import { SignatureHistory, SignatureDetails, SignatureRequest } from '../../../Signature';
import { LineItemsCatalog, AddLineItemDialog } from '../../../LineItems';
import {
  LocationsTab,
  LocationName,
  LocationSolutionType,
  PciCompliant,
  PackagedApplicationsToggle,
  LocationActionBar,
  LocationActionBtn,
  LocationNumLines,
} from '../../../Locations';
import HeaderInformation from '../../../HeaderInformation';
import Toolbar from '../../../Toolbar';
import Ictp from '../../../Ictp';
import SpiffDiscount from '../../../SpiffDiscount';
import LocationSpiffDiscount from '../../../SpiffDiscount/LocationSpiff';
import ContactSection from '../../../CustomerContactInfo/ContactSection';
import Checkpoints from '../../../Checkpoints';
import BadgeButton from '../../../BadgeButton';
import QuoteHistoryComponent from '../../../QuoteHistory/index';
import './quote.wizard.style.css';
import LeaseOption from '../../../LeaseOption';
import { CALC_RESULT_PACKAGED, RESULT_TYPES } from '../../../../Models/CalcResultsModel';
import LeaseOptionEmailForm from '../../../LeaseOptionEmailForm';
import { BILLING_EMAIL_VALIDATION_STATUSES } from '../../../../common/enums/billingEmailValidationStatuses';
import RentalFundingInvoice from '../../../RentalFundingInvoice';
import AddLocationsModal from '../../../Locations/AddLocationsModal';
import { CONTACT_PROPS } from '../../../CustomerContactInfo/ContactSectionProps';
import LocationDaasScheduleType from '../../../Locations/LocationDaasScheduleType';
import classNames from 'classnames';
import LocationSwitchvoxUsers from '../../../Locations/LocationSwitchvoxUsers';
import MarkersDialog from '../../../MarkersDialog';
import DuplicateLocationModal from '../../../DuplicateLocationModal';
import SmartOfficeLocationToggle from '../../../Locations/SmartOfficeLocationToggle';
import AddLineItems from '../../../AddLineItems';
import HiddenFormHelper from '../../../HiddenFormHelper';
import CloneOptionsComponent from '../../../CloneOptionsComponent';
import CollapsableCard from '../../../CollapsableCard';
import CustomerInformationWrapper from './nested/CustomerInformationWrapper';
import PartnerInformationWrapper from './nested/PartnerInformationWrapper';
import DaasSectionWrapper from './nested/DaasSectionWrapper';
import SangomaCxSettingsWrapper from './nested/SangomaCxSettingsWrapper';
import QuoteInformationWrapper from './nested/QuoteInformationWrapper';
import AdminSectionWrapper from './nested/AdminSectionWrapper';
import AddendumsSectionWrapper from './nested/AddendumsSectionWrapper';

const DUPLICATE_METHOD = {
  CLONE: 'CLONE',
  REVISION: 'REVISION',
};

const CLONE_ACTION_MESSAGES = {
  clone: [
    'msg_new_customer_name',
    'msg_spiff_acknowledgment',
    'msg_alternate_discount_selection_and_descriptions',
    'msg_customer_service_billing_and_shipping_information',
  ],
  revision: ['msg_spiff_acknowledgment', 'msg_alternate_discount_selection_and_descriptions'],
};

export default class QuoteWizardComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      packageDetailsOpened: false,
      slexOpened: false,
      packageDetailsId: props.customerOrder.packageIdSelected,
      finalizeDialogOpened: false,
      approvalDialogOpened: false,
      seApprovedSaveModal: false,
      copyEntireMasterOrder: false,
      locationPackageTypeModal: false,
      packageIdSelected: null,
      quoteHistoryOpen: false,
      packageTelecomWarning: false,
      ictppsDetailsOpened: false,
      signatureOpened: false,
      signatureHistoryOpened: false,
      signatureDetailsOpened: false,
      selectedSignatureId: null,
      retainOverridesModalOpen: false,
      duplicateType: '',
      checkpointsOpened: false,
      isLeaseCreditApplicationDialogVisible: false,
      billingEmailSuggestionModal: false,
      billingEmailSuggestion: '',
      billingEmailConfirmModal: false,
      cancelSignatureModal: false,
      cancelingSignatureRequestId: null,
      addLocationsModal: false,
      availableLocationsToAdd: [],
      markersModalOpened: false,
      duplicateLocationModalOpened: false,
      isCloneOptionsModalOpen: false,
      industryTypes: [],
    };

    bindMultiple(
      this,
      this.addLocation,
      this.addLocationAndCloseModal,
      this.addPureLocationAndCloseModal,
      this.closeRetainOverridesModal,
      this.collapseExpandAllSection,
      this.handleCloneClick,
      this.handleFinalize,
      this.handleLocationFinalize,
      this.handleLocationUnFinalize,
      this.handleOnBeforeUnload,
      this.handleRePush,
      this.handleRetractApproval,
      this.handleRevisionClick,
      this.handleSubmitForApproval,
      this.handleSyncToTracking,
      this.handleUnFinalize,
      this.isAllCollapsed,
      this.loadEsrHistory,
      this.onCheckpointClose,
      this.onCheckpointOpen,
      this.onClickAmendBtn,
      this.onClickCancelAmendment,
      this.onClickFinalizeBtn,
      this.onClickSEApprovedSave,
      this.onClickUnFinalizeBtn,
      this.onDetailsClick,
      this.onDetailsClose,
      this.onFinalizeDialogClose,
      this.onIctppsDetailsClick,
      this.onQuoteHistoryClick,
      this.onQuoteHistoryClose,
      this.onSignatureClose,
      this.onSignatureDetailsBack,
      this.onSignatureDetailsClose,
      this.onSignatureDetailsOpen,
      this.onSignatureHistoryClose,
      this.onSignatureHistoryOpen,
      this.onSignatureOpen,
      this.onSlexClose,
      this.onSlexOpen,
      this.onSubmitRetractApprovalDialogClose,
      this.openRetainOverridesModal,
      this.selectPackage,
      this.showAddonWarning,
      this.showHideSection,
      this.showHideGeneralSection,
      this.updateModelProp,
      this.handleLeaseCreditApplicationSubmit,
      this.handleBillingEmailValidation,
      this.handleBillingEmailSuggestionModalNoClick,
      this.handleBillingEmailSuggestionModalYesClick,
      this.handleBillingEmailConfirmModalContinueClick,
      this.handleBillingEmailConfirmModalCancelClick,
      this.openConfirmEmailModal,
      this.openCancelSignatureModal,
      this.closeCancelSignatureModal,
      this.handleCancelSignatureModalCancelClick,
      this.addSingleLocation,
      this.openAddLocationsModal,
      this.closeAddLocationsDialog,
      this.addMultipleLocations,
      this.onMarkersOpen,
      this.onMarkersClose,
      this.onMarkersSave,
      this.onDuplicateLocationOpen,
      this.onDuplicateLocationClose,
      this.onDuplicateLocationConfirm,
      this.openApprovalDialog,
      this.requestAvailableDocumentsWithData,
      this.toggleSaveSEApprovedModal,
      this.openCloneOptionsModal,
      this.closeCloneOptionsModal
    );

    this.sectionsInfo = props.controller.constantsModel.getMOSections;

    this.collapsedSections = {
      0: [
        this.props.controller.constantsModel.getMOSections.nrc.id,
        this.props.controller.constantsModel.getMOSections.rc.id,
        this.props.controller.constantsModel.getMOSections.ictp.id,
        this.props.controller.constantsModel.getMOSections.spiff.id,
        this.props.controller.constantsModel.getMOSections.contactInfo.id,
        this.props.controller.constantsModel.getMOSections.billingInfo.id,
        this.props.controller.constantsModel.getMOSections.shippingInfo.id,
        this.props.controller.constantsModel.getMOSections.leaseOption.id,
      ],
    };
    this.collapsedGeneralSections = [this.props.controller.constantsModel.getMOSections.addendumsSection.id];
  }

  componentDidMount() {
    window.onbeforeunload = this.handleOnBeforeUnload;
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleOnBeforeUnload() {
    if (this.props.appState.isEdited && !this.props.appState.isSaveRequested) {
      return '';
    } else {
      return null;
    }
  }

  showHideSection(key, callback, isToggle = true) {
    let activeQuote = this.collapsedSections[this.props.selectedQuote.index];
    let index = activeQuote.indexOf(key);
    if (index > -1) {
      activeQuote.splice(index, 1);
    } else {
      isToggle && activeQuote.push(key);
    }
    this.props.controller.renderView(this.props, callback);
  }

  showHideGeneralSection(key, callback, isToggle = true) {
    let index = this.collapsedGeneralSections.indexOf(key);
    if (index > -1) {
      this.collapsedGeneralSections.splice(index, 1);
    } else {
      isToggle && this.collapsedGeneralSections.push(key);
    }

    this.props.controller.renderView(this.props, callback);
  }

  collapseExpandAllSection() {
    let sections = null;
    const isCollapsed = this.isAllCollapsed();

    if (this.props.controller.selectedQuote.index === 0) {
      sections = this.props.controller.constantsModel.getMOSections;
    } else {
      sections = this.props.controller.constantsModel.getLocationSections;
    }

    this.collapsedSections[this.props.selectedQuote.index] = [];
    this.collapsedGeneralSections = [];

    if (!isCollapsed) {
      for (let key in sections) {
        if (sections.hasOwnProperty(key)) {
          if (sections[key].isGeneralSection) {
            this.collapsedGeneralSections.push(sections[key].id);
          } else {
            this.collapsedSections[this.props.selectedQuote.index].push(sections[key].id);
          }
        }
      }
    }

    this.props.controller.renderView();
  }

  isAllCollapsed() {
    let collapsedSections = [
      ...this.collapsedSections[this.props.selectedQuote.index],
      ...this.collapsedGeneralSections,
    ];
    let sections = null;

    if (this.props.controller.selectedQuote.index === 0) {
      sections = Object.values(this.props.controller.constantsModel.getMOSections);
    } else {
      sections = Object.values(this.props.controller.constantsModel.getLocationSections);
    }

    return sections.length === collapsedSections.length;
  }

  /**
   * Render event handler of component
   * @return {object} Rendered DOM tree
   */
  render() {
    const locationOptions = this.getOptions();
    const props = this.props;
    const controller = props.controller;
    const customerOrder = props.customerOrder;
    const lockedState = props.appState.locked;
    const disabledState = props.appState.disabled;
    const switchvoxWlwDisabled = props.appState.switchvoxWlwDisabled;
    const accessForbidden = props.appState.accessForbidden;
    const _isFinalizedMO = customerOrder.quoteStatus === QUOTE_STATUS.FINALIZED;
    const _isFinalizedAndSignedMO = [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(
      customerOrder.quoteStatus
    );
    const isAddLocationDisable =
      accessForbidden ||
      disabledState ||
      (([QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SENT_FOR_SIGNATURE, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(
        customerOrder.quoteStatus
      ) ||
        switchvoxWlwDisabled) &&
        props.locationQuotes.length >= customerOrder.numLocations) ||
      QUOTE_STATUS.CLOSED === customerOrder.quoteStatus ||
      (QUOTE_STATUS.AMENDING === customerOrder.quoteStatus && !AuthContext.model.hasInternalPermissions) ||
      (customerOrder.contractRewrite && !AuthContext.model.hasSalesOpsPermissions) ||
      (customerOrder.sangomaCXStandalone && props.locationQuotes.length >= customerOrder.numLocations);
    const selectedLocationIndex = props.selectedQuote.index;
    const selectedLocation = props.quotes[selectedLocationIndex];
    const lockedLoc = selectedLocation.status === QUOTE_STATUS.FINALIZED || props.appState.forceDisableQuoteControls;
    const hideLocAddLineItem =
      [QUOTE_STATUS.SENT_FOR_SIGNATURE, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(customerOrder.quoteStatus) ||
      _isFinalizedMO;
    const orderType = customerOrder.orderType;
    const quoteHasStarboxWithoutSbreliant = !AuthContext.model.hasInternalPermissions
      ? controller.masterOrder.quoteHasStarboxWithoutSbreliant()
      : false;
    const revisionDisabledForUnfinalized =
      QUOTE_STATUS.UNFINALIZED === customerOrder.quoteStatus &&
      [ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.NEW_CUSTOMER].includes(orderType) &&
      customerOrder.isAnyLocationWithTrackingId &&
      !AuthContext.model.hasSalesOpsPermissions;
    const cloneAction = controller.copyAction === QUOTE_COPY_ACTION.CLONE;
    const { flags } = PriceBookContext.model;
    const { isWholeSale, isWhiteLabelWholesale } = PartnerContext.model;
    const showSangomaPhoneIncentive = PriceBookContext.model.flags.pricebookTag.includes(
      PRICEBOOK_TAGS.SUPPORTS_SANGOMA_PHONE_INCENTIVE
    );
    const sangomaPhoneIncentiveTotal = customerOrder.spiffDiscount.sangomaPhoneIncentiveTotal;
    const sangomaPhoneIncentiveAvailable = customerOrder.spiffDiscount.sangomaPhoneIncentiveAvailable;

    let createRevisionButtonDisabled =
      !controller.appState.isSaved ||
      [
        QUOTE_STATUS.FINALIZED,
        QUOTE_STATUS.SIGNED_AND_FINALIZED,
        QUOTE_STATUS.SENT_FOR_SIGNATURE,
        QUOTE_STATUS.AMENDING,
        QUOTE_STATUS.CLOSED,
      ].includes(customerOrder.quoteStatus) ||
      quoteHasStarboxWithoutSbreliant ||
      (ORDER_TYPES.ADD_ON === orderType && customerOrder.quoteStatus === QUOTE_STATUS.UNFINALIZED) ||
      revisionDisabledForUnfinalized ||
      (customerOrder.contractRewrite && !AuthContext.model.hasSalesOpsPermissions) ||
      switchvoxWlwDisabled ||
      (ORDER_TYPES.REWRITE === orderType && customerOrder.isAnyLocationWithTrackingId);
    let revisionButtonTooltip = revisionDisabledForUnfinalized ? '@msg_revision_only_for_sales_ops' : '';

    if (quoteHasStarboxWithoutSbreliant) {
      revisionButtonTooltip += '@msg_starbox_without_sbreliant';
    }

    let cloneButtonDisabled =
      !controller.appState.isSaved ||
      [QUOTE_STATUS.CLOSED].includes(customerOrder.quoteStatus) ||
      quoteHasStarboxWithoutSbreliant ||
      (customerOrder.businessContinuity && !AuthContext.model.hasSalesOpsPermissions) ||
      (customerOrder.contractRewrite && !AuthContext.model.hasSalesOpsPermissions) ||
      this.props.appState.pricebookIdValidationInProgress ||
      AppStateContext.model.industryTypesLoading ||
      switchvoxWlwDisabled;
    const showDuplicateButtons =
      // Makes buttons initially invisible until quote is fully initialized
      customerOrder.orderType &&
      // Hide buttons for Add-Ons (non SalesOps)
      !props.appState.addOnsDisabled;

    // KM-8300: Force disable clone and revision buttons for Partners
    if (props.appState.isDemoKitQuoteViewedByPartner) {
      createRevisionButtonDisabled = true;
      cloneButtonDisabled = true;
    }

    let locationErrors = checkRequiredFieldsForFinalizeLoc(
      selectedLocation,
      customerOrder,
      props.isQuoteOverAllocated,
      props.appState.isSaveAllowed
    );

    if (!selectedLocation.locationInfo.isOrderTab && locationErrors.fields.length === 0) {
      locationErrors = checkLastLocationFinalize(
        selectedLocation,
        customerOrder,
        props.quotes,
        props.isQuoteOverAllocated
      );
    }

    const finalizeLocDisable =
      locationErrors.fields.length > 0 ||
      props.appState.isCalcRequested ||
      !lockedState ||
      lockedLoc ||
      ![QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(customerOrder.quoteStatus);
    const showLocations =
      [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(orderType) || !orderType;
    const submitForApproval = [QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(
      customerOrder.quoteStatus
    );
    const ictpTitle = !customerOrder.ictpProviders.isProServicesExists() ? 'extendedTitle' : 'title';

    const {
      amendmentButton,
      approvalButton,
      cancelAmendmentButton,
      finalizeButton,
      finalizedButton,
      preliminaryButton,
      printFinalButton,
      saveButton,
      sendForSignatureButton,
      signaturesButton,
      slexButton,
    } = props.toolbarButtons;

    const { packageOne, packageTwo, packageThree, packageFour } = props.HUDPackages;

    const {
      disabled: printQuickBtnDisabled,
      errors: printQuickBtnErrors,
      requiredFields: requiredQuickPrintFields,
    } = preliminaryButton;

    const toolbarProps = {
      // toolbar buttons
      amendmentButton,
      approvalButton,
      cancelAmendmentButton,
      finalizeButton,
      finalizedButton,
      preliminaryButton,
      printFinalButton,
      saveButton,
      sendForSignatureButton,
      signaturesButton,
      slexButton,

      onAmendBtnClick: this.onClickAmendBtn,
      onApprovalBtnClick: this.openApprovalDialog,
      onCancelAmendmentBtnClick: this.onClickCancelAmendment,
      onFinalizeBtnClick: _isFinalizedAndSignedMO ? this.onClickUnFinalizeBtn : this.onClickFinalizeBtn,
      onFinalizedBtnClick: () => {},
      preliminaryBtnEndpoint: controller.prelimPdfBtnEndpoint(),
      printFinalPdfEndpoint: ['/quotes/final', customerOrder.id, 'pdf', customerOrder.packageIdSelected].join('/'),
      onSaveBtnClick: controller.invalidateSalesException
        ? this.toggleSaveSEApprovedModal
        : controller.asyncOnClickSaveBtn,
      onSendForSignatureBtnClick: this.onSignatureOpen,
      onSignaturesBtnClick: this.onSignatureHistoryOpen,
      onSlexToolbarBtnClick: this.onSlexOpen,

      // HUD package types
      packageOne,
      packageTwo,
      packageThree,
      packageFour,

      onDetailsClick: this.onDetailsClick,
      onSelectPackage: this.selectPackage,
      disableDetailsClick: !props.appState.appInited,

      isSelectedPackageOverride: customerOrder.isSelectedPackageOverride && !AuthContext.model.hasSalesOpsPermissions,
    };

    if (Object.keys(this.collapsedSections).length !== controller.quotes.items.length) {
      for (let key in controller.quotes.items) {
        if (controller.quotes.items.hasOwnProperty(key) && !this.collapsedSections.hasOwnProperty(key)) {
          this.collapsedSections[key] = [];
        }
      }
    }

    const collapsableCardDefaultProps = {
      toggleCollapse: this.showHideSection,
      collapsedSections: [...this.collapsedSections[selectedLocationIndex], ...this.collapsedGeneralSections],
    };

    let locationContent;

    const finalizeLocLabel =
      customerOrder.quoteStatus === QUOTE_STATUS.SIGNED_AND_FINALIZED ? 'msg_upload_and_finalize' : 'msg_finalize_order';

    const ictpProvidersDisabled =
      disabledState ||
      customerOrder.dealerDemoKit ||
      (flags.pricebookTag.includes(PRICEBOOK_TAGS.BUSINESS_CONTINUITY) && !AuthContext.model.hasSalesOpsPermissions);

    if (selectedLocation.locationInfo.locationQuoteId && !selectedLocation.locationInfo.isOrderTab) {
      this.sectionsInfo = controller.constantsModel.getLocationSections;
      const locationQuoteUrl = selectedLocation.trackingLocationId
        ? config.trackingUrl + selectedLocation.trackingLocationId
        : '';
      const displaySyncToTracking =
        AuthContext.model.hasInternalPermissions &&
        !selectedLocation.trackingLocationId &&
        selectedLocation.id &&
        customerOrder.quoteStatus !== QUOTE_STATUS.CLOSED &&
        customerOrder.orderType !== ORDER_TYPES.REWRITE &&
        !customerOrder.standaloneServiceNonUCaaS;
      const trackingLocationLink = locationQuoteUrl ? (
        <span className="location-quote-status-link">
          <a
            href={locationQuoteUrl}
            id={'location-quote-url'}
            className="location-quote-status-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span id={'cpq-quote-number'}>Tracking #{selectedLocation.trackingLocationId}</span>
          </a>
        </span>
      ) : null;

      const locationDisabledState = disabledState || selectedLocation.forceDisabled;
      const locationNameTitle = (
        <div className="location-title location-name-title">
          <span>{translator.getMessage('msg_location_data')}</span>
          <div className="location-header">
            {trackingLocationLink}
            <div className="btn-collection">
              {displaySyncToTracking && (
                <BadgeButton
                  id="sync-to-tracking-btn"
                  key="sync-to-tracking-btn"
                  label={translator.getMessage('msg_sync_to_tracking')}
                  className="sync-to-tacking-btn-wrapper"
                  btnClassName="sync-to-tacking-btn"
                  onClick={e => this.handleSyncToTracking(e)}
                  hideInfoBadge={true}
                  disabled={
                    locationDisabledState || props.appState.isSyncToTrackingRequested || props.appState.isCalcRequested
                  }
                />
              )}
              {AuthContext.model.hasSalesOpsPermissions && customerOrder.quoteStatus !== QUOTE_STATUS.CLOSED && (
                <BadgeButton
                  id="re-push-location-btn"
                  key="re-push-location-btn"
                  label={translator.getMessage('msg_re_push')}
                  btnClassName="re-push-btn btn-primary"
                  onClick={e => this.handleRePush(e, false)}
                  errors={props.appState.rePushLocationInProgress ? 'msg_re_push_in_progress_button_tooltip' : ''}
                  infoTooltip="msg_confirm_impact_on_billing_and_commissins"
                  hideInfoBadge={true}
                  disabled={
                    locationDisabledState ||
                    !selectedLocation.trackingLocationId ||
                    props.appState.isCalcRequested ||
                    props.appState.rePushLocationInProgress
                  }
                />
              )}
              {customerOrder.quoteStatus !== QUOTE_STATUS.CLOSED &&
                (!lockedLoc ? (
                  <BadgeButton
                    id="finalize-button"
                    key="finalize-button-key"
                    label={translator.getMessage(finalizeLocLabel)}
                    btnClassName="location-finalize-btn primary"
                    className="badge-btn-component-location"
                    disabled={finalizeLocDisable || locationDisabledState}
                    onClick={this.handleLocationFinalize}
                    errors={locationErrors.message}
                    errorsCnt={locationErrors.fields.length}
                  />
                ) : AuthContext.model.hasInternalPermissions ? (
                  <BadgeButton
                    id="un-finalize-button"
                    key="un-finalize-button-key"
                    label={translator.getMessage(selectedLocation.shipped ? 'msg_finalized' : 'msg_un_finalize')}
                    btnClassName="location-un-finalize-btn critical"
                    className="badge-btn-component-location"
                    onClick={this.handleLocationUnFinalize}
                    errors=""
                    infoTooltip={selectedLocation.shipped ? 'msg_shipped' : ''}
                    hideInfoBadge={true}
                    disabled={locationDisabledState || selectedLocation.shipped}
                  />
                ) : (
                  <BadgeButton
                    id="finalize-button"
                    key="finalize-button-key"
                    label={translator.getMessage('msg_finalized')}
                    btnClassName={[
                      'critical',
                      selectedLocation.shipped ? 'location-un-finalize-btn' : 'location-finalize-btn',
                    ].join(' ')}
                    className="badge-btn-component-location"
                    disabled={finalizeLocDisable || locationDisabledState}
                    onClick={this.handleLocationFinalize}
                    errors={selectedLocation.quoteStatus === QUOTE_STATUS.FINALIZED ? '' : locationErrors.message}
                    errorsCnt={
                      selectedLocation.quoteStatus === QUOTE_STATUS.FINALIZED ? 0 : locationErrors.fields.length
                    }
                    infoTooltip={selectedLocation.shipped ? 'msg_shipped' : ''}
                    hideInfoBadge={true}
                  />
                ))}
            </div>
            <LocationActionBar>
              {customerOrder.orderType !== ORDER_TYPES.REWRITE && (
                <LocationActionBtn
                  id="duplicate-location-button"
                  className="copy no-border no-bg secondary square"
                  actionLabel={translator.getMessage('msg_clone')}
                  title="msg_copy_location"
                  action={this.onDuplicateLocationOpen}
                  disabled={
                    locationDisabledState ||
                    controller.copyBtnDisabled() ||
                    (!AuthContext.model.hasInternalPermissions && customerOrder.quoteStatus === QUOTE_STATUS.AMENDING)
                  }
                >
                  {/* Copy icon */}
                  <span className="icon-copy" />
                </LocationActionBtn>
              )}
              <LocationActionBtn
                id="remove-location-button"
                className="delete no-border no-bg secondary square"
                confirmText={`Are you sure you want to delete ${selectedLocation.serviceInfo.locationName}?`}
                actionLabel={translator.getMessage('msg_delete')}
                title={'msg_delete_location'}
                action={() => controller.requestLocationDelete()}
                disabled={lockedLoc || locationDisabledState || !!selectedLocation.trackingLocationId}
                critical
              >
                {/* Trash icon */}
                <span className="icon-delete" />
              </LocationActionBtn>
            </LocationActionBar>
          </div>
        </div>
      );
      const showNumLines =
        !isWholeSale &&
        [PACKAGE_TYPE_IDS.PACKAGE_TWO, PACKAGE_TYPE_IDS.PACKAGE_FOUR].includes(selectedLocation.packageIdSelected);

      const LocationLineItemsCatalog = props.locationQuotes[selectedLocationIndex - 1].lineItemsCatalog;
      const locationContentClassName = classNames({
        'location-content-wrapper': true,
        'switchvox-enabled': PriceBookContext.model.isSupportsSwitchvoxSIPStation,
      });

      // START: Location Content Section
      locationContent = (
        <div>
          {/* LOCATION DATA SECTION START */}

          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.locName.id}
            id={this.sectionsInfo.locName.id}
            className="quote-content-style"
            contentWrapperClassName="full-width"
            title={locationNameTitle}
          >
            <div className={locationContentClassName}>
              <LocationName
                id="location-name-field"
                lockedState={lockedLoc}
                disabledState={locationDisabledState || customerOrder.orderType === ORDER_TYPES.REWRITE}
                value={selectedLocation.serviceInfo.locationName}
                onChange={controller.changeLocationName}
              />
              {showNumLines && (
                <LocationNumLines
                  lockedState={lockedLoc}
                  disabledState={locationDisabledState}
                  selectedLocation={selectedLocation}
                  total={customerOrder.numLines}
                  allocated={customerOrder.numLinesAllocated}
                />
              )}
              {PriceBookContext.model.isSupportsSwitchvoxSIPStation && customerOrder.switchvoxOnPremEnabled && (
                <LocationSwitchvoxUsers
                  lockedState={lockedLoc}
                  disabledState={locationDisabledState}
                  selectedLocation={selectedLocation}
                  total={customerOrder.numSwitchvoxOnPremUsers}
                  allocated={customerOrder.numSwitchvoxOnPremUsersAllocated}
                />
              )}
              {(config.userInputsSolutionTypeEnabled || AuthContext.model.hasSalesOpsPermissions) &&
                !customerOrder.sangomaCXStandalone && (
                  <LocationSolutionType
                    id={'location-solution-type-field-' + selectedLocation.locationInfo.locationQuoteId}
                    key={'location-solution-type-field-' + selectedLocation.locationInfo.locationQuoteId}
                    lockedState={lockedLoc}
                    disabledState={
                      locationDisabledState ||
                      PriceBookContext.model.isSupportsSwitchvoxSIPStation ||
                      customerOrder.standaloneServiceNonUCaaS
                    }
                    options={selectedLocation.solutionTypeOptions}
                    value={selectedLocation.solutionTypeId}
                    onChange={value => selectedLocation.onChange('solutionTypeId', Number(value))}
                  />
                )}
              {PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE) && (
                <SmartOfficeLocationToggle
                  id="smartOfficeLocationToggle"
                  parentClassName="smart-office-location-toggle-wrapper"
                  // KM-13320: Force disable SmartOffice Location toggle for non Sales Ops users on location level
                  disabledState={!AuthContext.model.hasSalesOpsPermissions || locationDisabledState}
                  lockedState={lockedLoc}
                  value={selectedLocation.smartOffice}
                  onChange={selectedLocation.onChange}
                />
              )}
              {!PriceBookContext.model.isSupportsSwitchvoxSIPStation && (
                <PciCompliant
                  id="pciCompliantToggle"
                  parentClassName="pci-compliant-toggle"
                  disabledState={locationDisabledState}
                  lockedState={lockedLoc}
                  value={selectedLocation.pciCompliant}
                  onChange={selectedLocation.onChange}
                />
              )}
              {[ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(orderType) &&
                !PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
                !PriceBookContext.model.flags.pricebookTag.includes(
                  PRICEBOOK_TAGS.CPAAS_LOCATION_ENFORCEMENT_DISABLED
                ) && (
                  <PackagedApplicationsToggle
                    id="PackagedApplicationsToggle"
                    parentClassName="packaged-application-toggle"
                    disabledState={
                      locationDisabledState ||
                      !customerOrder.packagedApplicationsExists ||
                      selectedLocation.packagedApplicationsAllocationBlockedByFinalizedLocation
                    }
                    lockedState={lockedLoc}
                    value={selectedLocation.allocateAllCustomerLevelPackagedApplications}
                    onChange={selectedLocation.onChange}
                    finalizedLocationName={selectedLocation.finalizedLocationNameWithPackagedApplications}
                  />
                )}
              {PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_NEW_DAAS) &&
                customerOrder.daasWorkspaceType !== WORKSPACE_TYPE_OPTIONS.NONE &&
                !selectedLocation.hideDaasScheduleType && (
                  <LocationDaasScheduleType
                    disabledState={locationDisabledState || selectedLocation.daasScheduleTypeDisabled}
                    lockedState={lockedLoc}
                    onChange={selectedLocation.onChange}
                    value={selectedLocation.daasScheduleType}
                  />
                )}
              {AuthContext.model.hasInternalPermissions ? (
                <React.Fragment>
                  <ToggleControl
                    id={'syncOpportunityProductTypeFromMO-' + selectedLocation.uuid}
                    label="msg_opportunity_product_type_sync_toggle_label"
                    disabledState={!AuthContext.model.hasSalesOpsPermissions || locationDisabledState}
                    lockedState={lockedState}
                    value={selectedLocation.syncOpportunityProductTypeFromMO}
                    onChange={value => selectedLocation.onChange('syncOpportunityProductTypeFromMO', value)}
                  />
                  {selectedLocation.opportunityProductType === null &&
                  selectedLocation.syncOpportunityProductTypeFromMO ? (
                    <LabelField
                      id={'opportunityProductTypeOverride-' + selectedLocation.uuid}
                      label="msg_opportunity_product_type_drop_down_label"
                      parentClassName="text-right"
                      value={'msg_not_available_abbreviation'}
                    />
                  ) : (
                    <SelectControl
                      id={'opportunityProductTypeOverride-' + selectedLocation.uuid}
                      label="msg_opportunity_product_type_drop_down_label"
                      disabledState={
                        selectedLocation.syncOpportunityProductTypeFromMO ||
                        !AuthContext.model.hasSalesOpsPermissions ||
                        locationDisabledState
                      }
                      lockedState={lockedState}
                      value={selectedLocation.opportunityProductType}
                      onChange={value => selectedLocation.onChange('opportunityProductType', value)}
                      options={PriceBookContext.model.availableOpportunityProductTypeSelectControlOptions}
                    />
                  )}
                </React.Fragment>
              ) : null}
            </div>
          </CollapsableCard>

          {/* LOCATION DATA SECTION END */}

          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.nrc.id}
            id={this.sectionsInfo.nrc.id}
            className={[
              'quote-content-style',
              'transparent-bg',
              LocationLineItemsCatalog.nonRecurringHasError ? 'has-error' : '',
            ].join(' ')}
            contentWrapperClassName="full-width"
            cardContentClassName="card-content-category"
            title={this.sectionsInfo.nrc.title}
            grandTotal={formatter.currency(LocationLineItemsCatalog.grandTotalNonRecurring)}
          >
            <LineItemsCatalog
              data={LocationLineItemsCatalog.nonRecurring}
              lockedState={lockedLoc}
              disabledState={locationDisabledState}
              hideAddButton={hideLocAddLineItem}
              isWholeSale={isWholeSale}
              packageIdSelected={props.locationQuotes[selectedLocationIndex - 1].packageIdSelected}
            />
          </CollapsableCard>

          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.rc.id}
            id={this.sectionsInfo.rc.id}
            className={[
              'quote-content-style',
              'transparent-bg',
              LocationLineItemsCatalog.recurringHasError ? 'has-error' : '',
            ].join(' ')}
            cardContentClassName="card-content-category"
            contentWrapperClassName="full-width"
            title={this.sectionsInfo.rc.title}
            grandTotal={formatter.currency(LocationLineItemsCatalog.grandTotalRecurring)}
          >
            <LineItemsCatalog
              data={LocationLineItemsCatalog.recurring}
              lockedState={lockedLoc}
              disabledState={locationDisabledState}
              hideAddButton={hideLocAddLineItem}
              isWholeSale={isWholeSale}
              packageIdSelected={props.locationQuotes[selectedLocationIndex - 1].packageIdSelected}
            />
          </CollapsableCard>

          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.ictp.id}
            id={this.sectionsInfo.ictp.id}
            className="quote-content-style"
            contentWrapperClassName="full-width"
            cardContentClassName="card-content-category"
            title={this.sectionsInfo.ictp[ictpTitle]}
            grandTotal={formatter.currency(props.locationQuotes[selectedLocationIndex - 1].ictpProviders.grandTotal)}
          >
            <Ictp
              providers={props.locationQuotes[selectedLocationIndex - 1].locationIctpProviders}
              dates={props.locationQuotes[selectedLocationIndex - 1].ictpDates}
              openDetails={this.onIctppsDetailsClick}
              disableIctpps={props.appState.isCalcIstppsAllowed}
              isLocationQuote={true}
              lockedState={lockedLoc}
              setMixed={() => controller.setMixed()}
              datesDisabled={locationDisabledState}
              providersDisabled={ictpProvidersDisabled || locationDisabledState}
              // This is location tab level ICTP block
              // Considering that confirmation box available for Add-ons only. And Add-ons can not have location tabs
              // This attribute should always be false
              confirmationBoxVisible={false}
            />
          </CollapsableCard>
          {customerOrder.spiffDiscount.isSpiffVisible && (
            <CollapsableCard
              {...collapsableCardDefaultProps}
              id={this.sectionsInfo.spiff.id}
              className="quote-content-style transparent-bg"
              cardContentClassName="card-content-category"
              contentWrapperClassName="full-width"
              title={this.sectionsInfo.spiff.title}
            >
              <LocationSpiffDiscount
                spiffTotal={customerOrder.calcResults.getValue('spiffAmount', 'float')}
                nrcTotal={customerOrder.spiffDiscount.madNonRecurring}
                rcTotal={customerOrder.spiffDiscount.madRecurring}
                spiffApplied={props.locationQuotes[selectedLocationIndex - 1].calcResults.getValue(
                  'spiffAmount',
                  RESULT_TYPES.float
                )}
                nrcApplied={props.locationQuotes[selectedLocationIndex - 1].calcResults.getValue(
                  'madUpfrontAmount' + customerOrder.packageIdSelected,
                  RESULT_TYPES.float
                )}
                rcApplied={props.locationQuotes[selectedLocationIndex - 1].calcResults.getValue(
                  'madRecurAmount' + customerOrder.packageIdSelected,
                  RESULT_TYPES.float
                )}
                rcAvailable={customerOrder.spiffDiscount.rcAvailable}
                nrcAvailable={customerOrder.spiffDiscount.nrcAvailable}
                spiffAvailable={customerOrder.spiffDiscount.spiffAvailable}
                isSalesOps={AuthContext.model.hasSalesOpsPermissions}
                showSangomaPhoneIncentive={showSangomaPhoneIncentive}
                sangomaPhoneIncentiveTotal={sangomaPhoneIncentiveTotal}
                sangomaPhoneIncentiveAvailable={sangomaPhoneIncentiveAvailable}
                sangomaPhoneIncentiveApplied={props.locationQuotes[selectedLocationIndex - 1].calcResults.getValue(
                  'checkpoint623',
                  RESULT_TYPES.float
                )}
                showPartnerSpiff={
                  !PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS)
                }
              />
            </CollapsableCard>
          )}
          <section id="contactsSectionLocation" className="contacts-section quote-content-style">
            <div>
              <CollapsableCard
                {...collapsableCardDefaultProps}
                key={this.sectionsInfo.contactInfo.id}
                id={this.sectionsInfo.contactInfo.id}
                title={this.sectionsInfo.contactInfo.title}
                lockedState={lockedLoc}
                disabledState={locationDisabledState}
              >
                <ContactSection
                  section={'LOCATION_SERVICE'}
                  modelName={'serviceInfo'}
                  lockedState={lockedLoc}
                  disabledState={locationDisabledState}
                  optionsData={locationOptions}
                  contactModel={selectedLocation.serviceInfo}
                  handleCopyContacts={controller.handleCopyContacts}
                  requiredForSubmission={controller.constantsModel.getRequiredFields.requiredFieldsLOC}
                  isWhiteLabel={isWhiteLabelWholesale}
                />
              </CollapsableCard>

              {customerOrder.isRental && AuthContext.model.hasInternalPermissions && (
                <CollapsableCard
                  {...collapsableCardDefaultProps}
                  id={this.sectionsInfo.rentalInfo.id}
                  className="rental-info-section"
                  title={this.sectionsInfo.rentalInfo.title}
                >
                  <RentalFundingInvoice
                    disabledState={
                      selectedLocation.status === QUOTE_STATUS.FINALIZED
                        ? false
                        : locationDisabledState || finalizeLocDisable
                    }
                    errors={locationErrors.message}
                    errorsCnt={locationErrors.fields.length}
                    rentalFundingInvoiceEndpoint={
                      '/' + customerOrder.id + '/locations/' + selectedLocation.id + '/rentalFundingInvoice'
                    }
                  />
                </CollapsableCard>
              )}
            </div>
            <div>
              <CollapsableCard
                {...collapsableCardDefaultProps}
                key={this.sectionsInfo.billingInfo.id}
                id={this.sectionsInfo.billingInfo.id}
                title={this.sectionsInfo.billingInfo.title}
                lockedState={lockedLoc}
              >
                <ContactSection
                  section={'LOCATION_BILLING'}
                  modelName={'billingInfo'}
                  lockedState={lockedLoc}
                  disabledState={locationDisabledState}
                  optionsData={locationOptions}
                  contactModel={selectedLocation.billingInfo}
                  handleCopyContacts={controller.handleCopyContacts}
                  requiredForSubmission={controller.constantsModel.getRequiredFields.requiredFieldsLOC}
                  validateEmail={this.handleBillingEmailValidation}
                  openConfirmModal={this.openConfirmEmailModal}
                  isWhiteLabel={isWhiteLabelWholesale}
                  isEmailValidationRequested={props.appState.isEmailValidationRequested}
                />
              </CollapsableCard>

              <CollapsableCard
                {...collapsableCardDefaultProps}
                key={this.sectionsInfo.shippingInfo.id}
                id={this.sectionsInfo.shippingInfo.id}
                title={this.sectionsInfo.shippingInfo.title}
                lockedState={lockedLoc}
              >
                <ContactSection
                  section={'LOCATION_SHIPPING'}
                  modelName={'shippingInfo'}
                  lockedState={lockedLoc}
                  disabledState={locationDisabledState}
                  optionsData={locationOptions}
                  contactModel={selectedLocation.shippingInfo}
                  handleCopyContacts={controller.handleCopyContacts}
                  requiredForSubmission={controller.constantsModel.getRequiredFields.requiredFieldsLOC}
                />
              </CollapsableCard>
            </div>
          </section>
        </div>
      );
      // END: Location Content Section
    } else {
      this.sectionsInfo = controller.constantsModel.getMOSections;
      let madTitle = null;

      if (customerOrder.isAllInclusive) {
        madTitle = <span>{translator.getMessage('msg_for_all_inclusive_spiff')}</span>;
      }

      if (customerOrder.isRental) {
        madTitle = <span>{translator.getMessage('msg_for_rental_spiff')}</span>;
      }

      if ([ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(orderType)) {
        madTitle = (
          <span>
            {customerOrder.financingOption === FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR &&
              translator.getMessage('msg_for_all_inclusive')}
            {customerOrder.financingOption === FINANCING_OPTIONS.RENTAL_GREATAMERICA &&
              translator.getMessage('msg_for_rental')}
            <span>,{'\u00a0'}</span>
            {translator.getMessage('msg_customer_discount_will_always_be_applied_to_recurring')}
          </span>
        );
      }

      const leaseButtonErrors = [];

      if (!props.appState.isSaved) {
        leaseButtonErrors.push('msg_lease_form_dialog_trigger_error_quote_not_saved');
      }

      if (customerOrder.calcResults.getValue('estimatedLeasePayment', RESULT_TYPES.float, CALC_RESULT_PACKAGED) <= 0) {
        leaseButtonErrors.push('msg_lease_form_dialog_trigger_error_lease_not_eligible');
      }

      const masterOrderLineItemsCatalog = customerOrder.lineItemsCatalog;

      // START: Master Order Content Section
      locationContent = (
        <div>
          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.nrc.id}
            id={this.sectionsInfo.nrc.id}
            className={[
              'quote-content-style',
              'transparent-bg',
              masterOrderLineItemsCatalog.nonRecurringHasError ? 'has-error' : '',
            ].join(' ')}
            contentWrapperClassName="full-width"
            cardContentClassName="card-content-category"
            title={this.sectionsInfo.nrc.title}
            grandTotal={formatter.currency(masterOrderLineItemsCatalog.grandTotalNonRecurring)}
          >
            <LineItemsCatalog
              data={masterOrderLineItemsCatalog.nonRecurring}
              lockedState={lockedState || switchvoxWlwDisabled}
              disabledState={disabledState}
              isWholeSale={isWholeSale}
              packageIdSelected={customerOrder.packageIdSelected}
            />
          </CollapsableCard>

          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.rc.id}
            id={this.sectionsInfo.rc.id}
            className={[
              'quote-content-style',
              'transparent-bg',
              masterOrderLineItemsCatalog.recurringHasError ? 'has-error' : '',
            ].join(' ')}
            contentWrapperClassName="full-width"
            cardContentClassName="card-content-category"
            title={this.sectionsInfo.rc.title}
            grandTotal={formatter.currency(masterOrderLineItemsCatalog.grandTotalRecurring)}
          >
            <LineItemsCatalog
              data={masterOrderLineItemsCatalog.recurring}
              lockedState={lockedState || switchvoxWlwDisabled}
              disabledState={disabledState}
              isWholeSale={isWholeSale}
              packageIdSelected={customerOrder.packageIdSelected}
            />
          </CollapsableCard>

          <CollapsableCard
            {...collapsableCardDefaultProps}
            key={this.sectionsInfo.ictp.id}
            id={this.sectionsInfo.ictp.id}
            className="quote-content-style"
            contentWrapperClassName="full-width"
            title={this.sectionsInfo.ictp[ictpTitle]}
            grandTotal={formatter.currency(customerOrder.ictpProviders.grandTotal)}
          >
            <Ictp
              hideDates={[ORDER_TYPES.ADD_ON, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(orderType)}
              providers={customerOrder.ictpProviders}
              openDetails={this.onIctppsDetailsClick}
              dates={customerOrder.ictpDates}
              lockedState={lockedState || switchvoxWlwDisabled}
              disableIctpps={props.appState.isCalcIstppsAllowed}
              setMixed={() => controller.setMixed()}
              mixedDealerNet={customerOrder.ictpProviders.mixedDealerNet}
              orderType={orderType}
              salesOps={AuthContext.model.hasSalesOpsPermissions}
              datesDisabled={disabledState}
              providersDisabled={ictpProvidersDisabled}
              confirmationBoxVisible={
                customerOrder.orderType === ORDER_TYPES.ADD_ON &&
                !flags.pricebookTag.includes(PRICEBOOK_TAGS.BUSINESS_CONTINUITY) &&
                !isWhiteLabelWholesale
              }
              confirmationBoxDisabled={!customerOrder.ictpProviders.somePartnerEqualsZero}
            />
          </CollapsableCard>

          {orderType !== ORDER_TYPES.REDUCTION && customerOrder.spiffDiscount.isSpiffVisible && (
            <CollapsableCard
              {...collapsableCardDefaultProps}
              id={this.sectionsInfo.spiff.id}
              className="quote-content-style"
              contentWrapperClassName="full-width"
              title={this.sectionsInfo.spiff.title}
            >
              <SpiffDiscount
                {...customerOrder.spiffDiscount}
                isFinanced={customerOrder.isFinanced}
                isAllInclusive={customerOrder.isAllInclusive}
                madTitle={madTitle}
                spiffError={ERROR_TEXT_COLLECTION[props.appErrors.spiffAmount]}
                lockedState={lockedState || switchvoxWlwDisabled}
                disabledState={disabledState}
                overridesVisible={AuthContext.model.hasSalesOpsPermissions}
                spiffDiscountOverridesForceDisabled={customerOrder.isDiscountSpiffOverridesDisabled}
                spiffAmountCalculated={customerOrder.calcResults.getValue('spiffAmount', 'float')}
                isPartnerTargetSpiffAvailable={customerOrder.isPartnerTargetSpiffAvailable}
                isCalcRequested={props.appState.isCalcRequested}
                showPartnerSpiff={
                  !PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS)
                }
                showAdditionalDiscountFields={
                  PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_ADDITIONAL_DISCOUNT) &&
                  customerOrder.orderType !== ORDER_TYPES.ADD_ON
                }
                hideNrcAdditionalDiscountValues={customerOrder.isFinanced}
                showSlider={
                  PriceBookContext.model.pricebookId &&
                  !PriceBookContext.model.flags.pricebookTag.includes(
                    PRICEBOOK_TAGS.APPLY_ALL_MAD_TO_RECURRING_WITHOUT_SLIDER
                  )
                }
                showSangomaPhoneIncentive={showSangomaPhoneIncentive}
                sangomaPhoneIncentiveValue={sangomaPhoneIncentiveTotal}
              />
            </CollapsableCard>
          )}

          <section id="contactsSectionMasterOrder" className="contacts-section quote-content-style">
            <div>
              <CollapsableCard
                {...collapsableCardDefaultProps}
                key={this.sectionsInfo.contactInfo.id}
                id={this.sectionsInfo.contactInfo.id}
                title={this.sectionsInfo.contactInfo.title}
                lockedState={lockedState || switchvoxWlwDisabled}
              >
                <ContactSection
                  modelName={'serviceInfo'}
                  lockedState={lockedState || switchvoxWlwDisabled}
                  disabledState={disabledState}
                  optionsData={locationOptions}
                  orderType={orderType}
                  contactModel={customerOrder.serviceInfo}
                  handleCopyToAllLocations={controller.handleCopyToAllLocations}
                  requiredForSubmission={controller.constantsModel.getRequiredFields.requiredFieldsMO}
                  isWhiteLabel={isWhiteLabelWholesale}
                />
              </CollapsableCard>

              {customerOrder.isLeaseOptionAvailable && (
                <CollapsableCard
                  {...collapsableCardDefaultProps}
                  id={this.sectionsInfo.leaseOption.id}
                  className="lease-option-section"
                  title={this.sectionsInfo.leaseOption.title}
                >
                  <LeaseOption
                    disabledState={disabledState}
                    lockedState={lockedState || switchvoxWlwDisabled}
                    leaseOption={customerOrder.leaseOption}
                    enteredAmount={customerOrder.leaseCustomPaymentAmount}
                    calculatedAmount={customerOrder.calcResults.getValue(
                      'estimatedLeasePayment',
                      RESULT_TYPES.float,
                      CALC_RESULT_PACKAGED
                    )}
                    onOptionChange={value => this.updateModelProp('leaseOption', value)}
                    onPaymentChange={value => this.updateModelProp('leaseCustomPaymentAmount', value)}
                    onLeaseApplicationClick={() =>
                      this.setState(state => ({ isLeaseCreditApplicationDialogVisible: true }))
                    }
                    leaseButtonErrors={leaseButtonErrors}
                    leaseButtonDisabled={Boolean(leaseButtonErrors.length)}
                  />
                </CollapsableCard>
              )}
              {customerOrder.isRental && AuthContext.model.hasInternalPermissions && (
                <CollapsableCard
                  {...collapsableCardDefaultProps}
                  id={this.sectionsInfo.rentalInfo.id}
                  className="rental-info-section"
                  title={this.sectionsInfo.rentalInfo.title}
                >
                  <RentalFundingInvoice
                    disabledState={disabledState || sendForSignatureButton.disabled}
                    lockedState={lockedState || switchvoxWlwDisabled}
                    errors={sendForSignatureButton.errors}
                    errorsCnt={sendForSignatureButton.errorsCnt}
                    rentalFundingInvoiceEndpoint={'/' + customerOrder.id + '/rentalFundingInvoice'}
                  />
                </CollapsableCard>
              )}
            </div>

            <div>
              <CollapsableCard
                {...collapsableCardDefaultProps}
                key={this.sectionsInfo.billingInfo.id}
                id={this.sectionsInfo.billingInfo.id}
                title={this.sectionsInfo.billingInfo.title}
                lockedState={lockedState || switchvoxWlwDisabled}
              >
                <ContactSection
                  section={'MASTER_ORDER_BILLING'}
                  modelName={'billingInfo'}
                  lockedState={lockedState || switchvoxWlwDisabled}
                  disabledState={disabledState}
                  optionsData={locationOptions}
                  orderType={orderType}
                  isSalesOps={AuthContext.model.hasSalesOpsPermissions}
                  contactModel={customerOrder.billingInfo}
                  handleCopyContacts={controller.handleCopyContacts}
                  handleCopyToAllLocations={controller.handleCopyToAllLocations}
                  handleCopyBillingWlw={controller.billingInfoAdjustmentsForWlw}
                  requiredForSubmission={controller.constantsModel.getRequiredFields.requiredFieldsMO}
                  validateEmail={this.handleBillingEmailValidation}
                  openConfirmModal={this.openConfirmEmailModal}
                  isWhiteLabel={isWhiteLabelWholesale}
                  isEmailValidationRequested={props.appState.isEmailValidationRequested}
                />
              </CollapsableCard>

              <CollapsableCard
                {...collapsableCardDefaultProps}
                key={this.sectionsInfo.shippingInfo.id}
                id={this.sectionsInfo.shippingInfo.id}
                title={this.sectionsInfo.shippingInfo.title}
                lockedState={lockedState || switchvoxWlwDisabled}
              >
                <ContactSection
                  section={'MASTER_ORDER_SHIPPING'}
                  modelName={'shippingInfo'}
                  lockedState={lockedState || switchvoxWlwDisabled}
                  disabledState={disabledState}
                  optionsData={locationOptions}
                  orderType={orderType}
                  contactModel={customerOrder.shippingInfo}
                  handleCopyContacts={controller.handleCopyContacts}
                  requiredForSubmission={controller.constantsModel.getRequiredFields.requiredFieldsMO}
                />
              </CollapsableCard>
            </div>
          </section>
        </div>
      );
      // END: Master Order Content Section
    }

    if (
      !props.appState.quoteLoading &&
      AuthContext.model.hasInternalPermissions &&
      ORDER_TYPES.ADD_ON === orderType &&
      ![QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(customerOrder.quoteStatus)
    ) {
      this.showAddonWarning();
    }

    const selectedSignatureEnvelope = customerOrder.signatureHistory.find(
      sig => sig.id === this.state.selectedSignatureId
    );

    return (
      <main className="card-section">
        <Toolbar {...toolbarProps} />

        <section className="max-width-container first-section quote-content-style">
          {/*//START: Left Column*/}
          <div>
            <CustomerInformationWrapper
              {...collapsableCardDefaultProps}
              toggleCollapse={this.showHideGeneralSection}
              customerOrder={customerOrder}
              {...props.sections.customerInformationSection}
            />
            <PartnerInformationWrapper
              {...collapsableCardDefaultProps}
              toggleCollapse={this.showHideGeneralSection}
              customerOrder={customerOrder}
              {...props.sections.partnerInformationSection}
            />
            {customerOrder.showDaasSection && (
              <DaasSectionWrapper
                {...collapsableCardDefaultProps}
                toggleCollapse={this.showHideGeneralSection}
                customerOrder={customerOrder}
                {...props.sections.daasSection}
              />
            )}
          </div>
          {/*//END: Left Column*/}

          {/*//START: Right Column*/}
          <div>
            <QuoteInformationWrapper
              {...collapsableCardDefaultProps}
              toggleCollapse={this.showHideGeneralSection}
              customerOrder={customerOrder}
              controller={controller}
              {...props.sections.quoteInformationSection}
            />
            {customerOrder.showSangomaCxSettingsSection && (
              <SangomaCxSettingsWrapper
                {...collapsableCardDefaultProps}
                toggleCollapse={this.showHideGeneralSection}
                customerOrder={customerOrder}
                {...props.sections.sangomaCxSettingsSection}
              />
            )}
          </div>
          {/*//END: Right Column*/}
        </section>

        {AuthContext.model.hasSalesOpsPermissions && (
          <section className="max-width-container quote-content-style">
            <AdminSectionWrapper
              {...collapsableCardDefaultProps}
              toggleCollapse={this.showHideGeneralSection}
              customerOrder={customerOrder}
              {...props.sections.adminSection}
            />
            {customerOrder.showAddendumsSection && (
              <AddendumsSectionWrapper
                {...collapsableCardDefaultProps}
                toggleCollapse={this.showHideGeneralSection}
                customerOrder={customerOrder}
                {...props.sections.addendumSection}
              />
            )}
          </section>
        )}

        <div className={'max-width-container order-locations-tabs-container'}>
          <div>
            <div className="clone-and-allocation-controls">
              <div>
                <button
                  id="global-add-line-item-dialog-trigger"
                  className="tertiary blue-border"
                  onClick={this.props.addLineItemsGlobalDialog.handleDialogOpen}
                  disabled={disabledState || lockedState}
                >
                  {translator.getMessage('msg_add_line_item')}
                </button>
              </div>
              {showDuplicateButtons && (
                <React.Fragment>
                  {ORDER_TYPES.REWRITE !== customerOrder.orderType && (
                    <BadgeButton
                      id="clone-btn"
                      key="clone-btn"
                      label={translator.getMessage('msg_clone')}
                      btnClassName={[
                        'clone-btn',
                        'tertiary',
                        'blue-border',
                        this.props.appState.pricebookIdValidationInProgress ? 'validating-pricebook-id' : '',
                      ]}
                      onClick={this.handleCloneClick}
                      errors=""
                      infoTooltip={quoteHasStarboxWithoutSbreliant ? 'msg_starbox_without_sbreliant' : ''}
                      hideInfoBadge={true}
                      disabled={cloneButtonDisabled}
                      tooltipAlign="left"
                    />
                  )}
                  <BadgeButton
                    id="create-revision-btn"
                    key="create-revision-btn"
                    label={translator.getMessage('msg_create_revision')}
                    btnClassName="create-revision-btn tertiary blue-border"
                    onClick={this.handleRevisionClick}
                    errors=""
                    infoTooltip={revisionButtonTooltip}
                    hideInfoBadge={true}
                    disabled={createRevisionButtonDisabled}
                    tooltipAlign="left"
                  />
                </React.Fragment>
              )}
              {showLocations && (
                <button
                  type="button"
                  className="roll-up-btn tertiary blue-border"
                  onClick={controller.rollUpOrder}
                  disabled={lockedState || disabledState || switchvoxWlwDisabled || controller.rollUpDisabled}
                >
                  <span>{translator.getMessage('msg_roll_up_order')}</span>
                </button>
              )}

              {AuthContext.model.hasSalesOpsPermissions && (
                <BadgeButton
                  id="re-push-btn"
                  key="re-push-btn"
                  label={translator.getMessage('msg_re_push')}
                  btnClassName="re-push-btn tertiary blue-border"
                  onClick={e => this.handleRePush(e, true)}
                  infoTooltip="msg_confirm_impact_on_billing_and_commissins"
                  hideInfoBadge
                  disabled={
                    disabledState ||
                    !customerOrder.id ||
                    props.appState.isCalcRequested ||
                    props.appState.rePushMasterOrderInProgress
                  }
                  tooltipAlign="left"
                  errors={props.appState.rePushMasterOrderInProgress ? 'msg_re_push_in_progress_button_tooltip' : ''}
                />
              )}

              {customerOrder.locationBreakoutsCsvDownloadButton.visible && (
                <React.Fragment>
                  <BadgeButton
                    {...customerOrder.locationBreakoutsCsvDownloadButton}
                    disabled={disabledState || !controller.appState.isSaved}
                    btnClassName="location-breakouts-btn tertiary blue-border"
                    id="location-breakouts-csv-download-button"
                    form="location-breakouts-csv-download-form"
                    label={translator.getMessage(customerOrder.locationBreakoutsCsvDownloadButton.label)}
                  />
                  <HiddenFormHelper
                    id="location-breakouts-csv-download-form"
                    action={customerOrder.locationBreakoutsCsvDownloadButton.downloadUrl}
                    method={HiddenFormHelper.methods.post}
                  />
                </React.Fragment>
              )}

              {showLocations &&
                (controller.isQuoteContainUnderAllocatedItems() ? (
                  <span className="roll-up-note">
                    {translator.getMessage('msg_note_quote_contains_under_allocated_items')}
                  </span>
                ) : null)}
            </div>

            <LocationsTab
              showPlusButton={showLocations}
              showLocationMenuButton={customerOrder.orderType !== ORDER_TYPES.ADD_ON}
              tabs={props.quotes}
              isAddDisabled={isAddLocationDisable}
              activeLocationId={selectedLocation.locationInfo.locationQuoteId}
              onSelectLocation={controller.selectLocationQuote}
              onAddLocation={this.addLocation}
            />
            {locationContent}
          </div>
        </div>

        <HeaderInformation
          quoteId={customerOrder.id}
          appState={props.appState}
          quote={customerOrder}
          toggleAutoCalculation={controller.toggleAutoCalculation}
          onQuoteHistoryClick={this.onQuoteHistoryClick}
          isAllCollapsed={this.isAllCollapsed()}
          onCollapseExpandClick={this.collapseExpandAllSection}
          sectionsList={this.sectionsInfo}
          navCallback={this.showHideSection}
          navGeneralSectionCallback={this.showHideGeneralSection}
          onCheckpointClick={this.onCheckpointOpen}
          checkpointsDisabled={
            selectedLocationIndex === 0 ? customerOrder.id === null : selectedLocation.trackingLocationId === null
          }
          markersBtnDisabled={Boolean(!controller.appState.isSaved) || Boolean(customerOrder.hasFinalizedLocations)}
          markersTooltipMessage={
            (!controller.appState.isSaved && 'msg_save_changes') ||
            (customerOrder.hasFinalizedLocations && 'msg_no_finalized_locations_required') ||
            ''
          }
          onMarkersClick={this.onMarkersOpen}
        />

        {props.children}

        {customerOrder.addLineItemDialog.opened ? <AddLineItemDialog {...customerOrder.addLineItemDialog} /> : null}

        {customerOrder.addExistingLineItemDialog.opened ? (
          <AddLineItemDialog {...customerOrder.addExistingLineItemDialog} />
        ) : null}

        {customerOrder.lineItemRemoveDialog.isOpen ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal delete-line-item-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              <p className="light-bold">{translator.getMessage('msg_do_you_want_to_delete_this_line_item')}</p>
              <p>
                {customerOrder.lineItemRemoveDialog.allocated}
                {translator.getMessage('msg_units_of')} "{customerOrder.lineItemRemoveDialog.name}"
                {translator.getMessage('msg_allocated_in_location_quotes')}
              </p>
            </div>
            <div className="default-confirm-modal-actions">
              <button className="text-btn" onClick={customerOrder.lineItemRemoveDialog.onCancel}>
                {translator.getMessage('msg_cancel')}
              </button>
              <button className="critical" onClick={customerOrder.lineItemRemoveDialog.onYes}>
                {translator.getMessage('msg_delete')}
              </button>
            </div>
          </Modal>
        ) : null}

        {props.pricebookIdDialog.isOpen ? (
          <Modal
            isOpen={true}
            overlayClassName="modal-dialog-overlay"
            className="modal-dialog-content"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="message">{translator.getMessage('msg_pricebook_id_change_dialog_message')}</div>
            <div className="controls">
              <button className="btn-cancel" onClick={props.pricebookIdDialog.onCancel}>
                {translator.getMessage('msg_cancel')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.seApprovedSaveModal ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal reset-se-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              <p className="light-bold">{translator.getMessage('msg_reset_se_message_title')}</p>
              <p>{translator.getMessage('msg_new_changes_to_an_approved_quote')}</p>
            </div>
            <div className="default-confirm-modal-actions">
              <button className="btn-cancel text-btn" onClick={this.toggleSaveSEApprovedModal}>
                {translator.getMessage('msg_cancel')}
              </button>
              <button className="btn-yes" onClick={this.onClickSEApprovedSave}>
                {translator.getMessage('msg_save')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.copyEntireMasterOrder ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal single-location-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              <p className="light-bold">{translator.getMessage('msg_is_this_a_single_location_order')}</p>
              <p>{translator.getMessage('msg_for_single_location_orders')}</p>
            </div>
            <div className="default-confirm-modal-actions">
              <button className="btn-cancel text-btn" onClick={this.addLocationAndCloseModal}>
                {translator.getMessage('msg_no')}
              </button>
              <button className="btn-yes btn-primary" onClick={this.addPureLocationAndCloseModal}>
                {translator.getMessage('msg_yes')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.locationPackageTypeModal ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              <p className="light-bold">{translator.getMessage('msg_change_quote_type')}</p>
              <p>{translator.getMessage('msg_current_quote_type_will_not_be_available')}</p>
            </div>
            <div className="default-confirm-modal-actions">
              <button className="btn-cancel text-btn" onClick={() => this.setState({ locationPackageTypeModal: false })}>
                {translator.getMessage('msg_cancel')}
              </button>
              <button
                className="btn-yes"
                onClick={() =>
                  this.setState({ locationPackageTypeModal: false }, () =>
                    this.setPackageId(this.state.packageIdSelected)
                  )
                }
              >
                {translator.getMessage('msg_change')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.packageTelecomWarning ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">{translator.getMessage('msg_no_package_telecom_type_warning')}</div>
            <div className="default-confirm-modal-actions">
              <button className="btn-yes" onClick={() => this.setState({ packageTelecomWarning: false })}>
                {translator.getMessage('msg_ok')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.retainOverridesModalOpen ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">{translator.getMessage('msg_include_overrides')}</div>
            <div className="default-confirm-modal-actions">
              <button className="btn-cancel text-btn" onClick={() => this.closeRetainOverridesModal()}>
                {translator.getMessage('msg_no')}
              </button>
              <button className="btn-yes" onClick={() => this.closeRetainOverridesModal(false)}>
                {translator.getMessage('msg_yes')}
              </button>
            </div>
          </Modal>
        ) : null}

        {customerOrder.showPrintFlowConfirmationModal ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal print-flow-info-dialog"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              <p className="light-bold">{translator.getMessage('msg_print_flow_info_dialog_header')}</p>
              <p>{translator.getMessage('msg_print_flow_info_dialog_message')}</p>
            </div>
            <div className="default-confirm-modal-actions">
              <button className="btn-cancel text-btn" onClick={() => this.updateModelProp('useESigDocumentsFlow', true)}>
                {translator.getMessage('msg_cancel')}
              </button>
              <button className="btn-yes" onClick={() => this.updateModelProp('lockedInPrintFlow', true)}>
                {translator.getMessage('msg_confirm')}
              </button>
            </div>
          </Modal>
        ) : null}

        {typeof controller.copyAction === 'string' ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              <p className="light-bold">
                {cloneAction
                  ? translator.getMessage('msg_clone_successfully_created')
                  : translator.getMessage('msg_revision_successfuly_created')}
              </p>
              <p className="copy-sub-title">
                {translator.getMessage('msg_the_following_information_will_need_to_be_entered')}
              </p>
              <div className="copy-successfully-created-body">
                {controller.copyAction &&
                  CLONE_ACTION_MESSAGES[controller.copyAction].map((message, index) => {
                    return (
                      <span key={message}>
                        {index + 1 + '. '}
                        <span>{translator.getMessage(message)}</span>
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className="default-confirm-modal-actions">
              <button className="btn-yes" onClick={controller.closeCopyQuoteModal}>
                {translator.getMessage('msg_ok')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.packageDetailsOpened ? (
          <PackageDetailsComponent
            quote={customerOrder}
            appState={props.appState}
            isInternal={AuthContext.model.hasInternalPermissions}
            packageId={this.state.packageDetailsId}
            open={true}
            onClose={this.onDetailsClose}
            requiredForFinalize={controller.constantsModel.getRequiredForFinalize}
            toolbarModel={props.toolbar}
            preliminaryButtonModel={preliminaryButton}
            printFinalButtonModel={printFinalButton}
            disabledState={disabledState || switchvoxWlwDisabled}
            isWholesale={isWholeSale}
            packageModel={customerOrder.getPackageModelById(this.state.packageDetailsId)}
            asyncFetchTaxEstimates={controller.asyncFetchTaxEstimates}
            asyncGenerateTaxEstimates={controller.asyncGenerateTaxEstimates}
            isSelectedPackageOpened={this.state.packageDetailsId === customerOrder.packageIdSelected}
          />
        ) : null}

        {AuthContext.model.hasInternalPermissions && this.state.ictppsDetailsOpened ? (
          <ICTPPSDetails open={true} ictppsItems={apiData.ictppsDetails} onClose={this.onIctppsDetailsClick} />
        ) : null}

        {this.state.quoteHistoryOpen ? (
          <QuoteHistoryComponent
            quoteId={customerOrder.id}
            open={true}
            onClose={this.onQuoteHistoryClose}
            quoteHistory={customerOrder.quoteHistory}
          />
        ) : null}

        {this.state.slexOpened ? (
          <SlexComponent
            open={true}
            onClose={this.onSlexClose}
            title={'msg_approvals'}
            salesExceptions={customerOrder.salesExceptions}
            onRefresh={controller.requestSalesExceptions}
            forceHideContents={props.appState.isSalesExceptionsRequestInProgress}
          />
        ) : null}

        {this.state.checkpointsOpened ? (
          <Checkpoints
            isVisible={true}
            data={customerOrder.checkpoints}
            onClose={this.onCheckpointClose}
            title={this.props.selectedQuote.index === 0 ? customerOrder.orderType : 'locationTitle'}
            isDataLoading={this.props.appState.isCheckpointsRequested}
          />
        ) : null}

        {config.eSignatureEnabled && this.state.signatureOpened ? (
          <SignatureRequest
            isVisible={true}
            previewPdfUrlAndValues={controller.previewPdfUrlAndValues}
            onClickSubmit={controller.submitSignature}
            onClickClose={this.onSignatureClose}
            onChange={controller.changeSignatureDocuments}
            availableDocuments={customerOrder.availableDocuments}
            eSignature={customerOrder.eSignature}
            isPurchase={[3, 4].includes(customerOrder.packageIdSelected)}
            isFinanced={customerOrder.isFinanced}
            customerName={customerOrder.customerName}
            billingContact={customerOrder.billingInfo.contactFirstName + ' ' + customerOrder.billingInfo.contactLastName}
            billingEmail={customerOrder.billingInfo.contactEmail}
            billingPhone={customerOrder.billingInfo.contactOfficePhone}
            isSalesOps={AuthContext.model.hasSalesOpsPermissions}
            isInternalUser={AuthContext.model.hasInternalPermissions}
            ccEmail={customerOrder.dealerContactEmail}
            validateEmail={Validate.email}
            validateSignatureSubmit={Validate.signatureSubmitBtn}
            disabledState={disabledState}
            isSubmitSignatureRequested={this.props.appState.isSubmitSignatureRequested}
            quoteId={customerOrder.id}
            leaseOption={customerOrder.leaseOption}
            leasePayment={customerOrder.calcResults.getValue(
              'estimatedLeasePayment',
              RESULT_TYPES.float,
              CALC_RESULT_PACKAGED
            )}
            isLeaseOptionAvailable={customerOrder.isLeaseOptionAvailable}
            showCreditDocumentsOnFileMessage={customerOrder.creditDocumentsOnFile && customerOrder.isAllInclusive}
            showRentalAgreementOnFileMessage={customerOrder.greatAmericaRentalAgreementOnFile && customerOrder.isRental}
            isAddon={customerOrder.orderType === ORDER_TYPES.ADD_ON}
            showRentalNotice={customerOrder.isRental}
            isWhiteLabelWholesale={isWhiteLabelWholesale}
            quoteOwnerEmail={customerOrder.dealerContactEmail}
            standaloneServiceNonUCaaS={customerOrder.standaloneServiceNonUCaaS}
          />
        ) : null}

        {config.eSignatureEnabled && this.state.signatureHistoryOpened ? (
          <SignatureHistory
            isVisible={true}
            isSalesOps={AuthContext.model.hasSalesOpsPermissions}
            data={customerOrder.signatureHistory}
            quoteStatus={customerOrder.quoteStatus}
            isExistingAls={customerOrder.existingAlsAgreement}
            quickQuoteEndpoint={controller.prelimPdfBtnEndpoint()}
            partnerPdfEndpoint={controller.partnerPdfEndpoint()}
            onClickRefresh={this.loadEsrHistory}
            onClickClose={this.onSignatureHistoryClose}
            onClickSignatureCancel={this.openCancelSignatureModal}
            getSignaturePreviewUrlAndValues={controller.downloadDocumentsSignatureUrlAndValues}
            onClickSignatureDetails={this.onSignatureDetailsOpen}
            onClickReSubmitSignature={this.requestAvailableDocumentsWithData}
            onClickVoidSignature={controller.requestVoidEsr}
            onClickAdditionalCredit={this.requestAvailableDocumentsWithData}
            getSignatureDownloadEndpoint={controller.getSignatureDownloadEndpoint}
            quickQuoteDisabled={printQuickBtnDisabled || disabledState || switchvoxWlwDisabled}
            quickQuoteErrors={printQuickBtnErrors}
            quickQuoteErrorsCount={requiredQuickPrintFields?.fields.length || 0}
            partnerPdfDisabled={props.toolbar.printPartnerBtnDisabled || disabledState || switchvoxWlwDisabled}
            partnerPdfErrors={props.toolbar.printPartnerBtnErrors}
            partnerPdfErrorsCount={props.toolbar.requiredPartnerPrintFields?.fields.length || 0}
            additionalCreditDisabled={
              disabledState ||
              !customerOrder.isAllInclusive ||
              customerOrder.creditDocumentsOnFile ||
              customerOrder.greatAmericaRentalAgreementOnFile
            }
            additionalCreditVisible={customerOrder.additionalCreditAvailable}
            additionalCreditInfoTooltip={
              customerOrder.creditDocumentsOnFile || customerOrder.greatAmericaRentalAgreementOnFile
                ? 'msg_additional_credit_button_info_tooltip'
                : ''
            }
            signatureCancelDisabled={props.appState.isSignatureCancelRequested}
            signatureReSubmitAllowed={customerOrder.quoteStatus === QUOTE_STATUS.SENT_FOR_SIGNATURE}
            allowToVoid={customerOrder.allowToVoid}
            additionalCreditEnvelope={customerOrder.availableDocuments.find(
              envelope => envelope.type === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT
            )}
            availableEnvelopeTypes={customerOrder.availableDocuments.map(d => d.type)}
            showPartnerPdf={
              !PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS)
            }
          />
        ) : null}
        {config.eSignatureEnabled && this.state.signatureDetailsOpened ? (
          <SignatureDetails
            isVisible={true}
            data={selectedSignatureEnvelope}
            isExistingAls={customerOrder.existingAlsAgreement}
            onClickRefresh={controller.refreshSignatureDetails}
            onClickClose={this.onSignatureDetailsClose}
            onClickBack={this.onSignatureDetailsBack}
            onClickReSubmitSignature={this.requestAvailableDocumentsWithData}
            onChangeSignerEmail={customerOrder.eSignature.onChangeSignerEmail}
            getSignatureDownloadEndpoint={controller.getSignatureDownloadEndpoint}
            sendReminderToSigner={controller.sendReminderToSigner}
            getSignaturePreviewUrlAndValues={controller.downloadDocumentsSignatureUrlAndValues}
            requestEmailChange={controller.requestChangeCounterSignerEmail}
            signatureReSubmitAllowed={customerOrder.quoteStatus === QUOTE_STATUS.SENT_FOR_SIGNATURE}
            isSalesOps={AuthContext.model.hasSalesOpsPermissions}
            isEnvelopeAvailable={customerOrder.availableDocuments.some(
              d => d.type === selectedSignatureEnvelope.bundleType
            )}
          />
        ) : null}

        {this.state.finalizeDialogOpened ? (
          <SubmitWizardComponent
            open={true}
            quote={customerOrder}
            onClose={this.onFinalizeDialogClose}
            onFinalize={_isFinalizedAndSignedMO ? this.handleUnFinalize : this.handleFinalize}
            confirmButtonLabel={_isFinalizedAndSignedMO ? 'msg_un_finalize' : 'msg_finalize_order'}
          />
        ) : null}

        {this.state.approvalDialogOpened ? (
          <ApprovalComponent
            open={true}
            onClose={this.onSubmitRetractApprovalDialogClose}
            action={submitForApproval ? 'submit' : 'retract'}
            onSubmitApproval={this.handleSubmitForApproval}
            onRetractApproval={this.handleRetractApproval}
          />
        ) : null}

        {this.state.isLeaseCreditApplicationDialogVisible ? (
          <LeaseOptionEmailForm
            isOpen={true}
            onSubmit={this.handleLeaseCreditApplicationSubmit}
            onCancel={() => this.setState(state => ({ isLeaseCreditApplicationDialogVisible: false }))}
            isQuickQuoteAvailable={!preliminaryButton.disabled}
          />
        ) : null}

        {this.state.billingEmailSuggestionModal ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal confirm-email-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              {translator.getMessage('msg_did_you_mean')}"{this.state.billingEmailSuggestion}"
              {translator.getMessage('msg_instead_of')}"{selectedLocation.billingInfo.contactEmail}"?
            </div>
            <div className="default-confirm-modal-actions ">
              <button className="text-btn" onClick={this.handleBillingEmailSuggestionModalNoClick}>
                {translator.getMessage('msg_no')}
              </button>
              <button className="btn-yes" onClick={this.handleBillingEmailSuggestionModalYesClick}>
                {translator.getMessage('msg_yes')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.billingEmailConfirmModal ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal confirm-email-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">
              {translator.getMessage('msg_email_address')}"{selectedLocation.billingInfo.contactEmail}"
              {translator.getMessage('msg_email_may_be_invalid')}
            </div>
            <div className="default-confirm-modal-actions">
              <button className="tertiary" onClick={this.handleBillingEmailConfirmModalContinueClick}>
                {translator.getMessage('msg_confirm_email')}
              </button>
              <button onClick={this.handleBillingEmailConfirmModalCancelClick}>
                {translator.getMessage('msg_change_email')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.cancelSignatureModal ? (
          <Modal
            isOpen={true}
            overlayClassName="default-overlay-modal"
            className="default-confirm-modal"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="default-modal-label">{translator.getMessage('msg_cancel_signature_message')}</div>
            <div className="default-confirm-modal-actions">
              <button className="text-btn" onClick={this.closeCancelSignatureModal}>
                {translator.getMessage('msg_no')}
              </button>
              <button className="critical" onClick={this.handleCancelSignatureModalCancelClick}>
                {translator.getMessage('msg_cancel')}
              </button>
            </div>
          </Modal>
        ) : null}

        {this.state.addLocationsModal ? (
          <AddLocationsModal
            locations={this.state.availableLocationsToAdd}
            existingLocationIds={controller.quotes.locationsS2sIds}
            closeDialog={this.closeAddLocationsDialog}
            addLocations={this.addMultipleLocations}
          />
        ) : null}

        {this.state.markersModalOpened ? (
          <MarkersDialog
            onClose={this.onMarkersClose}
            onSave={this.onMarkersSave}
            data={customerOrder.markers}
            isDataLoading={props.appState.isMarkersRequested}
            isOpen
          />
        ) : null}

        {this.state.duplicateLocationModalOpened ? (
          <DuplicateLocationModal
            numLocations={customerOrder.numLocations}
            numExistingLocations={customerOrder.locations.length}
            isQuoteAvailableForChanges={[QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(
              customerOrder.quoteStatus
            )}
            closeModal={this.onDuplicateLocationClose}
            duplicateLocations={this.onDuplicateLocationConfirm}
          />
        ) : null}

        {this.props.addLineItemsGlobalDialog.isOpen ? (
          <AddLineItems {...this.props.addLineItemsGlobalDialog}></AddLineItems>
        ) : null}

        {this.state.isCloneOptionsModalOpen ? (
          <CloneOptionsComponent
            onClose={this.closeCloneOptionsModal}
            handleQuoteClone={this.props.controller.handleQuoteClone}
            isSalesOps={AuthContext.model.hasSalesOpsPermissions}
          />
        ) : null}
      </main>
    );
  }

  getOptions() {
    return {
      countries: apiData.countries,
      states: apiData.states,
      billTo: CONTACT_PROPS.billingInfo.billToId.options,
    };
  }

  /**
   * Event handler for model update event.
   * @param {string, number, boolean} parameterValue - New value of parameter
   * @param {string} parameterName - Name of parameter for update
   */
  updateModelProp(parameterName, parameterValue) {
    if (typeof this.props.customerOrder.onChange === 'function') {
      this.props.customerOrder.onChange(parameterName, parameterValue);
    }
  }

  setPackageId(packageId) {
    if (typeof this.props.customerOrder.onChange === 'function') {
      this.props.customerOrder.onChange('packageIdSelected', packageId);
    }
  }

  onSlexOpen() {
    this.setState(
      {
        slexOpened: true,
      },
      this.props.controller.requestSalesExceptions.bind(this.props.controller, true)
    );
  }

  onSlexClose() {
    this.setState({
      slexOpened: false,
    });
  }

  async onSignatureOpen() {
    const isActiveQuote = await this.props.toolbarButtons.sendForSignatureButton.asyncIsActiveQuote();

    if (!isActiveQuote) {
      return false;
    }

    this.setState(
      {
        signatureOpened: true,
      },
      this.props.controller.requestAvailableDocuments,
      this.props.customerOrder.eSignature.init()
    );
  }

  onSignatureClose() {
    this.setState({
      signatureOpened: false,
    });
  }

  onSignatureHistoryOpen() {
    this.setState(
      {
        signatureHistoryOpened: true,
      },
      this.loadEsrHistory
    );
  }

  onSignatureHistoryClose() {
    this.setState({ signatureHistoryOpened: false });
  }

  onSignatureDetailsOpen(id) {
    this.setState({
      signatureDetailsOpened: true,
      signatureHistoryOpened: false,
      selectedSignatureId: id,
    });
  }

  onSignatureDetailsClose() {
    this.setState({
      signatureDetailsOpened: false,
      selectedSignatureId: null,
    });
  }

  onSignatureDetailsBack() {
    this.setState({
      signatureDetailsOpened: false,
      signatureHistoryOpened: true,
      selectedSignatureId: null,
    });
  }

  requestAvailableDocumentsWithData(data) {
    this.setState(
      {
        signatureOpened: true,
        signatureDetailsOpened: false,
        signatureHistoryOpened: false,
        selectedSignatureId: null,
      },
      () => this.props.controller.requestAvailableDocuments(this.props.customerOrder.id, data)
    );
  }

  loadEsrHistory() {
    this.props.controller.requestAvailableDocuments().then(() => {
      this.props.controller.getSignatureHistory();
    });
  }

  selectPackage(id) {
    const customerOrder = this.props.customerOrder;

    if (
      customerOrder.orderType === ORDER_TYPES.ADD_ON &&
      !AuthContext.model.hasSalesOpsPermissions &&
      customerOrder.locationPackageType
    ) {
      const packageTypeRange = {
        1: [1, 3],
        2: [2, 4],
        3: [3],
        4: [4],
      };

      if (packageTypeRange[customerOrder.locationPackageType].includes(customerOrder.packageIdSelected)) {
        this.setPackageId(id);
      } else {
        this.setState({
          locationPackageTypeModal: true,
          packageIdSelected: id,
        });
      }
    } else {
      this.setPackageId(id);
    }
  }

  onDetailsClick(id) {
    this.setState({
      packageDetailsId: typeof id === 'number' ? id : this.props.customerOrder.packageIdSelected,
      packageDetailsOpened: true,
    });
  }

  onDetailsClose() {
    this.setState({
      packageDetailsOpened: false,
    });

    this.props.controller.resetTaxEstimates();
  }

  onQuoteHistoryClick() {
    this.props.controller.getQuoteHistoryMaster();

    this.setState({
      quoteHistoryOpen: true,
    });
  }

  onQuoteHistoryClose() {
    this.setState({
      quoteHistoryOpen: false,
    });
  }

  onIctppsDetailsClick() {
    const selectedLocationIndex = this.props.selectedQuote.index;
    const isMasterOrder = selectedLocationIndex === 0;
    const selectedQuote = this.props.quotes[selectedLocationIndex];

    if (selectedQuote.quoteId || selectedQuote.id) {
      if (!this.state.ictppsDetailsOpened) {
        this.props.controller.requestIctppsDetails(selectedQuote, isMasterOrder);
      }

      this.setState({
        ictppsDetailsOpened: !this.state.ictppsDetailsOpened,
      });
    }
  }

  async onClickFinalizeBtn() {
    const isActiveQuote = await this.props.toolbarButtons.finalizeButton.asyncIsActiveQuote();

    if (!isActiveQuote) {
      return false;
    }

    this.setState({
      finalizeDialogOpened: true,
    });
  }

  onClickUnFinalizeBtn() {
    this.setState({
      finalizeDialogOpened: true,
    });
  }

  openApprovalDialog() {
    this.setState({
      approvalDialogOpened: true,
    });
  }

  onFinalizeDialogClose() {
    this.setState({
      finalizeDialogOpened: false,
    });
  }

  onSubmitRetractApprovalDialogClose() {
    this.setState({
      approvalDialogOpened: false,
    });
  }

  toggleSaveSEApprovedModal() {
    this.setState({
      seApprovedSaveModal: !this.state.seApprovedSaveModal,
    });
  }

  onCheckpointOpen() {
    this.setState({ checkpointsOpened: true }, this.props.controller.requestCheckpoints);
  }
  onCheckpointClose() {
    this.setState({ checkpointsOpened: false });
  }

  onClickSEApprovedSave() {
    this.toggleSaveSEApprovedModal();
    this.props.controller.asyncOnClickSaveBtn();
  }

  onClickAmendBtn() {
    this.props.controller.requestAmend();
  }

  onClickCancelAmendment() {
    this.props.controller.cancelAmendment();
  }

  handleFinalize() {
    if (typeof this.props.controller.onQuoteFinalize === 'function') {
      this.props.controller.onQuoteFinalize();
    }

    this.onFinalizeDialogClose();
  }

  handleRePush(e, isQuote) {
    e.stopPropagation();

    this.props.controller.rePushToTracking(isQuote);
  }

  handleSyncToTracking(e) {
    e.stopPropagation();

    this.props.controller.syncToTracking();
  }

  handleLocationFinalize(e) {
    e.stopPropagation();

    this.props.controller.requestLocationFinalize();
  }

  handleUnFinalize() {
    if (typeof this.props.controller.onQuoteUnFinalize === 'function') {
      this.props.controller.onQuoteUnFinalize();
    }

    this.onFinalizeDialogClose();
  }

  handleLocationUnFinalize(e) {
    e.target.disabled = true;
    e.stopPropagation();
    this.props.controller.requestLocationUnFinalize();
  }

  handleSubmitForApproval(value) {
    if (typeof this.props.controller.onSubmitForApproval === 'function') {
      this.props.controller.onSubmitForApproval(value);
    }

    this.onSubmitRetractApprovalDialogClose();
  }

  handleRetractApproval(value) {
    if (typeof this.props.controller.onRetractApproval === 'function') {
      this.props.controller.onRetractApproval(value);
    }

    this.onSubmitRetractApprovalDialogClose();
  }

  addLocation() {
    if (this.props.customerOrder.orderType === ORDER_TYPES.REWRITE) {
      this.openAddLocationsModal();
    } else {
      this.addSingleLocation();
    }
  }

  addSingleLocation() {
    const { controller, customerOrder } = this.props;

    if (
      [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(customerOrder.quoteStatus) &&
      customerOrder.numLocations === 1 &&
      customerOrder.locations.length === 0
    ) {
      return controller.addLocationQuote(false, true);
    }

    if (
      controller.quotes.items.length === 1 &&
      customerOrder.numLocations === 1 &&
      controller.quoteHasNonAutoAddedLineItems()
    ) {
      this.setState({ copyEntireMasterOrder: true });
    } else {
      controller.addLocationQuote();
    }
  }

  openAddLocationsModal() {
    this.props.appState.setState('quoteLoading', true);

    this.props.controller.getAvailableLocationsToAdd().then(r => {
      this.props.appState.setState('quoteLoading', false);

      this.setState({
        availableLocationsToAdd: r,
        addLocationsModal: true,
      });
    });
  }

  closeAddLocationsDialog() {
    this.setState({
      availableLocationsToAdd: [],
      addLocationsModal: false,
    });
  }

  addMultipleLocations(locationIds) {
    this.props.appState.setState('quoteLoading', true);

    this.setState({ addLocationsModal: false }, () => {
      this.props.controller.addMultipleLocations(locationIds, this.props.customerOrder.customerId, true).then(() => {
        this.props.appState.setState('quoteLoading', false);
      });
    });
  }

  addLocationAndCloseModal() {
    this.setState({ copyEntireMasterOrder: false }, () => {
      this.props.controller.addLocationQuote();
    });
  }

  addPureLocationAndCloseModal() {
    this.setState({ copyEntireMasterOrder: false }, () => {
      this.props.controller.addLocationQuote(false, true);
    });
  }

  showAddonWarning = _.once(() => {
    if (
      this.props.customerOrder.availableLineProductGpCode === null &&
      this.props.customerOrder.locationPackageType === null
    ) {
      this.setState({ packageTelecomWarning: true });
    }
  });

  openRetainOverridesModal(duplicateType) {
    this.setState({
      retainOverridesModalOpen: true,
      duplicateType,
    });
  }

  closeRetainOverridesModal(resetOverrides = true) {
    let callback = null;

    if (this.state.duplicateType === DUPLICATE_METHOD.CLONE) {
      callback = this.props.controller.handleQuoteClone;
    }

    if (this.state.duplicateType === DUPLICATE_METHOD.REVISION) {
      callback = this.props.controller.handleQuoteCreateRevision;
    }

    this.setState({ retainOverridesModalOpen: false }, () =>
      typeof callback === 'function' ? callback(resetOverrides) : null
    );
  }

  async openCloneOptionsModal() {
    await this.props.controller.getIndustryTypes();

    this.setState({
      isCloneOptionsModalOpen: true,
    });
  }

  closeCloneOptionsModal() {
    this.setState({
      isCloneOptionsModalOpen: false,
    });
  }

  async handleCloneClick() {
    this.props.appState.setState('pricebookIdValidationInProgress', true);
    const isPricebookIdRemainSame = await Validate.asyncIsDefaultPricebookIdEqualsQuotePricebookId(
      this.props.customerOrder,
      QUOTE_COPY_ACTION.CLONE
    );

    this.props.appState.setState('pricebookIdValidationInProgress', false);

    if (!isPricebookIdRemainSame) {
      ErrorContext.model.setProps({
        isShown: true,
        details: 'msg_quote_pricebook_id_does_not_match_default_id_on_clone_error',
        autoHideDelay: 0,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.ERROR,
      });

      return;
    }

    if (AuthContext.model.hasSalesOpsPermissions) {
      this.props.customerOrder.orderType === ORDER_TYPES.NEW_CUSTOMER
        ? this.openCloneOptionsModal()
        : this.openRetainOverridesModal(DUPLICATE_METHOD.CLONE);
    } else {
      this.props.controller.handleQuoteClone();
    }
  }

  handleRevisionClick() {
    if (AuthContext.model.hasSalesOpsPermissions) {
      this.openRetainOverridesModal(DUPLICATE_METHOD.REVISION);
    } else {
      this.props.controller.handleQuoteCreateRevision();
    }
  }

  handleLeaseCreditApplicationSubmit(data) {
    this.props.controller.handleLeaseCreditApplicationSubmit(data);

    this.setState(state => ({ isLeaseCreditApplicationDialogVisible: false }));
  }

  handleBillingEmailValidation(email) {
    const selectedLocationIndex = this.props.selectedQuote.index;
    const { billingInfo } = this.props.quotes[selectedLocationIndex];

    this.props.appState.setState('isEmailValidationRequested', true);

    this.props.controller.validateBillingEmail(email).then(({ data }) => {
      const { verdict, suggestion, local } = data.content.responseJson.result;

      billingInfo.onChange('contactEmailValidationStatus', verdict.toUpperCase());

      if (suggestion) {
        return this.setState({
          billingEmailSuggestionModal: true,
          billingEmailSuggestion: local + '@' + suggestion,
        });
      }

      if (
        verdict.toLowerCase() === BILLING_EMAIL_VALIDATION_STATUSES.INVALID.toLowerCase() ||
        verdict.toLowerCase() === BILLING_EMAIL_VALIDATION_STATUSES.RISKY.toLowerCase() ||
        verdict.toLowerCase() === BILLING_EMAIL_VALIDATION_STATUSES.ERROR.toLowerCase()
      ) {
        this.setState({
          billingEmailConfirmModal: true,
        });
      }

      this.props.appState.setState('isEmailValidationRequested', false);
    });
  }

  handleBillingEmailSuggestionModalNoClick() {
    this.setState({
      billingEmailSuggestionModal: false,
      billingEmailConfirmModal: true,
    });
  }

  handleBillingEmailSuggestionModalYesClick() {
    const suggestedEmail = this.state.billingEmailSuggestion;
    const selectedLocationIndex = this.props.selectedQuote.index;
    const { billingInfo } = this.props.quotes[selectedLocationIndex];

    billingInfo.onChange('contactEmail', suggestedEmail);
    this.setState(
      {
        billingEmailSuggestionModal: false,
        billingEmailSuggestion: '',
      },
      () => {
        this.handleBillingEmailValidation(suggestedEmail);
      }
    );
  }

  handleBillingEmailConfirmModalContinueClick() {
    const selectedLocationIndex = this.props.selectedQuote.index;
    const { billingInfo } = this.props.quotes[selectedLocationIndex];
    const userNameAndEmail =
      AuthContext.model.firstName + ' ' + AuthContext.model.lastName + ' ' + AuthContext.model.email;

    billingInfo.onChange('contactEmailConfirmedBy', userNameAndEmail);
    billingInfo.onChange('contactEmailConfirmedOn', new Date().getTime());
    billingInfo.onChange('contactEmailValidationStatus', BILLING_EMAIL_VALIDATION_STATUSES.CONFIRMED_AS_VALID);

    this.setState({
      billingEmailConfirmModal: false,
    });
  }

  handleBillingEmailConfirmModalCancelClick() {
    const billingContactEmailEl = document.getElementById('billingContactEmail');

    if (billingContactEmailEl) {
      billingContactEmailEl.focus();
    }

    this.setState({
      billingEmailConfirmModal: false,
    });
  }

  openConfirmEmailModal() {
    this.setState({
      billingEmailConfirmModal: true,
    });
  }

  openCancelSignatureModal(cancelingSignatureRequestId) {
    this.setState({
      cancelSignatureModal: true,
      cancelingSignatureRequestId,
    });
  }

  closeCancelSignatureModal() {
    this.setState({
      cancelSignatureModal: false,
      cancelingSignatureRequestId: null,
    });
  }

  handleCancelSignatureModalCancelClick() {
    this.props.controller.cancelSignature(this.state.cancelingSignatureRequestId);
    this.closeCancelSignatureModal();
  }

  onMarkersOpen() {
    this.setState({ markersModalOpened: true }, this.props.controller.asyncRequestMarkers);
  }
  onMarkersClose() {
    this.setState({ markersModalOpened: false });
  }
  onMarkersSave() {
    this.props.controller.saveMarkerOverrides();

    this.setState({ markersModalOpened: false });
  }

  onDuplicateLocationOpen() {
    this.setState({ duplicateLocationModalOpened: true });
  }

  onDuplicateLocationClose() {
    this.setState({ duplicateLocationModalOpened: false });
  }

  onDuplicateLocationConfirm(numberOfIterations) {
    this.props.appState.setState('quoteLoading', true);

    this.setState(
      {
        duplicateLocationModalOpened: false,
      },
      () => {
        //TODO: Investigate workaround to remove setTimeout but prevent UI hanging due to excessive load on location clone process
        setTimeout(() => {
          this.props.controller.cloneLocationQuote(numberOfIterations);
          this.props.appState.setState('quoteLoading', false);
        }, 0);
      }
    );
  }
}
