import $http from '../common/$http';
import config from '../config';

const PriceBookVersionApi = {
  async getMarkers(priceBookVersionId) {
    try {
      const { data } = await $http.instance.api.get(
        `${config.api.url}/pricebook-version/${priceBookVersionId}/marker-fields`
      );

      return data.content;
    } catch (response) {
      return response.data;
    }
  },
  async asyncGetPricebookCatalog(orderType, pricebookId, pricebookVersionId, serviceType, partnerId) {
    let url = config.api.url + '/catalog/lineItems';
    const params = ['partnerId=' + encodeURIComponent(partnerId)];

    if (pricebookId) {
      params.push('pricebookId=' + encodeURIComponent(pricebookId));
    }

    if (pricebookVersionId) {
      params.push('pricebookVersionId=' + encodeURIComponent(pricebookVersionId));
    }

    if (orderType) {
      params.push('orderType=' + encodeURIComponent(orderType));
    }

    if (serviceType) {
      params.push('serviceType=' + encodeURIComponent(serviceType));
    }

    if (params.length) {
      url += '?' + params.join('&');
    }

    try {
      return (await $http.instance.api.get(url)).data.content;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
};

export default PriceBookVersionApi;
