import $http from '../common/$http';
import config from '../config';

const QuoteMarkerFieldOverridesApi = {
  async getMarkerOverrides(quoteId) {
    try {
      const { data } = await $http.instance.api.get(`${config.api.url}/${quoteId}/marker-field-overrides`);

      return data.content;
    } catch (response) {
      return response.data;
    }
  },

  async createUpdateMarkerOverrides(quoteId, markerOverrides) {
    try {
      const { data } = await $http.instance.api.put(
        `${config.api.url}/${quoteId}/marker-field-overrides`,
        markerOverrides
      );

      return data.content;
    } catch (response) {
      return response.data;
    }
  },
};

export default QuoteMarkerFieldOverridesApi;
