import CommonDownloadButtonModel from './CommonDownloadButtonModel';
import config from '../../config';

class RcTaxEstimatesCsvDownloadButtonModel extends CommonDownloadButtonModel {
  constructor(customerOrderInstance) {
    super(customerOrderInstance);

    this._ignoreOnExport.push('masterOrder');

    this.label = 'msg_download_rc_tax_summary';
  }

  get visible() {
    return Boolean(
      this.masterOrder.rcTaxEstimates &&
        !this.masterOrder.rcTaxEstimates.failed &&
        !this.masterOrder.rcTaxEstimates.noApplicableProducts &&
        this.masterOrder.rcTaxEstimates.total !== null
    );
  }

  get downloadUrl() {
    return [config.api.url, '/', this.masterOrder.id, '/tax-estimates-report'].join('');
  }
}

export default RcTaxEstimatesCsvDownloadButtonModel;
