import { AppStateContext } from '../Context';
import config from '../config';
import $http from '../common/$http';
import AppController from './AppController';
import AppRouter from '../AppRouter/AppRouter';

class OriginalQuoteController extends AppController {
  constructor(originalQuoteId, locationHash) {
    super();

    this.originalQuoteId = Number(originalQuoteId) || null;
    this.locationHash = locationHash;

    this.listenAppStateContext = this.listenAppStateContext.bind(this);
    AppStateContext.model.addBroadcastListener(this.listenAppStateContext);
  }

  listenAppStateContext() {
    if (AppStateContext.model.appInitiated) {
      this.handleRedirect();

      return true;
    }
  }

  handleRedirect() {
    if (this.originalQuoteId === null) {
      AppRouter.push('/404');

      return false;
    }

    this.requestMasterOrderId(this.originalQuoteId)
      .then(masterOrderId => {
        AppRouter.push('/quotes/' + masterOrderId + this.locationHash);
      })
      .catch(({ response }) => {
        AppRouter.push(response.status === 404 ? '/not-found' : '/landing');
      });
  }

  requestMasterOrderId(originalQuoteId) {
    const url = config.api.url + '/quotes/' + originalQuoteId + '/active-quote';

    return $http.instance.api.get(url).then(({ data }) => {
      return data.content.id;
    });
  }
}

export default OriginalQuoteController;
