import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import translator from '../../common/translate';
import * as formatter from '../../common/helpers/format';
import { RESULT_TYPES } from '../../Models/CalcResultsModel';
import { AuthContext, PriceBookContext } from '../../Context';
import config from '../../config';
import BadgeButton from '../BadgeButton';
import Dialog from '../Dialog';
import TableRow from './nested/TableRow';
import { dialogProps } from './package.details.style';
import { quotePackageItems } from './package.details.props';
import './package.details.style.css';
import { FINANCING_OPTIONS, LEASE_OPTIONS, PRICEBOOK_TAGS } from '../../common/enums';
import PdfHiddenForm from '../PdfHiddenForm';
import { checkRequiredForTaxEstimateGeneration } from './helpers/check-required-for-tax-estimate-generation';
import HiddenFormHelper from '../HiddenFormHelper';
import IconButtonWithTooltip from '../IconButtonWithTooltip';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';

class PackageDetailsComponent extends Component {
  static defaultProps = {
    open: true,
  };

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { asyncFetchTaxEstimates, isSelectedPackageOpened, quote } = this.props;

    if (isSelectedPackageOpened && quote.id) {
      asyncFetchTaxEstimates();
    }
  }

  render() {
    const {
      appState,
      packageId,
      disabledState,
      quote,
      open,
      toolbarModel,
      preliminaryButtonModel,
      printFinalButtonModel,
      packageModel,
      asyncGenerateTaxEstimates,
      isSelectedPackageOpened,
    } = this.props;

    const { useESigDocumentsFlow, lockedInPrintFlow, billingInfo } = quote;

    const priceBookFlags = PriceBookContext.model.flags;
    const previewPDF = config.eSignatureEnabled && (useESigDocumentsFlow || !lockedInPrintFlow);
    const packageTitle = packageModel.packageName || translator.getMessage('msg_package_details');
    const preliminaryPDFLabel = translator.getMessage(previewPDF ? 'msg_preview_quick_quote' : 'msg_quick_quote');
    const finalPDFLabel = translator.getMessage(previewPDF ? 'msg_preview_final_quote' : 'msg_final_quote');
    const generateTaxEstimatesErrors = checkRequiredForTaxEstimateGeneration(
      appState.isSaved,
      isSelectedPackageOpened,
      billingInfo
    );
    const generateTaxEstimatesLabel = appState.taxEstimatesGenerating
      ? translator.getMessage('msg_generating')
      : translator.getMessage('msg_generate_tax_estimates');
    const leftAlignedActions = [];
    const rightAlignedActions = [
      <button
        id="package-details-cancel-button"
        key="package-details-close-button"
        className="package-details-dialog-btn-cancel text-btn"
        onClick={this.handleClose}
      >
        {translator.getMessage('msg_close')}
      </button>,
    ];

    dialogProps.title = (
      <div>
        <header className="package-details-title">
          <div className="package-details-title-text">{packageTitle}</div>
          <small className="package-details-subtitle">
            ({translator.getMessage('msg_values_are_rounded_to_nearest_dollar_for_display_purposes')})
          </small>
        </header>
      </div>
    );

    if (isSelectedPackageOpened) {
      rightAlignedActions.push(
        <BadgeButton
          id="package-details-customer-pdf-button"
          key="package-details-customer-pdf-button"
          label={finalPDFLabel}
          btnClassName="active-tabpackage-details-dialog-btn"
          disabled={printFinalButtonModel.disabled}
          errors={printFinalButtonModel.errors}
          errorsCnt={printFinalButtonModel.errorsCnt}
          infoTooltip={printFinalButtonModel.infoTooltip}
          hideInfoBadge={true}
          tooltipAlign="left"
          tooltipVerticalAlign={'top'}
          form="form-package-details-customer-pdf-button"
        />,
        <PdfHiddenForm
          id="form-package-details-customer-pdf-button"
          key="form-package-details-customer-pdf-button"
          endpoint={'/quotes/final/' + quote.id + '/pdf/' + packageId}
        />,
        <BadgeButton
          id="package-details-preliminary-pdf-button"
          key="package-details-preliminary-pdf-button"
          label={preliminaryPDFLabel}
          btnClassName="package-details-dialog-btn tertiary"
          disabled={preliminaryButtonModel.disabled}
          errors={preliminaryButtonModel.errors}
          errorsCnt={preliminaryButtonModel.errorsCnt}
          infoTooltip="msg_quick_quote_tooltip"
          hideInfoBadge={true}
          tooltipAlign="right"
          tooltipVerticalAlign={'top'}
          form="form-package-details-preliminary-pdf-button"
        />,
        <PdfHiddenForm
          id="form-package-details-preliminary-pdf-button"
          key="form-package-details-preliminary-pdf-button"
          endpoint={'/quotes/preliminary/' + quote.id + '/pdf/' + packageId}
        />
      );

      if (!priceBookFlags.pricebookTag.includes(PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS)) {
        rightAlignedActions.push(
          <BadgeButton
            id="package-details-partner-pdf-button"
            key="package-details-partner-pdf-button"
            label={translator.getMessage('msg_partner_pdf')}
            btnClassName="package-details-dialog-btn"
            disabled={toolbarModel.printPartnerBtnDisabled || disabledState}
            errors={toolbarModel.printPartnerBtnErrors}
            errorsCnt={toolbarModel.requiredPartnerPrintFields?.fields.length || 0}
            tooltipAlign="right"
            tooltipVerticalAlign={'top'}
            form="form-package-details-partner-pdf-button"
          />,
          <PdfHiddenForm
            id="form-package-details-partner-pdf-button"
            key="form-package-details-partner-pdf-button"
            endpoint={'/quotes/partner/' + quote.id + '/pdf'}
          />
        );
      }
    }

    leftAlignedActions.push(
      <BadgeButton
        id="package-details-generate-tax-estimates-button"
        key="package-details-generate-tax-estimates-button"
        label={generateTaxEstimatesLabel}
        errors={generateTaxEstimatesErrors}
        errorsCnt={generateTaxEstimatesErrors.length}
        disabled={generateTaxEstimatesErrors.length > 0 || disabledState || appState.taxEstimatesGenerating}
        tooltipAlign="left"
        tooltipVerticalAlign={'top'}
        onClick={asyncGenerateTaxEstimates}
      />
    );

    dialogProps.actions = [
      <div className="left-aligned-actions" key="left-aligned-actions">
        {leftAlignedActions}
      </div>,
      <div className="right-aligned-actions" key="right-aligned-actions">
        {rightAlignedActions}
      </div>,
    ];

    return (
      <Dialog {...dialogProps} onRequestClose={this.handleClose} isOpen={open}>
        <table className="rq-table striped sticky" key="tabled-package-information">
          <thead>
            <tr>
              <th>{packageTitle}</th>
              <th className="align-right">{translator.getMessage('msg_non_recurring')}</th>
              <th className="align-right">{translator.getMessage('msg_recurring')}</th>
            </tr>
          </thead>
          <tbody>
            {quotePackageItems[packageId].map((item, idx) => this.getDetailedItem(item, idx, priceBookFlags))}
          </tbody>
        </table>
      </Dialog>
    );
  }

  __getValue = (key, store) => {
    if (_.isFunction(key)) {
      return key(store);
    } else if (key === null) {
      return '';
    } else if (key === 'N/A') {
      return 'N/A';
    } else if (typeof key === 'string') {
      return parseFloat(store[key]).toFixed(2);
    }

    return 'Unknown format value';
  };

  __isArraySubsetOfArray(arrayOne = [], arrayTwo = []) {
    return arrayOne.some(item => arrayTwo.includes(item));
  }

  getDetailedItem(
    {
      paymentType,
      marker,
      title,
      store,
      isBold,
      titleFullRow,
      customRenderer,
      internalOnly,
      hideIfWholesale,
      hideForTags,
      showForTags,
    },
    idx,
    priceBookFlags
  ) {
    const { isInternal, isWholesale, quote } = this.props;
    const hideIfTagIsPresent = this.__isArraySubsetOfArray(hideForTags, priceBookFlags.pricebookTag);
    const showIfTagIsPresent =
      showForTags !== undefined ? this.__isArraySubsetOfArray(showForTags, priceBookFlags.pricebookTag) : true;

    if ((internalOnly && !isInternal) || (hideIfWholesale && isWholesale) || hideIfTagIsPresent || !showIfTagIsPresent) {
      return null;
    }

    if (customRenderer && typeof this[customRenderer] === 'function') {
      return this[customRenderer](quote, isBold);
    }

    let upfrontValue = this.__getValue(paymentType.nrc, quote.calcResults.results);
    let recurringValue = this.__getValue(paymentType.rc, quote.calcResults.results);

    upfrontValue = upfrontValue.length ? this.formatValue(upfrontValue, marker) : '';
    recurringValue = recurringValue.length ? this.formatValue(recurringValue, marker) : '';

    return (
      <TableRow
        title={title}
        key={title + idx}
        nrcValue={upfrontValue}
        rcValue={recurringValue}
        isBold={isBold}
        titleFullRow={titleFullRow}
      />
    );
  }

  dealerNetSection(bean) {
    const nrcDealerNet = this.getFormattedValueFromBean(bean, 'nrcDealerNet');
    const ictppsDealerNet = this.getFormattedValueFromBean(bean, 'icttpsDealerNet');
    const total = this.getFormattedValueFromBean(bean, 'totalNrcDealerNet');

    return (
      <Fragment key="dealer-net-section">
        <TableRow title="msg_nrc_items_dealer_net" key="msg_nrc_items_dealer_net" nrcValue={nrcDealerNet} />
        <TableRow title="msg_ictpps_dealer_net" key="msg_ictpps_dealer_net" nrcValue={ictppsDealerNet} />
        <TableRow title="msg_dealer_net_total" key="msg_dealer_net_total" nrcValue={total} isBold />
      </Fragment>
    );
  }

  markupRatio(bean) {
    const markupRatio = this.getFormattedValueFromBean(bean, 'markupRatio', '%');

    return <TableRow title="msg_markup_ratio" key="msg_markup_ratio" nrcValue={markupRatio} />;
  }

  partnerMarkup(bean) {
    const nrcPartnerMarkup = this.getFormattedValueFromBean(bean, 'lineSumNrcMarkupTotals');
    const rcPartnerMarkup = this.props.isWholesale
      ? this.getFormattedValueFromBean(bean, 'lineSumRcMarkupTotals')
      : 'N/A';

    return (
      <TableRow
        title="msg_partner_markup"
        key="msg_partner_markup"
        nrcValue={nrcPartnerMarkup}
        rcValue={rcPartnerMarkup}
      />
    );
  }

  estimatedLeasePayment(bean) {
    const { packageId, quote } = this.props;
    const { leaseOption, isLeaseOptionAvailableExcludingPackageId } = quote;

    if (!isLeaseOptionAvailableExcludingPackageId) {
      return;
    }

    let estimatedLeasePayment = bean.calcResults.getValue('estimatedLeasePayment' + packageId, RESULT_TYPES.float);
    estimatedLeasePayment = estimatedLeasePayment <= 0 ? 'Not Eligible' : this.formatValue(estimatedLeasePayment);

    const rcValue = leaseOption === LEASE_OPTIONS.NONE ? 'N/A' : estimatedLeasePayment;

    return (
      <Fragment key="lease_payment_section">
        <TableRow />
        <TableRow title="msg_estimated_lease_payment" key="msg_estimated_lease_payment" rcValue={rcValue} isBold />
      </Fragment>
    );
  }

  madMessageRow() {
    let title = '';

    switch (this.props.quote.financingOption) {
      case FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR:
        title = 'msg_for_all_inclusive_of_maximum_allowable_discount';
        break;
      case FINANCING_OPTIONS.RENTAL_GREATAMERICA:
        title = 'msg_for_rental_of_maximum_allowable_discount';
        break;
      default:
        break;
    }

    return <TableRow title={title} key={title} titleFullRow />;
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  formatValue(value, marker) {
    if (value === 'N/A') {
      return value;
    } else if (marker) {
      const localeValue = Math.abs(parseFloat(value) || 0)
        .toFixed(0)
        .toLocaleString('en-US');

      return `${localeValue}${marker}`;
    } else {
      return formatter.currency(value, 0);
    }
  }

  getFormattedValueFromBean(bean, prop, marker) {
    return this.formatValue(bean.calcResults.getValue(prop + this.props.packageId, RESULT_TYPES.float), marker) || '';
  }

  taxEstimates() {
    const { nrcTaxEstimates, rcTaxEstimates } = this.props.quote;
    let nrcDate = '';
    let nrcTitle = '@msg_nrc_tax_estimates@';
    let nrcErrorSubtitle = '';
    let nrcTaxEstimate = 'N/A';
    let rcDate = '';
    let rcTitle = '@msg_rc_tax_estimates@';
    let rcErrorSubtitle = '';
    let rcTaxEstimate = 'N/A';

    if (nrcTaxEstimates) {
      if (nrcTaxEstimates.failed) {
        const errorMessage = nrcTaxEstimates.errorMessage ? '\n' + nrcTaxEstimates.errorMessage : '';

        nrcErrorSubtitle = {
          title: 'msg_unable_to_generate',
          icon: 'icon-warning',
          iconTitle: '@msg_tax_estimates_api_failure@' + errorMessage,
        };
      } else {
        nrcDate = dayjs(nrcTaxEstimates.generationDate).format('MM/DD/YYYY h:mm:ss A');
        nrcTitle += ' (@msg_generated_on@ ' + nrcDate + ')';
        nrcTaxEstimate = !nrcTaxEstimates.noApplicableProducts ? formatter.currency(nrcTaxEstimates.total, 2) : 'N/A';
      }
    }

    if (rcTaxEstimates) {
      if (rcTaxEstimates.failed) {
        const errorMessage = rcTaxEstimates.errorMessage ? '\n' + rcTaxEstimates.errorMessage : '';

        rcErrorSubtitle = {
          title: 'msg_unable_to_generate',
          icon: 'icon-warning',
          iconTitle: '@msg_tax_estimates_api_failure@' + errorMessage,
        };
      } else {
        rcDate = dayjs(nrcTaxEstimates.generationDate).format('MM/DD/YYYY h:mm:ss A');
        rcTitle += ' (@msg_generated_on@ ' + rcDate + ')';
        rcTaxEstimate = !rcTaxEstimates.noApplicableProducts ? formatter.currency(rcTaxEstimates.total, 2) : 'N/A';
      }
    }

    return (
      <Fragment key="tax-estimates-section">
        <TableRow title="" key="tax-estimates-empty-row" />
        <TableRow title="msg_tax_estimates" key="msg_tax_estimates" titleFullRow isBold />
        <TableRow title="msg_tax_estimates_disclaimer" key="msg_tax_estimates_disclaimer" titleFullRow />
        <TableRow
          title={nrcTitle}
          key="msg_nrc_tax_estimates"
          errorSubTitle={nrcErrorSubtitle}
          nrcValue={nrcTaxEstimate}
          nrcIcon={this.downloadNrcTaxEstimatesButton()}
        />
        <TableRow
          title={rcTitle}
          key="msg_rc_tax_estimates"
          errorSubTitle={rcErrorSubtitle}
          rcValue={rcTaxEstimate}
          rcIcon={this.downloadRcTaxEstimatesButton()}
        />
      </Fragment>
    );
  }

  downloadNrcTaxEstimatesButton() {
    const { nrcTaxEstimatesCsvDownloadButton } = this.props.quote;

    return nrcTaxEstimatesCsvDownloadButton.visible ? (
      <>
        <IconButtonWithTooltip
          id="download-nrc-tax-estimates-button"
          form="download-nrc-tax-estimates-form"
          iconClass="icon-download"
          title={this.props.intl.formatMessage({ id: nrcTaxEstimatesCsvDownloadButton.label })}
          className="download-taxes-button"
        />
        <HiddenFormHelper
          id="download-nrc-tax-estimates-form"
          action={nrcTaxEstimatesCsvDownloadButton.downloadUrl}
          method={HiddenFormHelper.methods.get}
          values={{
            token: AuthContext.model.token,
            recurring: 'false',
          }}
        />
      </>
    ) : null;
  }

  downloadRcTaxEstimatesButton() {
    const { rcTaxEstimatesCsvDownloadButton } = this.props.quote;

    return rcTaxEstimatesCsvDownloadButton.visible ? (
      <>
        <IconButtonWithTooltip
          id="download-rc-tax-estimates-button"
          form="download-rc-tax-estimates-form"
          iconClass="icon-download"
          title={this.props.intl.formatMessage({ id: rcTaxEstimatesCsvDownloadButton.label })}
          className="download-taxes-button"
        />
        <HiddenFormHelper
          id="download-rc-tax-estimates-form"
          action={rcTaxEstimatesCsvDownloadButton.downloadUrl}
          method={HiddenFormHelper.methods.get}
          values={{
            token: AuthContext.model.token,
            recurring: 'true',
          }}
        />
      </>
    ) : null;
  }
}

export default injectIntl(PackageDetailsComponent);
